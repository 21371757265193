












































































import { Component, Vue } from 'vue-property-decorator';
import { required, minLength, email } from 'vuelidate/lib/validators';
import loading from '@/components/Common/loading.vue';
@Component({
  validations: {
    userEmail: {
      required,
      email,
      minLength: minLength(1),
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
  components: {
    loading,
  },
})
export default class EvaluationQuestionExportResultsToEmailModalPopup extends Vue {
  private message = '';
  private userEmail = '';
  private showLoading = false;
  private loaderBorderColor = '';
  private evaluationReportSent = false;
  private Cancel() {
    this.$emit('confirm-click', {
      clickedYes: false,
    });
  }

  private clickYes() {
    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }
    this.$v.$reset();
    this.showLoading = true;
    this.$axios
      .post('/Program/SendEvaluationResult', {
        emailAddress: this.userEmail,
      })
      .then((response) => {
        if (response.data.success === false) {
          // Server side validation failuere
          alert(this.$t('Message.SomeErrorOccured'));
        } else {
          this.evaluationReportSent = true;
          // Successful update / save
          this.message = this.$t(
            'EvaluationQuestions.Message.EvaluationReport',
            {
              userEmail: this.userEmail,
            },
          ).toString();
        }
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
}
