

























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { ProgramEvaluationQuestionsItem } from '@/entities/Program/ProgramEvaluationQuestionsItem';
import { ProgramEvaluationQuestionType } from '@/entities/Program/ProgramEvaluationQuestionType';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import EvaluationQuestionListing from '@/components/Program/EvaluationQuestions/EvalutationQuestionListing.vue';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import { ProgramEvaluationQuestionItemResult } from '@/entities/Program/ProgramEvaluationQuestionItemResult';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';

import { ProgramEvaluationQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramEvaluationQuestionsSignalRModel';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { DateTimeFormatOptions } from 'vue-i18n';
import Common from '@/helper/Common';
@Component({
  validations: {
    localEvaluationQuestion: {
      Question: {
        required,
        minLength: minLength(1),
      },
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
  components: {
    loading,
    ModalPopup,
    EvaluationQuestionListing,
    ValidationSummary,
  },
})
export default class EvaluationQuestionsEditItemPane extends Vue {
  @Prop() private selectedEvaluationQuestion?: ProgramEvaluationQuestionsItem;
  @Prop() private programItems?: ProgramItem[];
  @Prop()
  private programEvaluationQuestionTypes?: ProgramEvaluationQuestionType[];
  @Prop() private globalProgramEvaluation?: boolean;
  private localEvaluationQuestion?: ProgramEvaluationQuestionsItem = ProgramEvaluationQuestionsItem.createEmpty();
  private localProgramEvaluationQuestionTypes?: ProgramEvaluationQuestionType[] = [];
  private loaderBorderColor = '';
  private showLoading = false;
  private beforeEditingCache: ProgramEvaluationQuestionsItem = ProgramEvaluationQuestionsItem.createEmpty();
  private isMobileView = false;
  private selectedProgramIds: number[] = [];
  private selectedPrograms: ProgramItem[] = [];
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent: string = this.getRemoveEvaluationQuestionMessage();
  private errors: string[] = [];
  private messages: string[] = [];
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private fileDownload = require('js-file-download');
  private selectAllProgramItemsCheckbox = false;
  private reloadProgramItems = false;
  private localGlobalProgramEvaluation = false;
  private selectedProgramNames = '';
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  // Date format to show for a selected Date time
  private dateOptions: DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  };
  // Create a new Date format from dateoptions specified
  private dateFormat = new Intl.DateTimeFormat();
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  // Created Event of Component
  private created() {
    // Assign value to the local variable of evaluation Question
    if (this.selectedEvaluationQuestion != null) {
      this.localEvaluationQuestion = Object.assign(
        {},
        this.selectedEvaluationQuestion,
      );
      this.beforeEditingCache = Object.assign(
        {},
        this.selectedEvaluationQuestion,
      );
    }
  }

  // Mounted Event of Component
  private mounted() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, this.dateOptions);
    this.localGlobalProgramEvaluation = this.globalProgramEvaluation!;
    // Assign value to the local variable of evaluation Question
    if (this.selectedEvaluationQuestion != null) {
      this.localEvaluationQuestion = Object.assign(
        {},
        this.selectedEvaluationQuestion,
      );
      this.beforeEditingCache = Object.assign(
        {},
        this.selectedEvaluationQuestion,
      );
    }
    // Assign value to the local variable of Program Question Types to show in dropdown
    if (this.programEvaluationQuestionTypes != null) {
      this.localProgramEvaluationQuestionTypes = this.programEvaluationQuestionTypes;
    }

    if (this.localEvaluationQuestion != null) {
      this.selectedProgramIds = [];
      this.selectedPrograms = [];
      if (this.localEvaluationQuestion.SelectedPrograms !== 'All') {
        // Set selected program Ids from list of attached program Ids
        if (this.localEvaluationQuestion.SelectedProgramIds) {
          const itemsArr = this.localEvaluationQuestion.SelectedProgramIds.split(
            ',',
          );

          itemsArr.forEach((item) => {
            this.selectedProgramIds.push(Number(item));
            const objIndex = this.programItems!.findIndex(
              (obj: ProgramItem) => obj.Id === Number(item),
            );
            if (objIndex > -1) {
              this.selectedPrograms.push(this.programItems![objIndex]);
            }
          });
        }
      }
    }
    // check for ipad and mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    // whenever all program or selected program radio button selected,
    // selected program list in edit pane will be updated.
    this.$root.$on('update-evaluation-selected-programs', (object: any) => {
      if (object.isallSelected) {
        this.selectedPrograms = [];
      } else {
        const selectedProgramIds: number[] = object.selectedProgramIds;
        selectedProgramIds.forEach((element) => {
          const objIndex = this.programItems!.findIndex(
            (obj: ProgramItem) => obj.Id === element,
          );
          if (objIndex > -1) {
            this.selectedPrograms.push(this.programItems![objIndex]);
          }
        });
      }
    });

    this.$root.$on('global-program-evaluation', (globalEvaluation: boolean) => {
      this.localGlobalProgramEvaluation = globalEvaluation;
      this.selectedPrograms = [];
      this.localEvaluationQuestion!.SelectedProgramIds = '';
      if (!globalEvaluation) {
        this.localEvaluationQuestion!.SelectedPrograms = '';
      } else {
        this.localEvaluationQuestion!.SelectedPrograms = 'All';
      }
    });

    // make scroll to top when session question item selected
    this.$root.$on(
      'new-evaluation-question-edit-item-pane',
      (isNewEvaluationQuestionEditItemPane: boolean) => {
        if (isNewEvaluationQuestionEditItemPane) {
          // scroll to top if any program edit or new program clicked
          if (!this.isMobileView) {
            Common.ScrollToTop('evaluationQuestionEditMode');
          }
        }
      },
    );

    const self = this;
    this.$root.$on(
      'program-evaluation-questions-updated',
      (evaluationQuestionsItem: ProgramEvaluationQuestionsItem) => {
        self.UpdateLocalEvaluationQuestion(self, evaluationQuestionsItem);
      },
    );
  }

  // Get message to delete from Translations
  private getRemoveEvaluationQuestionMessage(): string {
    return this.$t('EvaluationQuestions.DeleteEvaluationQuestion').toString();
  }

  // Click on Yes/Cancel Button of Popup
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.DeleteConfirmed();
    } else {
      return;
    }
  }

  // Show Popup for Deletion
  private Delete() {
    this.modalPopupVisible = true;
  }
  // Delete a Session Question
  private DeleteConfirmed() {
    const signalObject = this.SendNotificationRequest(true);
    this.$axios
      .post<ProgramEvaluationQuestionItemResult>('/Program/DeleteEvaluation', {
      programEvaluationId: this.localEvaluationQuestion!.Id,
    })
      .then((response) => {
        if (response.data.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToDelete'));
        } else {
          // Successful update / save
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localEvaluationQuestion!,
          );
          this.$root.$emit(
            'program-evaluationquestion-item-delete-operation',
            this.localEvaluationQuestion!.Id,
          );
        }
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private SendNotificationRequest(isDelete: boolean): SignalRModelRequest {
    const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    signalR.ShowNotificationsPopup();
    this.$root.$emit('program-evaluationquestion-tab-select-operation');
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    if (isDelete) {
      signalObject = signalR.SingleDeleteEvaluationQuestionSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localEvaluationQuestion!.Id);
      signalObject.SelectedIds = arr;
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        selectedCount: arr.length, // Just single item delete
        user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
      }).toString();
    } else {
      if (this.localEvaluationQuestion!.Id === 0) {
        signalObject = signalR.SingleCreateEvaluationQuestionSignalRModelRequest;
      } else {
        signalObject = signalR.SingleUpdateEvaluationQuestionSignalRModelRequest;
        const arr: number[] = [];
        arr.push(this.localEvaluationQuestion!.Id);
        signalObject.SelectedIds = arr;
      }
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
      }).toString();
    }
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({selectedName: this.localEvaluationQuestion!.Question});
    signalR.DisableEvaluationQuestionsAndAddToNotificationPopup(signalObject);
    return signalObject;
  }

  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    evaluationQuestion: ProgramEvaluationQuestionsItem,
  ) {
    const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(evaluationQuestion.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetEvaluationQuestionToSendToSignalR(evaluationQuestion);
    signalR.PerformEvaluationSingleOperation(signalObject);
  }

  private IsAnonymousClicked(event: any) {
    const programItemChecked = event.target.checked;
    this.localEvaluationQuestion!.Anynomous = programItemChecked;
  }
  private Cancel() {
    this.$v.$reset();
    const existingitem = this.beforeEditingCache;
    this.$emit(
      'program-evaluationQuestion-item-cancel-operation',
      existingitem,
    );
    this.$root.$emit(
      'program-evaluationQuestion-item-cancel-operation',
      existingitem,
    );
  }

  // Add/ Edit a Session Question
  private Submit() {
    this.$v.$touch();
    if (this.$v.$anyError) {
      if (this.$v.localEvaluationQuestion.Question!.$error === true) {
        this.validationErrorFields = [];
        this.validationErrorFields.push(this.$t('EvaluationQuestions.Question').toString());
      }
      this.visibleValidationSummary = true;
      return;
    }
    this.$v.$reset();
    this.visibleValidationSummary = false;

    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    this.localEvaluationQuestion!.ApplicationInstanceId = reqInfo.ApplicationInstanceId;
    const signalObject = this.SendNotificationRequest(false);
    // No errors submitting.
    this.$axios
      .post<ProgramEvaluationQuestionItemResult>(
      '/Program/CreateNewEvaluation',
      this.localEvaluationQuestion,
    )
      .then((response) => {
        if (response.data.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.data.ValidationErrors[0]));
        } else {
          // Successful update / save
          const item = response.data.EvaluationQuestionItem;
          // this.$root.$emit('program-evaluationquestion-item-edit-operation', item);
          this.SendNotificationRequestToQueue(signalObject, item);
        }
        this.showLoading = false;
        setTimeout(() => {
          this.ClearMessagesList();
        }, 5000);
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  // Clear Messages List
  private ClearMessagesList() {
    this.messages = [];
  }

  // Export Session Questions Data for a particular Session Question
  private ExportSingleEvaluationQuestionResultToExcel() {
    this.showLoading = true;
    this.$axios({
      url: '/FileDownload/ExportProgramEvaluation',
      method: 'POST',
      data: {
        programEvaluationId: this.localEvaluationQuestion!.Id,
      },
      headers: { Accept: 'application/vnd.ms-excel' },
      responseType: 'blob', // important
    })
      .then((response) => {
        this.fileDownload(response.data, 'EvaluationQuestions.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private UpdateLocalEvaluationQuestion(self: any, val: ProgramEvaluationQuestionsItem) {
    self.$v.$reset();
    // hide validation summary when switching the evaluation item
    self.visibleValidationSummary = false;
    self.localEvaluationQuestion = Object.assign({}, val);
    self.beforeEditingCache = Object.assign({}, val);
    if (self.localEvaluationQuestion != null) {
      self.selectedProgramIds = [];
      self.selectedPrograms = [];
      if (self.localEvaluationQuestion!.SelectedPrograms !== 'All') {
        // Set selected program Ids from list of attached program Ids
        if (self.localEvaluationQuestion!.SelectedProgramIds) {
          const itemsArr = self.localEvaluationQuestion!.SelectedProgramIds!.split(
            ',',
          );
          itemsArr.forEach((item: number) => {
            self.selectedProgramIds.push(Number(item));
            const objIndex = self.programItems!.findIndex(
              (obj: ProgramItem) => obj.Id === Number(item),
            );
            if (objIndex > -1) {
              self.selectedPrograms.push(self.programItems![objIndex]);
            }
          });
        }
      }
    }
  }
  // Watch when the selected evaluation question is updated from list
  @Watch('selectedEvaluationQuestion')
  private onPropertySelectedEvaluationQuestionChange(val: ProgramEvaluationQuestionsItem) {
    this.$v.$reset();
    // hide validation summary when switching the evaluation item
    this.visibleValidationSummary = false;
    this.localEvaluationQuestion = Object.assign({}, val);
    this.beforeEditingCache = Object.assign({}, val);
    if (this.localEvaluationQuestion != null) {
      this.selectedProgramIds = [];
      this.selectedPrograms = [];
      if (this.localEvaluationQuestion.SelectedPrograms !== 'All') {
        // Set selected program Ids from list of attached program Ids
        if (this.localEvaluationQuestion.SelectedProgramIds) {
          const itemsArr = this.localEvaluationQuestion.SelectedProgramIds.split(
            ',',
          );
          itemsArr.forEach((item) => {
            this.selectedProgramIds.push(Number(item));
            const objIndex = this.programItems!.findIndex(
              (obj: ProgramItem) => obj.Id === Number(item),
            );
            if (objIndex > -1) {
              this.selectedPrograms.push(this.programItems![objIndex]);
            }
          });
        }
      }
    }
  }

  // Set selected program Items
  private SetSelectedProgramItems(selectedProgramItems: ProgramItem[]) {
    this.selectedProgramIds = [];
    // this.selectedProgramNames="";
    selectedProgramItems.forEach((element) => {
      if (this.selectedProgramIds.indexOf(element.Id) === -1) {
        this.selectedProgramIds.push(element.Id);
      }
    });
    this.localEvaluationQuestion!.SelectedProgramIds = this.selectedProgramIds.toString();
  }
  @Watch('globalProgramEvaluation')
  private onGlobalProgramEvaluationChanged(val: boolean) {
    this.localGlobalProgramEvaluation = val;
  }
}
