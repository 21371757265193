




































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { VonageSessionData } from '@/entities/VonageSessionData';
import loading from '@/components/Common/loading.vue';
@Component({
  components: {
    loading,
  },
})
export default class VirtualMeetingComprehensiveDetails extends Vue {
  public loaderBorderColor = '';
  @Prop() private vonageAnalytics?: VonageSessionData;
  @Prop() private selectedProgramId?: number;
  @Prop() private moduleType?: string;
  private vonageAnalyticslocal: VonageSessionData = VonageSessionData.createEmpty();
  private showLoading = false;
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private fileDownload = require('js-file-download');

  private created() {
    this.vonageAnalyticslocal = this.vonageAnalytics!;
  }

  // Export Vonage Session Data for a particular Program
  private ExportSingleVonageAnalyticsToExcel() {
    this.showLoading = true;
    this.$axios({
      url: '/FileDownload/ExportVonageAnalyticsByEntityIdAndEntityType',
      method: 'POST',
      data: {
        // Add program Id
        entityId: this.selectedProgramId,
        entityType:this.moduleType,
      },
      headers: { Accept: 'application/vnd.ms-excel' },
      responseType: 'blob', // important
    })
      .then((response) => {
        this.fileDownload(response.data, 'VentlaLiveDetails.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

}
