


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProgramTagItem } from '@/entities/Program/ProgramTagItem';
import ProgramTagItemEditPane from '@/components/Program/ProgramDetail/Tags/ProgramTagItemEditPane.vue';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { TagSignalRModel } from '@/signalRRequest/Program/TagSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
@Component({
  components: {
    ProgramTagItemEditPane,
    loading,
    ModalPopup,
  },
})
export default class ProgramTagListViewEditItemPane extends Vue {
  public loaderBorderColor = '';
  public showLoading = false;
  @Prop() private selectedEditTagItem?: ProgramTagItem;
  @Prop() private applicationborderColor?: string;
  private localTagItem: ProgramTagItem | null = null;
  private showEditTag = false;
  private isMobileView = false;
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent: string = this.getRemoveTagMessage();
  private tagIdTobeDeleted = 0;

  @Watch('selectedEditTagItem')
  private onchange(val: ProgramTagItem) {
    this.localTagItem!.TagName = val.TagName;
  }

  private mounted() {
    if (this.selectedEditTagItem != null) {
      this.localTagItem = this.selectedEditTagItem;
    }
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    // check for ipad and mobile view
    if (window.innerWidth <= 1300) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    this.$root.$on('program-tag-close-others-operation', () => {
      this.showEditTag = false;
    });
  }
  private getEditButtonstyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        height: 'auto',
        width: 'auto',
      };
    } else {
      return {};
    }
  }
  private getDeleteButtonstyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        'margin-left': '5px',
        'height': 'auto',
        'width': 'auto',
      };
    } else {
      return { 'margin-left': '15px' };
    }
  }
  private getLeftDivStyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        'margin-top': '10px',
        'width': '60%',
      };
    } else {
      return { 'margin-top': '20px', 'width': '70%' };
    }
  }
  private getRightDivStyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        width: '40%',
      };
    } else {
      return { width: '30%' };
    }
  }
  private selectItem(val: ProgramTagItem) {
    this.$root.$emit('program-tag-close-others-operation');
    const selectedItem = val;
    this.localTagItem = selectedItem;
    this.showEditTag = true;
  }
  private programTagItemCancelOperation(pt: ProgramTagItem) {
    this.localTagItem = pt;
    this.showEditTag = false;
  }

  private programTagItemEditedOperation() {
    this.showEditTag = false;
  }

  // remove tag with modal popup
  private getRemoveTagMessage(): string {
    return this.$t('Tag.RemoveTag.Message').toString();
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.Delete(this.tagIdTobeDeleted);
    } else {
      return;
    }
  }
  private DeleteSelectedTag(tag: ProgramTagItem) {
    this.tagIdTobeDeleted = tag.Id;
    this.modalPopupVisible = true;
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      this.$root.$emit('show-notification-popup', true);
      this.$root.$emit('show-notification-loading', true);
    }, 100);
    const signalR: TagSignalRModel = new TagSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    signalObject = signalR.SingleDeleteTagItemSignalRModelRequest;
    const arr: number[] = [];
    arr.push(this.localTagItem!.Id);
    signalObject.SelectedIds = arr;
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      selectedCount: arr.length, // Just single item delete
      user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
    }).toString();
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    programTagItem: ProgramTagItem,
  ) {
    const signalR: TagSignalRModel = new TagSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(programTagItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetTagItemSendToSignalR(programTagItem);
    signalR.PerformTagSingleOperation(signalObject);
  }

  private Delete(tagId: number) {
    const signalObject = this.SendNotificationRequest();
    this.$axios
      .post('/Program/DeleteProgramTag', {
        id: tagId,
      })
      .then((response) => {
        if (response.data.success === false) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToDelete'));
        } else {
          // Successful delete
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localTagItem!,
          );
          // Successful update / save
          this.$root.$emit('program-tag-item-delete-operation', tagId);
        }
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
}
