












import CKEditor from 'ckeditor4-vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    ckeditor: CKEditor.component,
  },
})
export default class Editor extends Vue {
  @Prop() private description?: string;
  @Prop() private IsReadOnly?: boolean;
  private localText?: string = '';
  private config: object = {
    allowedContent: 'b i u p a[href, target]',
    toolbar: [
      { name: 'basic', items: ['Bold', 'Italic', 'Underline'] },
      { name: 'links', items: ['Link', 'Unlink'] },
    ],
    height: 150,
  };

  private getInputTextValue() {
    this.localText = this.description;
  }

  private mounted() {
    this.getInputTextValue();
    // clear description box after submit the form.
    this.$root.$on('clearDescription', (description: string) => {
      this.localText = description;
    });
    // update description
    this.$root.$on('UpdateEditorDescription', (description: string) => {
      this.localText = description;
    });
  }

  private change() {
    this.$emit('updatedDescription', this.localText);
  }
  @Watch('description')
  private CheckChangeInDescription(val: string) {
    this.localText = val;
  }
}
