

















import { Component, Prop, Vue } from 'vue-property-decorator';
import VoerroTagsInput from '@voerro/vue-tagsinput';
@Component({
  components: {
    VoerroTagsInput,
  },
})
export default class InputTag extends Vue {
  @Prop() private selectedTags?: any;
  @Prop() private existingTags?: any;
  private localselectedTags?: any = [];
  private localexistingTags?: any = [];
  private tagPlaceholder = this.getPlaceHolderText();
  private GetselectedTags() {
    this.localselectedTags = this.selectedTags;
  }
  private GetexistingTags() {
    this.localexistingTags = this.existingTags;
  }
  private getPlaceHolderText(): string {
    return this.$t('Tag.SelectOrAdd').toString();
  }
  private mounted() {
    this.GetselectedTags();
    this.GetexistingTags();
    this.$root.$on('clearTags', () => {
      this.localselectedTags = [];
      this.GetexistingTags();
    });
    // update tags
    this.$root.$on('UpdateTags', (tags: any) => {
      this.localselectedTags = [];
      if (tags != null) {
        for (const tag of tags) {
          this.localselectedTags.push({ key: tag.Id, value: tag.TagName });
        }
      }
    });
    this.$root.$on('AppendTags', (tag: any) => {
      if (tag != null) {
        const objIndex = this.localselectedTags.findIndex(
          (obj: any) => obj.key === tag.Id,
        );
        if (objIndex === -1) {
          this.localselectedTags.push({ key: tag.Id, value: tag.TagName });
        }
        this.onTagsUpdated();
      }
    });
  }
  private onTagsUpdated() {
    if (this.localselectedTags.length === 0) {
      this.tagPlaceholder = this.getPlaceHolderText();
    } else {
      this.tagPlaceholder = '';
    }
    this.$emit('selectedTags', this.localselectedTags);
  }
}
