



































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as Highcharts from 'highcharts';


import { VideoOnDemandMetrics, VodVideoMetricsRequest } from '@/entities/VodDetail';
import VodTypeEnums from '@/enums/VodTypeEnums';
import VodModuleTypeEnum from '@/enums/VodModuleTypeEnum';

@Component
export default class PreRecordedMeetingHighcharts extends Vue {
  @Prop() private videoDetail?: VodVideoMetricsRequest;
  @Prop() private videoOnDemandMetrics?: VideoOnDemandMetrics;
  @Prop() private streamType?: number;
  private isMobileView = false;
  private mainChart: any = '';
  private optionNames: any[] =  [];
  private UniqueViewers: number[] = [];
  private WatchViewers: number[] = [];
  private More = require('highcharts/highcharts-more');
  private Exporting = require('highcharts/modules/exporting');
  private ExportingData = require('highcharts/modules/export-data');
  private ChartAnnotations = require('highcharts/modules/annotations');
  private localVodVideoAnalytics: VideoOnDemandMetrics = VideoOnDemandMetrics.createEmpty();
  private surveypieLegends: any = '';
  private pieLegendsForWatchedusers: any = '';
  private Title = '';
  private showCancelButton = false;
  private normalColorTheme: any = {
    BackgroundColor: '#ffffff',
    FontColor: '#231F20',
    LabelColor: '#231F20',
    ChartColors: ['#88CA5E', '#3a3a3a', '#60A3D9', '#7E6099', '#FFA138', '#3998CC', '#F5322B', '#3DD8BD', '#CC325C', '#59CC5A', '#404F99', '#3C9799', '#6D6E71'],
  };
  private mounted() {
    this.$root.$on('show-detailed-section', (show: boolean) => {
      this.showCancelButton = show;
    });
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.localVodVideoAnalytics = this.videoOnDemandMetrics!;

    if (this.streamType === VodTypeEnums.RTMP && this.localVodVideoAnalytics !== undefined && this.localVodVideoAnalytics !== null) {
      this.Title = this.$t('VirtualMeetings.RtmpRecorded.Analytics.Title').toString();
      this.ShowRtmpVideoMetricsGraph();
    } else {
      this.Title = this.$t('VirtualMeetings.PreRecorded.Analytics.Title').toString();
      this.ShowVodVideoMetricsGraph(this.streamType);
    }
  }
  private initializeHighCharts() {
    this.More(Highcharts);
    this.Exporting(Highcharts);
    this.ExportingData(Highcharts);
    this.ChartAnnotations(Highcharts);
  }
  private Cancel() {
    this.$root.$emit('show-pre-recorded-video-analytics-section', false);
    this.$root.$emit('program-scroll-to-element', 'programVirtualMeetingDiv');
  }
  private ShowVodVideoMetricsGraph(type: number|undefined) {
    this.initializeHighCharts();
    const graph_unique_viewers = document.getElementById('graph_unique_viewers') as HTMLBodyElement;
    if (graph_unique_viewers !== null) {
      this.optionNames = [];
      if (type === VodTypeEnums.MeetAppLiveRecording) {
        this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountUniqueViewersDuringSession').toString());
        this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountAdditionalViewersInEvent').toString());
        this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountViewersBeforeAndAfterEvent').toString());
      } else {
        if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
          this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountUniqueViewersDuringSession').toString());
          this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountAdditionalViewersInEvent').toString());
        } else {
          this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountUniqueViewersDuringSessionInformation').toString());
        }
        this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountViewersBeforeAndAfterEvent').toString());
      }
      this.UniqueViewers.push(this.localVodVideoAnalytics.CountUniqueViewersDuringSession);
      if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
        this.UniqueViewers.push(this.localVodVideoAnalytics.CountAdditionalViewersInEvent);
      }
      this.UniqueViewers.push(this.localVodVideoAnalytics.CountViewersBeforeAndAfterEvent);
      this.setUniqueViewerLegends();

      this.graph(this.normalColorTheme, this.surveypieLegends, 'graph_unique_viewers', 'Viewers', this.UniqueViewers, '');
    }
    const graph_watched_users = document.getElementById('graph_watched_users') as HTMLBodyElement;
    if (graph_watched_users !== null) {
      this.optionNames = [];

      if (type === VodTypeEnums.MeetAppLiveRecording) {
        this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountUniqueViewersDuringSessionMinutes').toString());
        this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountAdditionalViewersInEventMinutes').toString());
        this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountViewersBeforeAndAfterEventMinutes').toString());
      } else {
        if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
          this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountUniqueViewersDuringSessionMinutes').toString());
          this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountAdditionalViewersInEventMinutes').toString());
        } else {
          this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountUniqueViewersDuringSessionMinutesInformation').toString());
        }
        this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountViewersBeforeAndAfterEventMinutes').toString());
      }
      this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedDuringSession);
      if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
        this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedDuringEvent);
      }
      this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedBeforeAndAfterEvent);
      this.setWatchTimeLegends();

      this.graph(this.normalColorTheme, this.pieLegendsForWatchedusers, 'graph_watched_users', 'Watch time', this.WatchViewers, 'mins');
    }
  }
  private ShowRtmpVideoMetricsGraph() {
    this.initializeHighCharts();
    const graph_unique_viewers = document.getElementById('graph_unique_viewers') as HTMLBodyElement;
    if (graph_unique_viewers !== null) {
      this.optionNames = [];
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountUniqueViewersDuringSession').toString());
      if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
        this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountAdditionalViewersInEvent').toString());
      }
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountViewersBeforeAndAfterEvent').toString());
      this.UniqueViewers.push(this.localVodVideoAnalytics.CountUniqueViewersDuringSession);
      if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
        this.UniqueViewers.push(this.localVodVideoAnalytics.CountAdditionalViewersInEvent);
      }
      this.UniqueViewers.push(this.localVodVideoAnalytics.CountViewersBeforeAndAfterEvent);
      this.setRtmpUniqueViewerLegends();

      this.graph(this.normalColorTheme, this.surveypieLegends, 'graph_unique_viewers', 'Viewers', this.UniqueViewers, '');
    }
    const watchedusers = document.getElementById('graph_watched_users') as HTMLBodyElement;
    if (watchedusers !== null) {
      this.optionNames = [];
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountUniqueViewersDuringSessionMinutes').toString());
      if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
        this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountAdditionalViewersInEventMinutes').toString());
      }
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountViewersBeforeAndAfterEventMinutes').toString());
      this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedDuringSession);
      if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
        this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedDuringEvent);
      }
      this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedBeforeAndAfterEvent);
      this.setRtmWatchTimeLegends();

      this.graph(this.normalColorTheme, this.pieLegendsForWatchedusers, 'graph_watched_users', 'Watch time', this.WatchViewers, 'mins');
    }
  }

  private graph(chartoptions: any, legends: any, chartId: string, chartTitle: string, series: number[], units: string) {
    let charttype: any = null;
    charttype = 'pie';
    this.mainChart = Highcharts.chart({
      chart: {
        renderTo: chartId,
        type: 'pie',
        height: 300,
      },
      title: {
        text: chartTitle,
        align: 'left',
        style: {
          fontFamily: 'Open Sans',
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.optionNames,
        lineWidth: 3,
        lineColor: '#000000',
        labels: {
          style: {
            fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: 13 + 'px', color: chartoptions.LabelColor,
          },
          formatter() {

            const newstr = this.value.toString().length > 75 ? '<span style="fontFamily: \'Open Sans\'; max-width: 200px; height: 30px; color: \'black\'">' + this.value.toString().substring(0, 75) + '... </span>' : '<span style="fontFamily: \'Open Sans\' max-width: 200px; height: 30px; color: \'black\'">' + this.value.toString() + '</span>';
            return newstr;
          },
        },
      } as Highcharts.XAxisOptions,
      yAxis: {
        title: false,
        tickInterval: 0,
        visible: false,
        labels: {
          format: '{value}',
          overflow: 'justify',
        },
        opposite: false,
      } as Highcharts.YAxisOptions,
      colors: chartoptions.ChartColors,

      tooltip: {
        formatter() {
          const sliceIndex = this.point.index;
          const sliceName = this.series.chart.axes[0].categories[sliceIndex];
          return '<b>' + sliceName + '</b> : <b>' + this.y + '</b>';
        },
      },
      plotOptions: {
        column: {
          colorByPoint: true,
        },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          size: 200,
          showInLegend: true,
          dataLabels: {
            enabled: true,
            format: '{y}',
            color: '#000000',
            connectorColor: '#000000',
          },
          point: {
            events : {
              legendItemClick(e) {
                e.preventDefault();
              },
            },
          },
        }
        , series: {
          dataLabels: {
            enabled: true,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      legend: legends,
      exporting: {
        allowHTML: true,
        sourceWidth: 700,
        chartOptions: {
          title: {
            text: '',
          },
          xAxis: {
            labels: {
              padding: 25,
            },
          },
        },
        buttons: {
          contextButton: {
            x: -5,
            menuItems: [
              'viewFullscreen', 'printChart', 'separator',
              'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG',
            ],
          },
        },
        enabled: true,
      },
      series: [
        {
          type: charttype,
          pointPadding: 0,

          showInLegend: true,
          data: series,
          colorByPoint: true,
          dataLabels:  { align: 'left', color: '#000000', format: '{y}' + ' ' + units},
        },
      ],
    });
  }
  private setUniqueViewerLegends() {
    this.surveypieLegends = {
      width: 350,
      floating: false,
      align: 'right',
      x: 5,
      itemWidth: 350,
      borderWidth: 0.3,
      symbolRadius: 0,
      itemStyle: {
        fontWeight: 'lighter',
      },
      title: {

        text: '<div style="margin-top: -40px; padding-right: 20px;"><span><b>A total of ' + this.localVodVideoAnalytics.TotalPeopleWatched + ' </b> viewers <b>watched</b> the video <b>' + this.localVodVideoAnalytics.TotalTimesWatched  + ' times</b></span></div>',
        style: {
          fontWeight: 'normal',
          fontFamily: 'Open Sans',
        },
      },
      enabled: true,
      layout: 'vertical',
      useHTML: true,
      verticalAlign: 'middle',
      labelFormatter() {
        const legendIndex = this.index;
        const legendName = this.series.chart.axes[0].categories[legendIndex];
        return legendName;
      },
    };
  }
  private setRtmpUniqueViewerLegends() {
    this.surveypieLegends = {
      width: 350,
      floating: false,
      align: 'right',
      x: 5,
      itemWidth: 350,
      borderWidth: 0.3,
      symbolRadius: 0,
      itemStyle: {
        fontWeight: 'lighter',
      },
      title: {

        text: '<div style="margin-top: -40px; padding-right: 20px;"><span><b>A total of ' + this.localVodVideoAnalytics.TotalPeopleWatched + ' </b> viewers <b>watched</b> the session <b>' + this.localVodVideoAnalytics.TotalTimesWatched  + ' times</b></span></div>',
        style: {
          fontWeight: 'normal',
          fontFamily: 'Open Sans',
        },
      },
      enabled: true,
      layout: 'vertical',
      useHTML: true,
      verticalAlign: 'middle',
      labelFormatter() {
        const legendIndex = this.index;
        const legendName = this.series.chart.axes[0].categories[legendIndex];
        return legendName;
      },
    };
  }
  private setWatchTimeLegends() {
    this.pieLegendsForWatchedusers = {
      width: 350,
      floating: false,
      align: 'right',
      x: 5,
      height: 300,
      itemWidth: 350,
      borderWidth: 0.3,
      padding: 5,
      symbolRadius: 0,
      itemStyle: {
        fontWeight: 'lighter',
      },
      title: {

        text: '<div style="margin-top: -60px; padding-right: 20px;"><span>The video has been played for<b> a total of ' + this.localVodVideoAnalytics.TotalTimeSpanWatched.toFixed(2) + ' minutes</b> The average viewing time per viewer is <b>' + this.localVodVideoAnalytics.AverageTimePerUserWatched + ' mins </b></span></div>',
        style: {
          fontWeight: 'normal',
        },
      },
      enabled: true,
      layout: 'vertical',
      useHTML: true,
      verticalAlign: 'middle',
      labelFormatter() {
        const legendIndex = this.index;
        const legendName = this.series.chart.axes[0].categories[legendIndex];
        return legendName;
      },
    };
  }
  private setRtmWatchTimeLegends() {
    this.pieLegendsForWatchedusers = {
      width: 350,
      floating: false,
      align: 'right',
      x: 5,
      height: 300,
      itemWidth: 350,
      borderWidth: 0.3,
      padding: 5,
      symbolRadius: 0,
      itemStyle: {
        fontWeight: 'lighter',
      },
      title: {

        text: '<div style="margin-top: -60px; padding-right: 20px;"><span>The session has been watched for<b> a total of ' + this.localVodVideoAnalytics.TotalTimeSpanWatched.toFixed(2) + ' minutes</b>. The average viewing time per viewer is <b>' + this.localVodVideoAnalytics.AverageTimePerUserWatched.toFixed(2) + ' mins </b></span></div>',
        style: {
          fontWeight: 'normal',
        },
      },
      enabled: true,
      layout: 'vertical',
      useHTML: true,
      verticalAlign: 'middle',
      labelFormatter() {
        const legendIndex = this.index;
        const legendName = this.series.chart.axes[0].categories[legendIndex];
        return legendName;
      },
    };
  }
}
