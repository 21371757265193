





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MultimediaItem } from '@/entities/Program/MultimediaItem';
import { ProgramMultimediaItemResult } from '@/entities/Program/ProgramMultimediaItemResult';
import { required, minLength } from 'vuelidate/lib/validators';
import loading from '@/components/Common/loading.vue';
@Component({
  validations: {
    localMultimediaItem: {
      Title: {
        required,
        minLength: minLength(1),
      },
      ThumbUrl: {
        required,
      },
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
  components: {
    loading,
  },
})
export default class ProgramMultimediaEditItemPane extends Vue {
  @Prop() private selectedMultimediaItem?: MultimediaItem;
  @Prop() private applicationborderColor?: string;
  @Prop() private selectedProgramId?: number;
  public showLoading = false;
  public loaderBorderColor = '';
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private showImageProcess = false;
  public localMultimediaItem: MultimediaItem | null = null;

  private Cancel() {
    this.$v.$reset();
    // update full image src in selectedMultimedia item for cancel operation
    this.selectedMultimediaItem!.Url = this.selectedMultimediaItem!.OldUrl;
    this.selectedMultimediaItem!.ThumbUrl = this.selectedMultimediaItem!.OldThumbUrl;
    this.selectedMultimediaItem!.Title = this.selectedMultimediaItem!.OldTitle;
    this.$emit(
      'program-multimedia-item-cancel-operation',
      this.selectedMultimediaItem,
    );
  }

  private clickOnImage(val: boolean) {
    // retain textbox value if switch to image component
    this.selectedMultimediaItem!.Title = this.localMultimediaItem!.Title;
    this.showImageProcess = val;
    this.$emit('program-multimedia-item-imageUpload-operation', val);
  }
  private GetImageUrl() {
    return this.localMultimediaItem!.ThumbUrl === null
      ? this.missingImage
      : this.localMultimediaItem!.ThumbUrl === ''
        ? this.missingImage
        : this.localMultimediaItem!.ThumbUrl;
  }
  private created() {
    if (this.selectedMultimediaItem != null) {
      this.localMultimediaItem = MultimediaItem.createEmpty();
      // to retain values for cancel operation
      if (
        this.selectedMultimediaItem.OldUrl === '' ||
        this.selectedMultimediaItem.OldUrl == null
      ) {
        this.selectedMultimediaItem.OldUrl = this.selectedMultimediaItem.Url;
        this.selectedMultimediaItem.OldThumbUrl = this.selectedMultimediaItem.ThumbUrl;
        this.selectedMultimediaItem.OldTitle = this.selectedMultimediaItem.Title;
      }

      this.localMultimediaItem = Object.assign(
        this.localMultimediaItem,
        this.selectedMultimediaItem,
      );
    }
  }

  private Submit() {
    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }
    this.$v.$reset();
    // No errors submitting.
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    this.$axios
      .post<ProgramMultimediaItemResult>(
      '/Program/EditProgramMedia',
      this.localMultimediaItem,
    )
      .then((response) => {
        if (response.data.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.data.ValidationErrors[0]));
        } else {
          // need information send to signalr
          const multimediaItemData = {
            dataItem: response.data.MultimediaItem,
            programDetailType: 'Multimedia',
          };
          this.$root.$emit('multimedia-update-signalr', multimediaItemData);

          this.$emit(
            'program-multimedia-item-edit-operation',
            response.data.MultimediaItem,
          );
          this.$root.$emit(
            'program-multimedia-item-edit-operation',
            response.data.MultimediaItem,
          );
        }
      })
      .catch(() => {
        // Log error if required
      });
  }
}
