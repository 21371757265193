import ActionButtonViewModel from '../ActionButtonViewModel';
import i18n from '@/i18n';
export default class ImportDataViewModel {
  public static createEmpty(): ImportDataViewModel {
    return new ImportDataViewModel();
  }
  constructor(
    public Heading: string = '',
    public Description: string = '',
    public FileOperation: ActionButtonViewModel[] = [],
  ) {}
  public static GetImportFileData(moduleType: number) {
    switch(moduleType) {
    case 0:
      return new ImportDataViewModel(i18n.t('Import.ProgramImport.Heading').toString(), i18n.t('Import.ProgramImport.Description').toString(),[{Heading: i18n.t('Import.ProgramImport.ActionButton.CreateNewHeading').toString(), Description: i18n.t('Import.ProgramImport.ActionButton.CreateNewDiscription').toString(), ButtonAction: 0, ButtonText: i18n.t('Import.ProgramImport.ActionButton.CreateNewButtonText').toString()},{Heading: i18n.t('Import.ProgramImport.ActionButton.UpdateHeading').toString(), Description: i18n.t('Import.ProgramImport.ActionButton.UpdateDiscription').toString(), ButtonAction: 1, ButtonText: i18n.t('Import.ProgramImport.ActionButton.UpdateButtonText').toString()}]);
    default:
      return ImportDataViewModel.createEmpty();
    }
  }
}
