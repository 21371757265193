/* eslint-disable max-len */
import { BaseSignalRModelRequestJSON } from '@/signalRRequest/SignalRModelRequest';
import Common from '@/helper/Common';

export class ProgramEvaluationQuestionsItem {
  public static createEmpty(): ProgramEvaluationQuestionsItem {
    return new ProgramEvaluationQuestionsItem();
  }


  public static GetEvaluationQuestionItemFromProgramEvaluationQuestionItemSignalR(evalItem: ProgramEvaluationQuestionsItem, evaluationQuestion: ProgramEvaluationQuestionsItemSignalR): ProgramEvaluationQuestionsItem {
    evalItem.Id = evaluationQuestion.Id;
    evalItem.Question = evaluationQuestion.Question;
    evalItem.SortOrder = evaluationQuestion.SortOrder;
    evalItem.Type = evaluationQuestion.Type;
    evalItem.Anynomous = evaluationQuestion.Anynomous;
    evalItem.ProgramEvaluationQuestionType = evaluationQuestion.ProgramEvaluationQuestionType;
    evalItem.SelectedPrograms = evaluationQuestion.SelectedPrograms;
    evalItem.SelectedProgramIds = evaluationQuestion.SelectedProgramIds;
    return evalItem;
  }
  constructor(
    public Id: number = 0,
    public Question: string = '',
    public SortOrder: number = 0,
    public Type: string = 'Grade',
    public GradeRange: number|null = 0,
    public Anynomous: boolean|null= true,
    public IsDeleted: boolean= false,
    public SelectedPrograms: string= '',
    public SelectedProgramIds: string= '',
    public defaultBGColor: string = '#f9f9f9',
    public ProgramEvaluationQuestionType: string = 'Grade',
    public Modified: Date = new Date(),
    public ApplicationInstanceId: number = 0,
    public DisableItem: boolean= false,
  ) { }
  public SetProgramEvaluationQuestionObject(val: ProgramEvaluationQuestionsItem): any {
    return {
      Question: val.Question,
      Type: val.Type,
      Anynomous: val.Anynomous,
      SortOrder: val.SortOrder,
      SelectedProgramIds: this.GetSelectedProgramIds(val),
    };
  }

  public CompareProgramObjects({ val1, val2 }: { val1: ProgramEvaluationQuestionsItem; val2: ProgramEvaluationQuestionsItem }): boolean {
    const programEvalQuestionItem1 = this.SetProgramEvaluationQuestionObject(val1);
    const programEvalQuestionItem2 = this.SetProgramEvaluationQuestionObject(val2);
    if (Common.prototype.CompareObjects(programEvalQuestionItem1, programEvalQuestionItem2)) {
      return true;
    } else {
      return false;
    }
  }

  private GetSelectedProgramIds(val: ProgramEvaluationQuestionsItem): string {
    let result = '';
    if (val.SelectedProgramIds && val.SelectedProgramIds !== '') {
      result = val.SelectedProgramIds.split(',').map((item) => parseInt(item, 10)).sort().toString();
    }
    return result;
  }
}

export class ProgramEvaluationQuestionsItemSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): ProgramEvaluationQuestionsItemSignalR {
    return new ProgramEvaluationQuestionsItemSignalR();
  }

  public static GetProgramEvaluationQuestionsItemSignalR(evaluationQuestion: ProgramEvaluationQuestionsItem): ProgramEvaluationQuestionsItemSignalR {
    const item: ProgramEvaluationQuestionsItemSignalR = ProgramEvaluationQuestionsItemSignalR.createEmpty();
    item.Id = evaluationQuestion.Id;
    item.Question = evaluationQuestion.Question;
    item.SortOrder = evaluationQuestion.SortOrder;
    item.Type = evaluationQuestion.Type;
    item.Anynomous = evaluationQuestion.Anynomous;
    item.ProgramEvaluationQuestionType = evaluationQuestion.ProgramEvaluationQuestionType;
    item.SelectedPrograms = evaluationQuestion.SelectedPrograms;
    item.SelectedProgramIds = evaluationQuestion.SelectedProgramIds;
    item.selectedName = evaluationQuestion.Question;
    item.selectedCount = 1;
    return item;
  }

  constructor(
    public Id: number = 0,
    public Question: string = '',
    public SortOrder: number = 0,
    public Type: string = 'Grade',
    public Anynomous: boolean|null= false,
    public ProgramEvaluationQuestionType: string = 'Grade',
    public SelectedPrograms: string= '',
    public SelectedProgramIds: string= '',
  ) {
    super(0, '');
  }
}
