import { Create } from '../Vod/VodFactoryCreator';
import { VodBaseRenderModel } from '../Vod/VodBaseRenderModel';
import VodModuleTypeEnum from '@/enums/VodModuleTypeEnum';
import i18n from '@/i18n'; // its used for translation. i18n.t() same as $t
import { VodDetail } from '@/entities/VodDetail';
import VideoScheduleEnums from '@/enums/VideoScheduleEnums';
import VirtualMeetingtypeParentEnum from '@/enums/VirtualMeetingtypeParentEnum';

export class ProgramRenderer implements Create {
  public GetValues(vodDetail: VodDetail, entityId: number, sessionStartDate: Date, uploadUrl: string, acceptedVideoFileTypes: string, acceptedCaptionFileTypes: string, subModuleType: number): VodBaseRenderModel {
    const ProgramRendererBaseClass: VodBaseRenderModel = new VodBaseRenderModel();
    ProgramRendererBaseClass.Moduletype = VodModuleTypeEnum[0].toString();
    ProgramRendererBaseClass.SubModuleType = subModuleType;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-unused-expressions
    ProgramRendererBaseClass.SubModuleTypeValue = VirtualMeetingtypeParentEnum[subModuleType].toString(),
    ProgramRendererBaseClass.ShowAddCaption = true;
    ProgramRendererBaseClass.ShowPublish = true;
    const PublishItemList =  [{ Text : i18n.t('VirtualMeetings.AddVideo.PublishNow').toString() , Value : 1 },
      { Text : i18n.t('VirtualMeetings.AddVideo.FifteenMinutesBefore').toString() , Value : 2 },
      { Text : i18n.t('VirtualMeetings.AddVideo.CustomPublish').toString() , Value : 3 }];
    ProgramRendererBaseClass.PublishItemList = PublishItemList;
    ProgramRendererBaseClass.VodDetail = vodDetail;
    ProgramRendererBaseClass.EntityId = entityId;
    ProgramRendererBaseClass.UploadUrl = uploadUrl;
    ProgramRendererBaseClass.AcceptedVideoFileTypes = acceptedVideoFileTypes;
    ProgramRendererBaseClass.AcceptedCaptionFileTypes = acceptedCaptionFileTypes;
    ProgramRendererBaseClass.PercentageVideoUploaded = 0;
    ProgramRendererBaseClass.SessionStartDate = sessionStartDate;
    ProgramRendererBaseClass.DefaultValue = VideoScheduleEnums.FifteenMinPriorToSession;
    ProgramRendererBaseClass.Title = i18n.t('Program.ProgramTab').toString();
    return ProgramRendererBaseClass;
  }
}
