












































































































































































































import { ProgramItem } from '@/entities/Program/ProgramItem';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MeetappPricingDetails from '@/components/Application/MeetappPricingDetails.vue';
import { ProductPrice } from '@/entities/ProductPrice';
import { VonageSessionData } from '@/entities/VonageSessionData';
import loading from '@/components/Common/loading.vue';
import { ConsumptionBasedProductPrice } from '@/entities/ConsumptionBasedProductPrice';
import MeetAppLiveConsolidatedDetails from '@/components/Common/VirtualMeetings/MeetAppLiveConsolidatedDetails.vue';
import moment from 'moment-timezone';
import VodTypeEnums from '@/enums/VodTypeEnums';
@Component({
  components: {
    loading,
    MeetappPricingDetails,
    MeetAppLiveConsolidatedDetails,
  },
})
export default class VirtualMeetingMeetAppLive extends Vue {
  @Prop() private selectedItem?: ProgramItem;
  @Prop() private showStudioLink?: boolean;
  @Prop() private vonageThresholdLimitReached?: boolean;
  @Prop() private moduleType?: string;
  private isMobileView = false;
  private showLoading = false;
  private isiPadView = false;
  private showVonageDiv = false;
  private showVonageAnalyticsDiv = false;
  private showVonageStudioLink = false;
  private isVonageLinkGenerated = false;
  private showCopyToClipboard = false;
  private showCopiedMessage?: boolean = false;
  private linkCopiedFadeoutTime = 1500;
  private showMeetappPricingDetailsPopup = false;
  private pricingPopupType: number = VodTypeEnums.MeetAppLive;
  private vonageAnalytics: VonageSessionData | null = null;
  private meetAppLiveExtendedMinutes = 0;
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private fileDownload = require('js-file-download');
  private loaderBorderColor = '';
  private meetAppLivePricingInformation: ProductPrice = ProductPrice.createEmpty();
  private meetAppLiveAnalyticsdata: VonageSessionData = VonageSessionData.createEmpty();
  private showVonageRecordingOption = false;
  private isVideoUnavailableInfo = false;
  private IsRecordedSession = true;
  private IsRecordedAutomatically = false;
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private isAutomatically = false;
  private appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
  private mounted() {
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
      this.isiPadView = false;
    } else if (window.innerWidth <= 1100) {
      this.isMobileView = false;
      this.isiPadView = true;
    } else {
      this.isMobileView = false;
      this.isiPadView = false;
    }
    if (this.selectedItem!.Id > 0) {
      // set value of IsRecordedAutomatically and IsRecordedSession when data if available
      this.IsRecordedSession = this.selectedItem!.IsRecordedSession;
      if (this.selectedItem!.IsRecordedAutomatically) {
        this.isAutomatically = true;
      }
      if (this.IsRecordedSession) {
      // show automatically and manually radioBox if IsRecordedSession have data
        this.showVonageRecordingOption = true;
      }
      this.isVonageLinkGenerated = this.showStudioLink!;
      this.showVonageStudioLink = this.showStudioLink!;
      this.showCopyToClipboard = true;
    }
    this.$root.$on('show-meetapp-live-pricing-popup', (val: boolean) => {
      this.showMeetappPricingDetailsPopup = val;
    });
    this.$root.$on('ProgramVonageSessionData', (vonageData: VonageSessionData) => {
      if (vonageData !== null && vonageData !== undefined) {
        this.showVonageAnalyticsDiv = true;
        this.$root.$emit('virtual-meeting-meetapp-live-analytics-available', true);
        this.vonageAnalytics = vonageData;
      }
    });
    if(this.selectedItem!.VonageSessionData){
      this.showVonageAnalyticsDiv = true;
      this.vonageAnalytics = this.selectedItem!.VonageSessionData;
    }
    this.$root.$on('reload-eventsettingtab', () => {
      this.appInfo = this.storeHelper.GetRequestInfoFromStore();
      this.isEnableMeetAppLiveRecording();
    });
  }
  private isEnableMeetAppLiveRecording(): boolean {
    if (this.appInfo.EnableMeetAppLiveArchiving) {
      return true;
    }
    return false;
  }
  // Method to copy Link to Clipboard
  private copyPublicLinkToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.selectedItem!.VirtualMeetingLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.showCopiedMessage = true;
      } else {
        alert('Oops, unable to copy');
      }
    } catch (err) {
      alert('Oops, unable to copy');
    }
    if (this.showCopiedMessage) {
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, this.linkCopiedFadeoutTime);
    }
    document.body.removeChild(selBox);
  }
  private ShowMeetAppLivePricingPopUp(val: string) {
    if (val === 'MeetAppPricing') {

      const meetAppLivepricingInfo: ConsumptionBasedProductPrice = this.storeHelper.GetconsumptionBasedProductPricingFromStore();
      this.meetAppLivePricingInformation = meetAppLivepricingInfo.MeetAppLiveProductPrice!;
      this.pricingPopupType = VodTypeEnums.MeetAppLive;
      this.showMeetappPricingDetailsPopup = true;
    } else {

      const meetAppLivepricingInfo: ConsumptionBasedProductPrice = this.storeHelper.GetconsumptionBasedProductPricingFromStore();
      this.meetAppLivePricingInformation = meetAppLivepricingInfo.PreRecordedVideoProductPrice!;
      this.showMeetappPricingDetailsPopup = true;
      this.pricingPopupType = VodTypeEnums.MeetAppLiveRecording;
    }
  }
  private OpenVonageLiveStream() {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();

    const link: string = this.selectedItem!.VirtualMeetingLink + '&firstName=' + reqInfo.FirstName + '&lastName=' + reqInfo.LastName;
    window.open(
      link,
      '_blank',
    );
  }
  // Get MeetApp live meeting URL
  private GetLiveVideoLink(): any {
    this.showCopyToClipboard = false;
    if (this.appInfo.EnableMeetAppLiveArchiving) {
      this.IsRecordedSession = true;
      if (this.IsRecordedSession) {
        this.isAutomatically = true;
        this.IsRecordedAutomatically = true;
        this.$root.$emit('set-IsRecorded-Session', true);
        this.$root.$emit('set-IsRecorded-Automatically', true);
        this.showVonageRecordingOption = true;
      }
    } else {
      this.$root.$emit('set-IsRecorded-Session', false);
      this.$root.$emit('set-IsRecorded-Automatically', false);
    }
    if ((this.$refs.getVonageLiveVideoLinkCheckbox as HTMLInputElement).checked) {
      this.showVonageStudioLink = true;
      if (this.selectedItem!.Id > 0) {
        this.selectedItem!.VirtualMeetingLink = this.$t('VirtualMeetings.GenerateLink').toString();
        this.selectedItem!.VirtualMeetingType = 'Vonage';
        this.isVonageLinkGenerated = true;
        this.$axios
          .post('/'+ (this.moduleType === 'Entity' ? 'ApplicationInstance' : 'program') +'/GetVonageLiveStreamingStudioLink', {
            entityId: this.selectedItem!.Id,
            isRecorded: this.IsRecordedSession,
            isRecordedAutomatically: this.IsRecordedAutomatically,
          })
          .then((response) => {
            this.selectedItem!.VirtualMeetingLink = response.data.VonageLink;
            this.showCopyToClipboard = true;
          })
          .catch(() => {
            // Log error if required
          });
      } else {
        this.selectedItem!.VirtualMeetingLink = this.$t('VirtualMeetings.LinkWillBeGeneratedAfterSave').toString();
        this.selectedItem!.VirtualMeetingType = 'Vonage';
      }
    } else {
      this.selectedItem!.VirtualMeetingLink = '';
      this.showVonageStudioLink = false;
    }
  }
  private isMeetAppLiveMeetingValid(): boolean {
    let isvalidProgram = true;
    const todaynowString = new Date().toUTCString();
    const programEndDateString = new Date(this.selectedItem!.EndDateUTC).toUTCString();
    const todaynow = moment(todaynowString);
    const programEndDate = moment(programEndDateString);
    // this.selectedItem!.EndDate.getTime() + (this.meetAppLiveExtendedMinutes * 60 * 1000);
    // commented code line is for adding extended minutes to the end date of program
    if (todaynow > programEndDate) {
      if (this.vonageAnalytics === undefined || this.vonageAnalytics === null) {
        if (this.selectedItem!.Id !== 0) {
          isvalidProgram = false;
        }
      }
    }
    return isvalidProgram;
  }
  // show or hide meetApp live recording options
  private GetRecordingOption() {
    if ((this.$refs.getVonageLiveVideoRecordingCheckbox as HTMLInputElement).checked) {
      this.showVonageRecordingOption = true;
    } else {
      this.showVonageRecordingOption = false;
    }
  }
  private getInformationString(val: boolean) {
    this.isVideoUnavailableInfo = val;
  }
  private getInformationMsg() {
    return this.$t('VirtualMeetings.MeetAppLive.RecordingCheckBox').toString();
  }
  // bind value of meetapp live recording checkbox
  private MakeRecordingAvailable() {
    this.IsRecordedAutomatically = true;
    if ((this.$refs.getVonageLiveVideoRecordingCheckbox as HTMLInputElement).checked) {
      this.IsRecordedSession = true;
      this.$root.$emit('set-IsRecorded-Session', true);
    } else {
      this.IsRecordedAutomatically = false;
      this.IsRecordedSession = false;
      this.$root.$emit('set-IsRecorded-Session', false);
    }
  }
  // bind value of meetApp live recording radio buttons
  private MakeRecordingAutomatically(val: boolean) {
    this.IsRecordedAutomatically = val;
    this.$root.$emit('set-IsRecorded-Automatically', val);
    if ((this.$refs.getVonageLiveVideoRecordingCheckbox as HTMLInputElement).checked) {
      this.IsRecordedSession = true;
      this.$root.$emit('set-IsRecorded-Session', true);
    }
  }
  @Watch('selectedItem')
  private onPropertyChanged(value: ProgramItem) {
    this.vonageAnalytics = null;
    this.$root.$emit('virtual-meeting-meetapp-live-analytics-available', false);
    this.showVonageAnalyticsDiv = false;
    if (value.Id === 0) {
      this.showVonageAnalyticsDiv = false;
    }
    // set value of meetApp live recording radiobox and checkbox while switching programs
    this.IsRecordedSession = value.IsRecordedSession;
    this.showVonageRecordingOption = false;
    if (value.IsRecordedSession) {
      this.showVonageRecordingOption = true;
    }
    this.isAutomatically = value.IsRecordedAutomatically;
    if (value.IsRecordedAutomatically) {
      this.isAutomatically = true;
      this.IsRecordedAutomatically = true;
    }
    this.isVonageLinkGenerated = this.showStudioLink!;
    this.showVonageStudioLink = this.showStudioLink!;
  }
}
