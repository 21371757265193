















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProgramEvaluationQuestionsItem } from '@/entities/Program/ProgramEvaluationQuestionsItem';
import loading from '@/components/Common/loading.vue';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { StoreHelper } from '@/store/StoreHelper';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { DateTimeFormatOptions } from 'vue-i18n';
@Component({
  components: {
    loading,
    ModalPopup,
  },
})
export default class ProgramEvaluationQuestionsListView extends Vue {
  @Prop() private evaluationQuestions?: ProgramEvaluationQuestionsItem[];
  @Prop() private selectedProgramId?: number;
  @Prop() private globalProgramEvaluation?: boolean;
  private localEvaluationLoading?: boolean = false;
  private localEvaluationQuestions?: ProgramEvaluationQuestionsItem[] = [];
  private loaderBorderColor = '';
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private showLoading = false;
  private tempSelectedEvaluationQuestionsItem: ProgramEvaluationQuestionsItem | null = null;
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private loadingImage: string = require('@/assets/Images/loading.png');
  private fileDownload = require('js-file-download');
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private mounted() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, this.dateOptions);
    if (this.evaluationQuestions != null) {
      this.localEvaluationQuestions = this.evaluationQuestions;
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.$root.$on('programDetailLoading', (loading: boolean) => {
      this.localEvaluationLoading = loading;
    });
    this.$root.$on(
      'evaluationQuestionData',
      (evaluationQuestions: ProgramEvaluationQuestionsItem[]) => {
        this.localEvaluationQuestions = evaluationQuestions;
      },
    );
    this.$root.$on('clearEvaluationQuestions', () => {
      this.localEvaluationQuestions = [];
    });
  }
  private dateOptions: DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  };
  private dateFormat = new Intl.DateTimeFormat();

  private formatDate(pi: ProgramEvaluationQuestionsItem): string {
    return this.dateFormat.format(pi.Modified).toUpperCase();
  }
  private formatTime(pi: ProgramEvaluationQuestionsItem): string {
    return pi.Modified.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    });
  }
  // Click on Yes/Cancel Button of Popup
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.DelinkEvaluationQuestionConfirmed(
        this.tempSelectedEvaluationQuestionsItem!,
      );
    } else {
      return;
    }
  }
  private DelinkEvaluationQuestion(
    programEvaluationQuestionsItem: ProgramEvaluationQuestionsItem,
  ) {
    this.tempSelectedEvaluationQuestionsItem = programEvaluationQuestionsItem;
    this.modalPopupVisible = true;
    this.modalPopupContent = this.$t(
      'EvaluationQuestions.DeleteEvaluationFromProgram',
    ).toString();
  }
  private DelinkEvaluationQuestionConfirmed(
    programEvaluationQuestionItem: ProgramEvaluationQuestionsItem,
  ) {
    this.$axios
      .post('/Program/DeleteEvaluationForProgram', {
        programEvaluationId: programEvaluationQuestionItem.Id,
        programId: this.selectedProgramId,
      })
      .then((response) => {
        if (response.data.success === false) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToRemove'));
        } else {
          // need information send to signalr
          const evaluationQuestionItem = {
            id: programEvaluationQuestionItem.Id,
            programDetailType: 'EvaluationQuestions',
          };
          this.$emit('evaluation-question-delink-program-notification', evaluationQuestionItem);
        }
      })
      .catch(() => {
        // Log error if required
      });
  }

  // Export Evaluations Questions Data for a particular Program
  private ExportResultsToExcel() {
    this.showLoading = true;
    this.$axios({
      url: '/FileDownload/ExportProgramEvaluation',
      method: 'POST',
      data: {
        programId: this.selectedProgramId,
      },
      headers: { Accept: 'application/vnd.ms-excel' },
      responseType: 'blob', // important
    })
      .then((response) => {
        this.fileDownload(response.data, 'EvaluationQuestions.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  // Export Evaluations Questions Data for a particular Evaluation Question
  private ExportSingleEvaluationResultToExcel(
    programEvaluationItem: ProgramEvaluationQuestionsItem,
  ) {
    this.showLoading = true;
    this.$axios({
      url: '/FileDownload/ExportProgramEvaluation',
      method: 'POST',
      data: {
        programEvaluationId: programEvaluationItem.Id,
        programId: this.selectedProgramId,
      },
      headers: { Accept: 'application/vnd.ms-excel' },
      responseType: 'blob', // important
    })
      .then((response) => {
        this.fileDownload(response.data, 'EvaluationQuestions.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
}
