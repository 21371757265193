

























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import loading from '@/components/Common/loading.vue';
import { SessionCheckInViewModel } from '@/entities/SessionCheckInViewModel';
import { maxValue, minValue, required } from 'vuelidate/lib/validators';
@Component({
  validations: {
    localSessionCheckInData: {
      MaxCheckInLimitQuantity: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100000),
      },
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
  components: {
    loading,
  },
})
export default class SessionCheckIn extends Vue {
  @Prop() private SessionCheckInData!: SessionCheckInViewModel;
  private localSessionCheckInData: SessionCheckInViewModel = SessionCheckInViewModel.createEmpty();
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private loadingImage: string = require('@/assets/Images/loading.png');
  private showLoading = false;
  private loaderBorderColor = '';
  private fileDownload = require('js-file-download');
  private areSeatsLimited = false;
  private isDataLoading = false;

  private mounted() {
    this.localSessionCheckInData = Object.assign({}, this.SessionCheckInData);
    this.AreSeatsLimited();
    this.$root.$on('programDetailLoading', (isLoading: boolean) => {
      this.isDataLoading = isLoading;
      this.AreSeatsLimited();
    });
  }
  private AreSeatsLimited() {
    if(this.localSessionCheckInData.MaxCheckInLimitQuantity > 0) {
      this.areSeatsLimited = true;
    } else {
      this.areSeatsLimited = false;
    }
  }
  private ExportResultsToExcel() {
    this.showLoading = true;
    this.$axios({
      url: '/Program/ExportCheckedInParticipantDataToExcel',
      method: 'POST',
      data: {
        programId: this.localSessionCheckInData.Id,
      },
      headers: { Accept: 'application/vnd.ms-excel' },
      responseType: 'blob',
    })
      .then((response) => {
        this.fileDownload(response.data, 'ParticipantExport.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private ToggleMaxSeats() {
    if (!this.areSeatsLimited) {
      this.localSessionCheckInData.MaxCheckInLimitQuantity = 1;
    } else {
      this.localSessionCheckInData.MaxCheckInLimitQuantity = -1;
    }
    this.SaveData();
  }
  private SaveData() {
    this.localSessionCheckInData.IsSessionCheckInEnabled = (this.$refs.chkSessionCheckInEnabled as HTMLInputElement).checked;
    this.$emit('change-in-session-check-in', this.localSessionCheckInData);
  }
  private GetCheckedInParticipantCount() {
    if (this.localSessionCheckInData.MaxCheckInLimitQuantity > -1) {
      return  this.localSessionCheckInData.CheckedInParticipantsCount + ' / ' + this.localSessionCheckInData.MaxCheckInLimitQuantity;
    } else {
      return this.localSessionCheckInData.CheckedInParticipantsCount;
    }
  }

  private ValidatePositiveInteger(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    this.localSessionCheckInData.MaxCheckInLimitQuantity = Number(value);
  }

  private GetCheckedInParticipantDetails() {
    this.$emit('show-checked-in-participant-details', true);
  }
  @Watch('SessionCheckInData', {deep: true})
  private ChangeInSessionCheckInData(val: SessionCheckInViewModel) {
    this.localSessionCheckInData = val;
  }
}
