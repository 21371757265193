/* eslint-disable max-len */
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import { AxiosInstance } from 'axios';
import { BaseBulkOperationsModel } from '../BaseBulkOperationsModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
export class ProgramBulkOperations extends BaseBulkOperationsModel {
  constructor(rootObj: any, axios: AxiosInstance, $store: any, $t: any) {
    super();
    const storeHelper: StoreHelper = new StoreHelper($store);
    const appInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    const isEnableFeaturedImage = !appInfo.EnableLinkProgramItemsToGroup;
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(rootObj, axios, $store, $t);
    this.arrBulkOperations.push({ Id: 1, Name: 'Delete', IconUrl: 'fa fa-trash-o font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Permanently delete your selected items', ConfirmationMessage: 'Notifications.SureDeleteProgramItems', ConfirmationMessageSubTitle: '', objModel: signalR.DeleteSelectedProgramSignalRModelRequest, ShowConfirmationPopup: true });

    if (isEnableFeaturedImage) {

      this.arrBulkOperations.push({ Id: 2, Name: 'Edit Icons & Images', IconUrl: 'fa fa-picture-o font-awesome-icon', IconPadding: 'padding:17px 17px 17px 0px;', Description: 'Apply an icon or a featured image to selected items', ConfirmationMessage: 'Notifications.SureUpdateProgramItemsImage', ConfirmationMessageSubTitle: '', objModel: signalR.EditSelectedProgramIconsAndImagesSignalRModelRequest, ShowConfirmationPopup: false });
    } else {

      this.arrBulkOperations.push({ Id: 2, Name: 'Edit Icons', IconUrl: 'fa fa-picture-o font-awesome-icon', IconPadding: 'padding:17px 17px 17px 0px;', Description: 'Apply an icon to selected items', ConfirmationMessage: 'Notifications.SureUpdateProgramItemsImage', ConfirmationMessageSubTitle: '', objModel: signalR.EditSelectedProgramIconsAndImagesSignalRModelRequest, ShowConfirmationPopup: false });
    }

    this.arrBulkOperations.push({ Id: 3, Name: 'Edit Date & Time', IconUrl: 'fa fa-clock-o font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Edit the date or time of your selected items', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkEditDateTimeSignalRModelRequest, ShowConfirmationPopup: false });

    // this.arrBulkOperations.push({ Id: 4, Name: 'Edit Location', IconUrl: 'fa fa-map-marker font-awesome-icon', IconPadding: 'padding:25px;padding-top:10px;', Description: 'Edit the room or location of your selected items', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: new SignalRModelRequest(), ShowConfirmationPopup: false });
    this.arrBulkOperations.push({ Id: 5, Name: 'Edit Tags', IconUrl: 'fa fa-tags font-awesome-icon', IconPadding: 'padding:17px 17px 17px 0px;', Description: 'Edit tags of your selected items for better organization', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkEditTagsSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 6, Name: 'Assign to Track', IconUrl: 'fa fa-sitemap font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Add or edit your selected items into tracks', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkAssignTracksSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 7, Name: 'Link Information', IconUrl: 'fa fa-link font-awesome-icon', IconPadding: 'padding:17px 17px 17px 0px;', Description: 'Link information items to your selected items', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkLinkInformationSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 8, Name: 'Export', IconUrl: 'fa fa-upload font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Exort all programs in one excel file', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.ExportProgramsSignalRModelRequest, ShowConfirmationPopup: false });
  }
}
