/* eslint-disable max-len */
import { BaseSignalRModel } from '../BaseSignalRModel';
import { AxiosInstance } from 'axios';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { SignalRModelRequest } from '../SignalRModelRequest';
import { Vue } from 'vue-property-decorator';
import SubModuleEnum from '@/enums/SubModuleEnum';
import ModuleEnum from '@/enums/ModuleEnum';
import { ProgramTrackItem, ProgramTrackItemSignalR } from '@/entities/Program/ProgramTrackItem';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';

const TrackSignalRModelRequestIds = {
  SingleCreate: 1,
  SingleEdit: 2,
  SingleDelete: 3,
};

export class TrackSignalRModel extends BaseSignalRModel {
  // Get SignalRModelRequest for adding a single track item
  get SingleCreateTrackItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(TrackSignalRModelRequestIds.SingleCreate);
  }
  // Get SignalRModelRequest for Updating a single track item
  get SingleUpdateTrackItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(TrackSignalRModelRequestIds.SingleEdit);
  }
  // Get SignalRModelRequest for Deleting a single program item
  get SingleDeleteTrackItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(TrackSignalRModelRequestIds.SingleDelete);
  }
  readonly programBatchOperationURL = '/BulkOperations/PerformBatchOperation';
  readonly programPostToClientsSignalRURL = '/BulkOperations/PostToClientsSignalR';
  constructor(public rootObj: any, public axios: AxiosInstance, public $store: any, public $t: any) {
    super(rootObj, axios, $store);
    const reqInfo: AppRequestInfo = this.GetRequestInfoFromStore();
    const loggedInUserFullName = reqInfo.FirstName + ' ' + reqInfo.LastName;
    const singleItemChangeUrl = 'api/SignalRProgram/PushProgramSignalRChanges';

    this.arrSignalRModelRequests.push(new SignalRModelRequest(TrackSignalRModelRequestIds.SingleCreate, ModuleEnum.Track, SubModuleEnum.SingleCreate, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, BaseSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, Vue.prototype.$SignalRBaseURL + singleItemChangeUrl, [], 'Notifications.SaveOperation', '', 'Notifications.SavingTrackItem', 'Notifications.SavedTrackItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleTrack'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(TrackSignalRModelRequestIds.SingleEdit, ModuleEnum.Track, SubModuleEnum.SingleEdit,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, BaseSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, Vue.prototype.$SignalRBaseURL + singleItemChangeUrl, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingTrackItem', 'Notifications.UpdatedTrackItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleTrack'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(TrackSignalRModelRequestIds.SingleDelete, ModuleEnum.Track, SubModuleEnum.SingleDelete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, BaseSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, Vue.prototype.$SignalRBaseURL + singleItemChangeUrl, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleTrackItem', 'Notifications.DeletedSingleTrackItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleTrack'));
  }
  // Add to Notification Popup that an operation has started
  public AddToNotificationPopup(signalRModelRequest: SignalRModelRequest) {
    this.EmitAddNewNotifications(signalRModelRequest);
  }
  // Get Track Item send to SignalR as JSON data
  public GetTrackItemSendToSignalR(programTrackItem: ProgramTrackItem): string {
    const item: ProgramTrackItemSignalR = ProgramTrackItemSignalR.GetProgramTracksItemSignalR(programTrackItem);
    return JSON.stringify(item);
  }
  // Perform Track Single Operations
  public PerformTrackSingleOperation(signalRModelRequest: SignalRModelRequest) {
    this.SendSignalRModelRequestToHubWithoutAddingToQueue(signalRModelRequest, this.programPostToClientsSignalRURL);
    this.SendSignalRModelRequestToQueueWithoutEmit(signalRModelRequest, this.programBatchOperationURL);
  }
  // Method that fires when Track SignalR response model is received from Hub
  public TrackSignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    this.SignalRModelResponseReceived(signalRModelRequest);
  }
}
