






















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { VonageSessionData } from '@/entities/VonageSessionData';
import { VideoOnDemandMetrics, VodVideoMetricsRequest } from '@/entities/VodDetail';
import PreRecordedMeetingHighcharts from '@/components/Common/PreRecordedMeetingHighcharts.vue';
import VirtualMeetingSessionAnalytics from '@/components/Common/VirtualMeetings/VirtualMeetingSessionAnalytics.vue';

@Component({
  components: {
    PreRecordedMeetingHighcharts,
    VirtualMeetingSessionAnalytics,
  },
})
export default class VirtualMeetingCombineAnalytics extends Vue {
  @Prop() private vonageAnalyticsForGraph?: VonageSessionData;
  @Prop() private selectedProgramId?: number;
  @Prop() private videoDetail?: VodVideoMetricsRequest;
  @Prop() private videoOnDemandMetrics?: VideoOnDemandMetrics;
  @Prop() private streamType?: number;
  @Prop() private moduleType?: string;
  private isMobileView = false;
  private mounted() {
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.showDetailedSection();
    this.showConsolidatedSection();
  }
  private Cancel() {
    this.$root.$emit('reload-program-detail');
    this.$root.$emit('reload-watchlive-detail');
  }
  private showDetailedSection() {
    this.$root.$emit('show-detailed-section', true);
  }
  private showConsolidatedSection() {
    this.$root.$emit('show-consolidated-section', true);
  }
}
