





import { Component, Vue, Prop } from 'vue-property-decorator';
import * as Highcharts from 'highcharts';

@Component
export default class NotificationHighCharts extends Vue {
  @Prop() private seriesData?: string;
  @Prop() private startPoint?: string;

  private More = require('highcharts/highcharts-more');
  private Exporting = require('highcharts/modules/exporting');
  private ExportingData = require('highcharts/modules/export-data');
  private ChartAnnotations = require('highcharts/modules/annotations');
  private startTime = 0;
  private usageData: number[] = [];

  private mounted() {
    this.initializeHighCharts();
    this.startTime = Number(this.startPoint);
    this.usageData = this.seriesData!.split(',').map(Number);
    this.graph();
  }
  private initializeHighCharts() {
    this.More(Highcharts);
    this.Exporting(Highcharts);
    this.ExportingData(Highcharts);
    this.ChartAnnotations(Highcharts);
  }

  private graph() {
    Highcharts.chart({
      chart: {
        renderTo: 'graph',
        type: 'spline',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: 'Time (HH:MM)',
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
        },
        tickInterval: 60000,
      } as Highcharts.XAxisOptions,
      yAxis: {
        title: {
          text: this.$t('VirtualMeetings.HighChart.TitleY').toString(),
        },
        min: 0,
        tickInterval: 1,
      } as Highcharts.YAxisOptions,
      tooltip: {
        enabled: true,
        formatter() {
          return Highcharts.dateFormat('%A, %b %d, %H:%M', (this.key)) + '</br> <span style="font-weight: 600;"> Attendees : </span>'  + this.y;
        },
      },
      plotOptions: {
        series: {
          marker: {
            fillColor: '#FFFFFF',
            lineWidth: 5,
            lineColor: '#7CB5EC',
          },
        },
        line: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen', 'printChart', 'separator',
              'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG',
            ],
          },
        },
        enabled: true,
      },
      navigation: {
        buttonOptions: {
          y: 0,
        },
      },
      series: [
        {
          type: 'area',
          pointInterval: 60000,
          showInLegend: false,
          pointStart: this.startTime,
          data: this.usageData,
        },
      ],
    });
  }

}
