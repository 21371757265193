import { Create } from '../Vod/VodFactoryCreator';
import { VodBaseRenderModel } from '../Vod/VodBaseRenderModel';
import VodModuleTypeEnum from '@/enums/VodModuleTypeEnum';
import VodTypeEnum from '@/enums/VodTypeEnums';
import i18n from '@/i18n'; // its used for translation. i18n.t() same as $t
import { VodDetail } from '@/entities/VodDetail';
import VideoScheduleEnums from '@/enums/VideoScheduleEnums';

export class CustomCategoryRenderer implements Create {
  public GetValues(vodDetail: VodDetail, entityId: number, sessionStartDate: Date, uploadUrl: string, acceptedVideoFileTypes: string, acceptedCaptionFileTypes: string, subModuleType: number): VodBaseRenderModel {
    const CustomCategoryRendererBaseClass: VodBaseRenderModel = new VodBaseRenderModel();
    CustomCategoryRendererBaseClass.Moduletype = VodModuleTypeEnum[1].toString();
    CustomCategoryRendererBaseClass.SubModuleType = subModuleType;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-unused-expressions
    CustomCategoryRendererBaseClass.SubModuleTypeValue = VodTypeEnum[subModuleType].toString(),
    CustomCategoryRendererBaseClass.ShowAddCaption = true;
    CustomCategoryRendererBaseClass.ShowPublish = true;
    const PublishItemList =  [{ Text : i18n.t('VirtualMeetings.AddVideo.PublishNow').toString() , Value : 1 },
      { Text : i18n.t('VirtualMeetings.AddVideo.CustomPublish').toString() , Value : 3 }];
    CustomCategoryRendererBaseClass.PublishItemList = PublishItemList;
    CustomCategoryRendererBaseClass.VodDetail = vodDetail;
    CustomCategoryRendererBaseClass.EntityId = entityId;
    CustomCategoryRendererBaseClass.UploadUrl = uploadUrl;
    CustomCategoryRendererBaseClass.AcceptedVideoFileTypes = acceptedVideoFileTypes;
    CustomCategoryRendererBaseClass.AcceptedCaptionFileTypes = acceptedCaptionFileTypes;
    CustomCategoryRendererBaseClass.PercentageVideoUploaded = 0;
    CustomCategoryRendererBaseClass.SessionStartDate = sessionStartDate;
    CustomCategoryRendererBaseClass.DefaultValue = VideoScheduleEnums.Now;
    CustomCategoryRendererBaseClass.Title = i18n.t('Information').toString();
    return CustomCategoryRendererBaseClass;
  }
}
