







































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { ProgramSessionQuestionsItem } from '@/entities/Program/ProgramSessionQuestionsItem';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { ProgramSessionQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramSessionQuestionsSignalRModel';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { DateTimeFormatOptions } from 'vue-i18n';
@Component({
  components: {
    loading,
    ModalPopup,
  },
})
export default class SessionQuestionsProgramListing extends Vue {
  @Prop() private programItems?: ProgramItem[];
  @Prop() private selectedProgramIds?: number[];
  @Prop() private selectAllItems?: boolean;
  @Prop() private isBulkEdit?: boolean;
  @Prop() private selectedItems?: ProgramSessionQuestionsItem[];
  @Prop() private isGlobalProgramQuestion?: boolean;
  @Prop() private selectedItem?: ProgramSessionQuestionsItem;

  private localprogramsList?: ProgramItem[] = [];
  private selectedProgramItems: ProgramItem[] = [];
  private selectAllProgramItems = false;
  private disableAllProgramItems = false;
  private showLoading = false;
  private loaderBorderColor = '';
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private selectAllRadioButtonOption = false;
  private reloadProgramItems = false;
  private signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(
    this.$root,
    this.$axios,
    this.$store,
    this.$t,
  );
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
  // Date format to show for a selected Date time
  private dateOptions: DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  };
  // Create a new Date format from dateoptions specified
  private dateFormat = new Intl.DateTimeFormat();
  private Cancel() {
    this.$emit('show-bulkOperation', false);
  }
  // Event that fires when the mounted event of the component is fired
  private mounted() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, this.dateOptions);
    this.selectedProgramItems = [];
    if (this.programItems && this.selectedProgramIds) {
      this.programItems.forEach((element) => {
        if (this.selectedProgramIds!.indexOf(element.Id) > -1) {
          this.selectedProgramItems.push(element);
        }
      });
    }
    if (this.isGlobalProgramQuestion) {
      this.selectAllProgramItems = true;
      this.selectAllRadioButtonOption = true;
    } else {
      if (
        this.selectedProgramItems.length === this.programItems!.length &&
        this.selectedProgramItems.length > 0
      ) {
        this.selectAllProgramItems = true;
      }
    }
  }

  // Returns true/false whether to draw date or not
  private drawDate(index: number): boolean {
    if (index === 0) {
      return true;
    } else {
      if (this.programItems) {
        const prevDate = this.dateFormat.format(this.programItems[index - 1].StartDate);
        const currDate = this.dateFormat.format(this.programItems[index].StartDate);
        if (prevDate !== currDate) {
          return true;
        }
      }
    }
    return false;
  }
  // UI formatting of the Date value provided
  private formatDate(pi: ProgramItem): string {
    return this.dateFormat.format(pi.StartDate).toString();
  }
  // UI formatting of the Time Value Provided
  private formatTime(pi: ProgramItem): string {
    return pi.StartDate.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    });
  }
  // Select all program items in the list on click of checkbox/radio button
  private SelectAllProgramItems() {
    this.selectAllProgramItems = true;
    this.disableAllProgramItems = true;
    this.selectAllRadioButtonOption = true;
  }

  // Uncheck all program items in the list
  private DeSelectAllProgramItems() {
    this.selectAllProgramItems = false;
    this.disableAllProgramItems = false;
    this.selectAllRadioButtonOption = false;
  }

  // Assign Program Items to selected Session Questions for Bulk Edit of Session Questions
  private AssignProgramsToSelectedSessionQuestionsConfirmed(
    confirmed: boolean,
  ) {
    this.modalPopupVisible = false;
    if (confirmed) {
      setTimeout(() => {
        this.$root.$emit('show-notification-popup', true);
        this.$root.$emit('show-notification-loading', true);
      }, 100);

      // eslint-disable-next-line max-len
      const localSelectedBulkOperationModel: SignalRModelRequest = this.signalR.BulkAssignSelectedProgramIdsSignalRModelRequest;
      localSelectedBulkOperationModel.Heading = this.$t(
        localSelectedBulkOperationModel.Heading,
      ).toString();
      localSelectedBulkOperationModel.Description = this.$t(
        localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      let programIds: number[] = [];
      const sessionQuestionIds: number[] = [];
      const isGlobalProgramQuestion: boolean = this.selectAllRadioButtonOption;
      if (this.selectedProgramItems) {
        this.selectedProgramItems.forEach((element) => {
          programIds.push(element.Id);
        });
        // fix for IE and Edge remove duplicate program ids
        programIds = programIds.filter((item, pos) => programIds.indexOf(item) === pos);
      }
      this.selectedItems!.forEach((element) => {
        sessionQuestionIds.push(element.id);
      });

      localSelectedBulkOperationModel.SelectedIds = sessionQuestionIds;
      localSelectedBulkOperationModel.ApplicationId = Number(this.reqInfo.ApplicationId);
      localSelectedBulkOperationModel.EventId = Number(this.reqInfo.ApplicationInstanceId);

      // eslint-disable-next-line max-len
      localSelectedBulkOperationModel.JSONData = JSON.stringify({globalProgramQuestion: isGlobalProgramQuestion, selectedProgramIds: programIds});
      this.signalR.PerformSessionQuestionBatchOperation(localSelectedBulkOperationModel);
      this.$root.$emit('program-sessionquestion-tab-select-operation');
    }
  }
  // Show confirmation message for Assigning Program Items to selected Session Questions
  private AssignProgramsToSelectedSessionQuestions() {
    this.modalPopupVisible = true;
    this.showCloseButton = true;
    this.modalPopupContent = this.$t(
      'SessionQuestions.BulkEditSessionQuestions',
    ).toString();
  }
  // Pass selected Program Items to parent Level
  private EmitSelectedProgramItems() {
    this.$root.$emit(
      'program-sessionquestions-batch-item-edit-operation',
      this.selectedItems,
    );
  }

  // Select Program Items that are to be linked with Session Questions
  private checkProgramItems(val: ProgramItem, index: number, event: any) {
    this.reloadProgramItems = true;
    const programItemChecked = event.target.checked;
    if (programItemChecked) {
      this.selectedProgramItems.push(val);
    } else {
      const objIndex = this.selectedProgramItems.findIndex(
        (obj: ProgramItem) => obj.Id === val.Id,
      );
      if (objIndex > -1) {
        this.selectedProgramItems.splice(objIndex, 1);
      }
    }
    this.$emit(
      'program-sessionQuestion-SetSelectedProgramItems',
      this.selectedProgramItems,
    );
  }
  // Event fires when SelectedProgramIds changes
  @Watch('selectedProgramIds')
  private onPropertyChange(val: number[]) {
    this.selectedProgramItems = [];
    if (this.selectAllRadioButtonOption) {
      this.programItems!.forEach((element) => {
        this.selectedProgramItems.push(element);
      });
      this.selectAllProgramItems = true;
    } else if (this.programItems) {
      this.programItems.forEach((element) => {
        if (val.indexOf(element.Id) > -1) {
          this.selectedProgramItems.push(element);
        }
      });

      if (
        this.selectedProgramItems.length === this.programItems.length &&
        this.selectedProgramItems.length > 0
      ) {
        this.selectAllProgramItems = true;
      } else {
        this.selectAllProgramItems = false;
      }
    }
  }

  @Watch('selectedItem')
  private onPropertyselectedItemsChange(
  ) {
    // Do not clear selected program items if true
    this.reloadProgramItems = true;
  }

  // Evnet fires when Select All option is checked/unchecked
  @Watch('selectAllProgramItems')
  private onPropertySelectAllItemsChange(val: boolean) {
    if (this.programItems) {
      if (val === true) {
        this.selectedProgramItems = [];
        this.programItems.forEach((item) => {
          this.selectedProgramItems.push(item);
        });
        this.reloadProgramItems = false;
      } else {
        if (this.reloadProgramItems === true) {
          this.reloadProgramItems = false;
          this.selectedProgramItems = [];
          this.programItems.forEach((element) => {
            if (this.selectedProgramIds!.indexOf(element.Id) > -1) {
              this.selectedProgramItems.push(element);
            }
          });
        } else {
          this.selectedProgramItems = [];
        }
      }
      this.$emit(
        'program-sessionQuestion-SetSelectedProgramItems',
        this.selectedProgramItems,
      );
    }
  }
  @Watch('isGlobalProgramQuestion')
  private onIsGlobalProgramQuestionOptionChange(val: boolean) {
    this.selectAllRadioButtonOption = val;

  }
  @Watch('selectAllRadioButtonOption')
  private onselectAllRadioButtonOptionChange(val: boolean) {
    if (!val) {
      this.selectAllProgramItems = false;
    }
    this.disableAllProgramItems = val;
    this.$emit('program-sessionquestion-item-global-program-question', val);
  }
}
