






















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import ModuleEnum from '@/enums/ModuleEnum';
@Component({
  components: {
    ValidationSummary,
  },
})
export default class AddInformationWithEntity extends Vue {
  @Prop() private allInformations?: CustomCategoryItem[];
  @Prop() private selectedEntityId?: number;
  @Prop() private customCategoryFolders?: CustomCategoryFolder[];
  @Prop() private isBulkOperation?: boolean;
  @Prop() private selectedItems?: number[];
  @Prop() private selectedBulkOperationModel?: SignalRModelRequest;
  @Prop() private EntityType?: number;

  private localAllInformations?: CustomCategoryItem[] = [];
  private localcustomCategoryFolders?: CustomCategoryFolder[] = [];
  private filteredInformations?: CustomCategoryItem[] = [];
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private overwriteLinkedInformation = false;
  private isMobileView = false;
  private information = 'Information';
  private isLinkedItem = false;
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private signalR: ProgramSignalRModel = new ProgramSignalRModel(
    this.$root,
    this.$axios,
    this.$store,
    this.$t,
  );
  // function to move scroll to the top
  private scrollToTop() {
    setTimeout(() => {
      const el = document.getElementById('dvAddInformation');
      if (el) {
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start',
        });
      }
    }, 200);
  }
  // Mounted lifecycle event of Component
  private mounted() {
    // Set listing object for Information
    if (this.allInformations != null) {
      this.localAllInformations = this.allInformations;
      this.filteredInformations = this.allInformations;
      const linkedItem  = this.filteredInformations.find((a)=> a.IsLinked === true);
      if (this.EntityType === ModuleEnum.Dialogue &&  linkedItem !== null && linkedItem !== undefined && linkedItem.CustomCategoryId > 0) {
        this.isLinkedItem = true;
      }
    }
    // Set Folders dropdown
    if (this.customCategoryFolders != null) {
      this.localcustomCategoryFolders = this.customCategoryFolders;
      // remove duplicate folder names
      this.localcustomCategoryFolders = this.localcustomCategoryFolders.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.Name === thing.Name),
      );
      this.GetAllInformations();
    }
    this.scrollToTop();
    this.localSelectedBulkOperationModel = this.selectedBulkOperationModel!;
    this.visibleValidationSummary = false;
    if (this.isBulkOperation) {
      this.UncheckAllInformations();
    }

    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    if (this.EntityType === ModuleEnum.Dialogue) {
      this.$root.$emit('notification-hide-edit-item-buttons', false);
    }
  }

  // Filter Information Based on Folder
  private onFolderChange(selectedFolderName: string) {
    if (selectedFolderName !== undefined && this.localAllInformations != null) {
      if (selectedFolderName === 'All') {
        this.GetAllInformations();
      } else if (selectedFolderName === '') {
        this.GetTopLevelInformations();
      } else {
        this.GetSelectedFolderInformation(selectedFolderName);
      }
    }
  }
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  // Get information in top Level of folder
  private GetTopLevelInformations() {
    const infoItems: CustomCategoryItem[] = [];
    this.localAllInformations!.forEach(function(value) {
      if (value.FolderName == null) {
        infoItems.push(value);
      }
    });
    this.filteredInformations = Object.assign({}, infoItems);
  }
  // Get information in "all" option of folder
  private GetAllInformations() {
    this.filteredInformations = Object.assign({},  this.localAllInformations);
  }
  // Get information in selected folder
  private GetSelectedFolderInformation(selectedFolderName: string) {
    const infoItems: CustomCategoryItem[] = [];
    this.localAllInformations!.forEach(function(value) {
      if (value.FolderName === selectedFolderName) {
        infoItems.push(value);
      }
    });
    this.filteredInformations = Object.assign({}, infoItems);
  }
  // Close Add Information Section
  private clickOnBackProgramEdit() {
    this.$emit('selectBack', {
      showAddInformation: false,
      informations: this.localAllInformations,
    });
  }

  private addItem(item: CustomCategoryItem) {
    if (item !== null && item !== undefined) {
      if (this.EntityType! === ModuleEnum.Program) {
        this.addItemToProgram(item);
      } else {
        this.SaveItemToDialogue(item);
      }
    }
  }

  // Add a new Linked Information to program Item
  private addItemToProgram(item: CustomCategoryItem) {
    if (!this.isBulkOperation) {
      const objIndex = this.localAllInformations!.findIndex(
        (obj: CustomCategoryItem) =>
          obj.CustomCategoryId === item.CustomCategoryId,
      );

      if (this.selectedEntityId! > 0 && item.CustomCategoryId > 0) {
        item.IsLinked = true;
        this.$axios
          .post('/Program/AddLinkedCategory', {
            programId: this.selectedEntityId,
            customCategoryId: item.CustomCategoryId,
          })
          .then((response) => {
            if (response.data.success === false) {
              alert(response.data.message);
              item.IsLinked = false;
            } else {
              Vue.set(this.localAllInformations!, objIndex, item); // Update array w reactivity
              // need information send to signalr
              const customCategoryItem = {
                dataItem: this.localAllInformations,
                programDetailType: 'LinkedInformation',
              };
              this.$emit('linked-category-add-program-notification', customCategoryItem);
            }
          })
          .catch(() => {
            // Log error if required
          });
      }
    } else {
      item.IsLinked = true;
    }

  }

  // Add a new Linked Information to dialogue Item
  private addItemToDialogue(item: CustomCategoryItem) {
    if (!this.isBulkOperation) {
      const objIndex = this.localAllInformations!.findIndex(
        (obj: CustomCategoryItem) =>
          obj.CustomCategoryId === item.CustomCategoryId,
      );

      if (this.selectedEntityId! > 0 && item.CustomCategoryId > 0) {
        item.IsLinked = true;
        this.$axios
          .post('/Notification/SaveOrUpdateLinkedEntityWithDialogue', {
            notificationId: this.selectedEntityId,
            entityId: item.CustomCategoryId,
            entityType: this.information,
          })
          .then((response) => {
            if (response.data.success === false) {
              alert(response.data.message);
              item.IsLinked = false;
            } else {
              Vue.set(this.localAllInformations!, objIndex, item); // Update array w reactivity
              // need information send to signalr
              const customCategoryItem = {
                dataItem: this.localAllInformations,
                programDetailType: 'LinkedInformation',
              };
              this.$emit('linked-category-add-program-notification', customCategoryItem);
              this.isLinkedItem = true;
            }
          })
          .catch(() => {
            // Log error if required
          });
      }
    } else {
      item.IsLinked = true;
    }

  }

  private SaveItemToDialogue(item: CustomCategoryItem) {
    item.IsLinked = true;
    this.isLinkedItem = true;
  }

  private CancelItemToDialogue(item: CustomCategoryItem) {
    item.IsLinked = false;
    this.isLinkedItem = false;
  }

  private UncheckAllInformations() {
    this.localAllInformations!.forEach((element) => {
      element.IsLinked = false;
    });
  }

  private removeItem(item: CustomCategoryItem) {
    if (item !== null && item !== undefined) {
      if (this.EntityType! === ModuleEnum.Program) {
        this.removeItemToProgram(item);
      } else {
        this.CancelItemToDialogue(item);
      }
    }
  }

  // Remove a Linked Information from  program Item
  private removeItemToProgram(item: CustomCategoryItem) {
    if (!this.isBulkOperation) {
      const objIndex = this.localAllInformations!.findIndex(
        (obj: CustomCategoryItem) =>
          obj.CustomCategoryId === item.CustomCategoryId,
      );

      if (this.selectedEntityId! > 0 && item.CustomCategoryId > 0) {
        item.IsLinked = false;
        this.$axios
          .post('/Program/RemoveLinkedCategory', {
            programId: this.selectedEntityId,
            customCategoryId: item.CustomCategoryId,
          })
          .then((response) => {
            if (response.data.success === false) {
              alert(response.data.message);
              item.IsLinked = true;
            } else {
              Vue.set(this.localAllInformations!, objIndex, item); // Update array w reactivity
              // need information send to signalr
              const customCategoryItem = {
                dataItem: this.localAllInformations,
                programDetailType: 'LinkedInformation',
              };
              this.$emit('linked-category-add-program-notification', customCategoryItem);
            }
          })
          .catch(() => {
            // Log error if required
          });
      }
    } else {
      item.IsLinked = false;
    }
  }

  // Remove a Linked Information from  program Item
  private removeItemToDialogue(item: CustomCategoryItem) {
    if (!this.isBulkOperation) {
      const objIndex = this.localAllInformations!.findIndex(
        (obj: CustomCategoryItem) =>
          obj.CustomCategoryId === item.CustomCategoryId,
      );

      if (this.selectedEntityId! > 0 && item.CustomCategoryId > 0) {
        item.IsLinked = false;
        this.$axios
          .post('/Notification/SaveOrUpdateLinkedEntityWithDialogue', {
            notificationId: this.selectedEntityId,
            entityId: 0,
            entityType: this.information,
          })
          .then((response) => {
            if (response.data.success === false) {
              alert(response.data.message);
              item.IsLinked = true;
            } else {
              Vue.set(this.localAllInformations!, objIndex, item); // Update array w reactivity
              // need information send to signalr
              const customCategoryItem = {
                dataItem: this.localAllInformations,
                programDetailType: 'LinkedInformation',
              };
              this.$emit('linked-category-add-program-notification', customCategoryItem);
              this.isLinkedItem = false;
            }
          })
          .catch(() => {
            // Log error if required
          });
      }
    } else {
      item.IsLinked = false;
    }
  }

  private SaveLinkedInformation() {
    const objIndex = this.localAllInformations!.findIndex(
      (obj: CustomCategoryItem) => obj.IsLinked === true,
    );
    if (objIndex > -1) {
      const infoItems: number[] = [];
      this.localAllInformations!.forEach(function(value) {
        if (value.IsLinked === true) {
          infoItems.push(value.CustomCategoryId);
        }
      });
      const programIds: number[] = this.selectedItems!;  // this.signalR.GetselectedEntityIdsToSendInJson(this.selectedItems!);

      this.localSelectedBulkOperationModel.JSONData = JSON.stringify({overwriteLinkedInformation: this.overwriteLinkedInformation, LinkedinfoItems: infoItems});
      this.localSelectedBulkOperationModel.SelectedIds = programIds;
      setTimeout(() => {
        this.$root.$emit('show-notification-popup', true);
        this.$root.$emit('show-notification-loading', true);
      }, 100);
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(this.localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      this.signalR.PerformProgramBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      this.$root.$emit('program-uncheck-select-all');
      this.$root.$emit('empty-track-tag');
    } else {
      this.validationErrorFields = [];
      this.validationErrorFields.push(this.$t('Tracks.SelectInformation').toString());
      this.visibleValidationSummary = true;
    }
  }

  // Get imgUrl for Information item
  private GetImageUrl(imgurl: string) {
    return imgurl === null ? this.missingImage : imgurl;
  }
  private isDisableBtn() {
    if (this.EntityType === ModuleEnum.Dialogue && this.isLinkedItem) {
      return true;
    } else {
      return false;
    }
  }
}
