



































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { ProgramEvaluationQuestionsItem } from '@/entities/Program/ProgramEvaluationQuestionsItem';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';

import { ProgramEvaluationQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramEvaluationQuestionsSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { DateTimeFormatOptions } from 'vue-i18n';
@Component({
  components: {
    loading,
    ModalPopup,
  },
})
export default class EvaluationQuestionListing extends Vue {
  @Prop() private programItems?: ProgramItem[];
  @Prop() private selectedProgramIds?: number[];
  @Prop() private selectAllItems?: boolean;
  @Prop() private isBulkOperation?: boolean;
  @Prop() private selectedItems?: ProgramEvaluationQuestionsItem[];
  @Prop() private isGlobalProgramQuestion?: boolean;
  @Prop() private selectedItem?: ProgramEvaluationQuestionsItem;

  private selectAllProgramItemsCheckbox = false;
  private selectedPrograms: ProgramItem[] = [];
  private disableAllProgramItems = false;
  private showLoading = false;
  private loaderBorderColor = '';
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private selectAllRadioButtonOption = false;
  private localSelectedProgramIds?: number[];
  private localEvaluationQuestion?: ProgramEvaluationQuestionsItem = ProgramEvaluationQuestionsItem.createEmpty();
  private signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
    this.$root,
    this.$axios,
    this.$store,
    this.$t,
  );
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
  // Date format to show for a selected Date time
  private dateOptions: DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  };
  // Create a new Date format from dateoptions specified
  private dateFormat = new Intl.DateTimeFormat();
  private Cancel() {
    this.$emit('show-bulkOperation', false);
  }
  // Event that fires when the mounted event of the component is fired
  private mounted() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, this.dateOptions);
    this.localSelectedProgramIds = Object.assign({}, this.selectedProgramIds);
    this.selectedPrograms = [];
    if (this.programItems && this.selectedProgramIds) {
      this.programItems.forEach((element) => {
        if (this.selectedProgramIds!.indexOf(element.Id) > -1) {
          this.selectedPrograms.push(element);
        }
      });
    }
    if (this.isGlobalProgramQuestion) {
      this.selectAllProgramItemsCheckbox = true;
      this.selectAllRadioButtonOption = true;
    } else {
      if (
        this.selectedPrograms.length === this.programItems!.length &&
        this.selectedPrograms.length > 0
      ) {
        this.selectAllProgramItemsCheckbox = true;
      }
    }
  }

  // Returns true/false whether to draw date or not
  private drawDate(index: number): boolean {
    if (index === 0) {
      return true;
    } else {
      if (this.programItems) {
        const prevDate = this.dateFormat.format(this.programItems[index - 1].StartDate);
        const currDate = this.dateFormat.format(this.programItems[index].StartDate);
        if (prevDate !== currDate) {
          return true;
        }
      }
    }
    return false;
  }
  // UI formatting of the Date value provided
  private formatDate(pi: ProgramItem): string {
    return this.dateFormat.format(pi.StartDate).toString();
  }
  // UI formatting of the Time Value Provided
  private formatTime(pi: ProgramItem): string {
    return pi.StartDate.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    });
  }
  // Select Program Items that are to be linked with Evaluation Questions
  private checkProgramItems(val: ProgramItem, index: number, event: any) {
    const programItemChecked = event.target.checked;
    if (programItemChecked) {
      this.selectedPrograms.push(val);
    } else {
      const objIndex = this.selectedPrograms.findIndex(
        (obj: ProgramItem) => obj.Id === val.Id,
      );
      if (objIndex > -1) {
        this.selectedPrograms.splice(objIndex, 1);
      }
    }
    this.SetSelectedProgramItems(this.selectedPrograms);
  }

  // Set selected program Items
  private SetSelectedProgramItems(selectedProgramItems: ProgramItem[]) {
    this.localSelectedProgramIds = [];
    // this.selectedProgramNames="";
    selectedProgramItems.forEach((element) => {
      if (this.localSelectedProgramIds!.indexOf(element.Id) === -1) {
        this.localSelectedProgramIds!.push(element.Id);
      }
    });
    this.localEvaluationQuestion!.SelectedProgramIds = this.localSelectedProgramIds.toString();
    this.$emit(
      'program-SetSelectedProgramItems',
      selectedProgramItems,
    );
  }

  // Show confirmation message for Assigning Program Items to selected Session Questions
  private AssignProgramsToSelectedEvaluationQuestions() {
    this.modalPopupVisible = true;
    this.modalPopupContent = this.$t(
      'EvaluationQuestions.BulkEditEvaluationQuestions',
    ).toString();
  }
  // Assign Program Items to selected Session Questions for Bulk Edit of Session Questions
  private AssignProgramsToSelectedEvaluationQuestionsConfirmed(
    confirmed: boolean,
  ) {
    this.modalPopupVisible = false;
    if (confirmed) {
      setTimeout(() => {
        this.$root.$emit('show-notification-popup', true);
        this.$root.$emit('show-notification-loading', true);
      }, 100);

      const localSelectedBulkOperationModel: SignalRModelRequest = this.signalR.BulkAssignSelectedProgramIdsSignalRModelRequest;
      localSelectedBulkOperationModel.Heading = this.$t(
        localSelectedBulkOperationModel.Heading,
      ).toString();
      localSelectedBulkOperationModel.Description = this.$t(
        localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      const programIds: number[] = [];
      const sessionQuestionIds: number[] = [];
      const isGlobalEvaluationQuestion: boolean = this.selectAllRadioButtonOption;
      this.selectedPrograms.forEach((element) => {
        // prevent duplicate value insertion -- issue in IE
        if (programIds.indexOf(element.Id) === -1) {
          programIds.push(element.Id);
        }
      });
      this.selectedItems!.forEach((element) => {
        sessionQuestionIds.push(element.Id);
      });

      localSelectedBulkOperationModel.SelectedIds = sessionQuestionIds;
      localSelectedBulkOperationModel.ApplicationId = Number(this.reqInfo.ApplicationId);
      localSelectedBulkOperationModel.EventId = Number(this.reqInfo.ApplicationInstanceId);

      localSelectedBulkOperationModel.JSONData = JSON.stringify({globalEvaluationQuestion: isGlobalEvaluationQuestion, selectedProgramIds: programIds});
      this.signalR.PerformEvaluationQuestionBatchOperation(localSelectedBulkOperationModel);
    }
  }

  // Event fires when SelectedProgramIds changes
  @Watch('selectedProgramIds')
  private onPropertyChange(val: number[]) {
    this.selectedPrograms = [];
    if (this.programItems) {
      this.programItems.forEach((element) => {
        if (val.indexOf(element.Id) > -1) {
          this.selectedPrograms.push(element);
        }
      });

      if (
        this.selectedPrograms.length === this.programItems.length &&
        this.selectedPrograms.length > 0
      ) {
        this.selectAllProgramItemsCheckbox = true;
      } else {
        this.selectAllProgramItemsCheckbox = false;
      }
    }
  }

  // Event fires when Select All option is checked/unchecked
  @Watch('selectAllProgramItemsCheckbox')
  private onPropertySelectAllItemsChange(val: boolean, oldVal: boolean) {
    if (this.programItems) {
      if (val === true) {
        this.selectedPrograms = [];
        this.programItems.forEach((item) => {
          this.selectedPrograms.push(item);
        });
      } else {
        if (oldVal && this.selectedPrograms.length === this.programItems.length) {
          this.selectedPrograms = [];
        }
      }
    }
    this.SetSelectedProgramItems(this.selectedPrograms);
  }
  @Watch('isGlobalProgramQuestion')
  private onIsGlobalProgramQuestionOptionChange(val: boolean) {
    this.selectAllRadioButtonOption = val;
  }
}
