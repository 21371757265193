




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {EvaluationBulkOperations} from '@/entities/BulkOperations/Program/EvaluationBulkOperations';
import { BaseBulkOperationsModel } from '@/entities/BulkOperations/BaseBulkOperationsModel';
import EvaluationQuestionListing from '@/components/Program/EvaluationQuestions/EvalutationQuestionListing.vue';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { ProgramEvaluationQuestionsItem } from '@/entities/Program/ProgramEvaluationQuestionsItem';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import ModalPopup from '@/components/Common/ModalPopup.vue';

import { ProgramEvaluationQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramEvaluationQuestionsSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { BulkOperations } from '@/entities/BulkOperations/BulkOperations';
@Component({components: {
  EvaluationQuestionListing,
  ModalPopup,
}})
export default class EvaluationQuestionBatchPane extends Vue {
  @Prop() private globalProgramEvaluation?: boolean;
  @Prop() private selectedItems?: ProgramEvaluationQuestionsItem[];
  @Prop() private programItems?: ProgramItem[];
  private localGlobalProgramEvaluation = false;
  private bulkEditOperations: BaseBulkOperationsModel = new EvaluationBulkOperations(
    this.$root,
    this.$axios,
    this.$store,
    this.$t,
  );
  private localBulkOperations: BulkOperations[] = [];
  private selectedProgramIds: number[] = [];
  private showEvaluationQuestionListing = false;
  private modalPopupContent = '';
  private modalBodyContent = '';
  private modalPopupVisible = false;
  private showCloseButton = true;
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
    this.$root,
    this.$axios,
    this.$store,
    this.$t,
  );
  private ShowBulkOperation(visibleBulkOperation: boolean) {
    this.showEvaluationQuestionListing = visibleBulkOperation;
  }
  private evenIndex(index: number): boolean {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }
  // Handle calling of Functions from Click Events in the List
  private handle_function_call(
    objModel: SignalRModelRequest,
    confirmationMessage: string, subtitleConfirmationMessage: string, showConfirmationPopup: boolean,
  ) {
    if (showConfirmationPopup) {
      this.modalPopupVisible = true;
      this.modalPopupContent = this.$t(confirmationMessage).toString();
      this.modalBodyContent = this.$t(subtitleConfirmationMessage).toString();
      this.localSelectedBulkOperationModel = Object.assign({}, objModel);
    } else {
      this.AddToProgramScreen();
    }
  }
  // Mounted event of Batch edit pane
  private mounted() {
    this.localBulkOperations = Object.assign({}, this.bulkEditOperations.arrBulkOperations);
    this.localGlobalProgramEvaluation = this.globalProgramEvaluation!;
    this.$root.$on('global-program-evaluation', (globalEvaluation: boolean) => {
      this.localGlobalProgramEvaluation = globalEvaluation;
    });
  }
  private AddToProgramScreen() {
    this.showEvaluationQuestionListing = true;
  }
  @Watch('localGlobalProgramEvaluation')
  private OnPropertyChange(val: boolean) {
    if (val) {
      this.localBulkOperations = this.bulkEditOperations.arrBulkOperations.filter((value) => value.Name !== 'Add to Program',
      );
    } else {
      this.localBulkOperations = Object.assign(
        {},
        this.bulkEditOperations.arrBulkOperations,
      );
    }
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      setTimeout(() => {
        this.$root.$emit('show-notification-popup', true);
        this.$root.$emit('show-notification-loading', true);
      }, 100);
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,

          user: this.signalR.GetUserName(this.localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      const storeHelper: StoreHelper = new StoreHelper(this.$store);
      const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
      this.localSelectedBulkOperationModel.ApplicationId = Number(reqInfo.ApplicationId);
      this.localSelectedBulkOperationModel.EventId = Number(reqInfo.ApplicationInstanceId);

      const evaluationQuestionIds: number[] = [];
      this.selectedItems!.forEach((element) => {
        evaluationQuestionIds.push(element.Id);
      });

      this.localSelectedBulkOperationModel.SelectedIds = evaluationQuestionIds;
      this.signalR.PerformEvaluationQuestionBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      this.$root.$emit('program-evaluationquestion-tab-select-operation');
    } else {
      return;
    }
  }

}
