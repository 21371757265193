













import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class EventWebPageVisibiltity extends Vue {
  @Prop() private IsCheckboxVisible!: boolean;
  @Prop() private ModuleName!: string;
  @Prop() private IsCheckboxChecked!: boolean;
  private isCheckboxChecked = false;
  private mounted() {
    this.isCheckboxChecked = this.IsCheckboxChecked;
  }
  private GetCheckboxLabelText() {
    return this.$t('DoNotDisplayItemOnEventWebpage',{item: this.ModuleName});
  }
  private SetCheckboxValue() {
    this.isCheckboxChecked = (this.$refs.chkEventVisibility as HTMLInputElement).checked;
    this.$emit('set-checkbox-value', this.isCheckboxChecked);
  }
  @Watch('IsCheckboxChecked')
  private CheckChangeInCheckboxValue(val: boolean) {
    this.isCheckboxChecked = val;
  }
}
