































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProgramTagItem } from '@/entities/Program/ProgramTagItem';
import ProgramTagItemEditPane from '@/components/Program/ProgramDetail/Tags/ProgramTagItemEditPane.vue';
import ProgramTagListViewEditItemPane from '@/components/Program/ProgramDetail/Tags/ProgramTagListViewEditItemPane.vue';
@Component({
  components: {
    ProgramTagItemEditPane,
    ProgramTagListViewEditItemPane,
  },
})
export default class ProgramTagListView extends Vue {
  public loaderBorderColor = '';
  @Prop() private tagItems?: ProgramTagItem[];
  @Prop() private applicationborderColor?: string;
  private localTagItem: ProgramTagItem | null = null;
  private ClosePreviouslyOpenTagsForEdit() {
    this.$root.$emit('program-tag-close-others-operation');
  }
  private AddTag() {
    this.ClosePreviouslyOpenTagsForEdit();
    const newItem = ProgramTagItem.createEmpty();
    this.localTagItem = newItem;
    this.RedirectToItem(this.tagItems!.length);
  }
  private CloseTag() {
    this.localTagItem = null;
    this.$emit('program-tag-close-operation', true);
    this.$root.$emit('clearCheckboxes', []);
  }
  private programTagItemCancelOperation() {
    this.localTagItem = null;
  }
  private mounted() {
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.$root.$on('program-tag-item-edit-operation', (pt: ProgramTagItem) => {
      this.programTagItemCancelOperation();
    });
    this.$root.$on('program-tag-close-others-operation', () => {
      this.localTagItem = null;
    });
  }
  private RedirectToItem(index: number) {
    setTimeout(() => {
      const element = document.querySelectorAll('[indexTag="' + index + '"]')[0];
      if (element) {
        element.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
          inline: 'end',
        });
      }
    }, 100);
  }
}
