/* eslint-disable max-len */
import { BaseSignalRModel } from '../BaseSignalRModel';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import EmitEnum from '@/enums/EmitEnums';
import { SignalRModelRequest } from '../SignalRModelRequest';
import { Vue } from 'vue-property-decorator';
import { AxiosInstance } from 'axios';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { ProgramEvaluationQuestionsItem, ProgramEvaluationQuestionsItemSignalR } from '@/entities/Program/ProgramEvaluationQuestionsItem';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';

const ProgramEvaluationQuestionsSignalRModelRequestIds = {
  BulkDelete: 1,
  BulkAssignSelectedProgramIds: 2,
  BulkMakeAnonymous: 3,
  BulkMakeNotAnonymous: 4,
  SingleCreate: 5,
  SingleEdit: 6,
  SingleDelete: 7,
  SingleToggleGlobalEvaluationQuestion: 8,
};


export class ProgramEvaluationQuestionsSignalRModel extends BaseSignalRModel {
  // Get SignalRModelRequest for deleting seleted Evaluation questions in a batch
  get DeleteSelectedEvaluationQuestionsSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramEvaluationQuestionsSignalRModelRequestIds.BulkDelete);
  }
  // Get SignalRModelRequest for updating selected program ids with session questions
  get BulkAssignSelectedProgramIdsSignalRModelRequest(): SignalRModelRequest {

    return this.GetSelectedSignalRModelRequestByID(ProgramEvaluationQuestionsSignalRModelRequestIds.BulkAssignSelectedProgramIds);
  }
  // Get SignalRModelRequest for making anonymous selected Evaluation questions
  get BulkMakeAnonymousSignalRModelRequest(): SignalRModelRequest {

    const signalObject: SignalRModelRequest = this.GetSelectedSignalRModelRequestByID(ProgramEvaluationQuestionsSignalRModelRequestIds.BulkMakeAnonymous);
    signalObject.JSONData = JSON.stringify({isAnonymous: true});
    return signalObject;
  }
  // Get SignalRModelRequest for making anonymous selected Evaluation questions
  get BulkMakeNotAnonymousSignalRModelRequest(): SignalRModelRequest {

    const signalObject: SignalRModelRequest = this.GetSelectedSignalRModelRequestByID(ProgramEvaluationQuestionsSignalRModelRequestIds.BulkMakeNotAnonymous);
    signalObject.JSONData = JSON.stringify({isAnonymous: false});
    return signalObject;
  }
  // Get SignalRModelRequest for adding a single program  Evaluation question
  get SingleCreateEvaluationQuestionSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramEvaluationQuestionsSignalRModelRequestIds.SingleCreate);
  }
  // Get SignalRModelRequest for Updating a single Evaluation question
  get SingleUpdateEvaluationQuestionSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramEvaluationQuestionsSignalRModelRequestIds.SingleEdit);
  }
  // Get SignalRModelRequest for Deleting a single program Evaluation question
  get SingleDeleteEvaluationQuestionSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramEvaluationQuestionsSignalRModelRequestIds.SingleDelete);
  }
  get SingleToggleGlobalEvaluationQuestion(): SignalRModelRequest {

    return this.GetSelectedSignalRModelRequestByID(ProgramEvaluationQuestionsSignalRModelRequestIds.SingleToggleGlobalEvaluationQuestion);
  }
  readonly EvaluationQuestionsBatchOperationURL = '/BulkOperations/PerformBatchOperation';
  readonly EvaluationQuestionsPostToClientsSignalRURL = '/BulkOperations/PostToClientsSignalR';

  constructor(public rootObj: any, public axios: AxiosInstance, public $store: any, public $t: any) {
    super(rootObj, axios, $store);
    let reqInfo: AppRequestInfo = this.GetRequestInfoFromStore();
    if (reqInfo.ApplicationId === 0) {
      reqInfo = this.GetRequestInfoFromStore();
    }
    const loggedInUserFullName = reqInfo.FirstName + ' ' + reqInfo.LastName;
    const signalRAPIProgramURL = Vue.prototype.$SignalRBaseURL + 'api/SignalRProgram/PushProgramSignalRChanges';

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramEvaluationQuestionsSignalRModelRequestIds.BulkDelete, ModuleEnum.ProgramEvaluationQuestions, SubModuleEnum.Delete, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramEvaluationQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteProgramEvaluationQuestions', 'Notifications.DeletedProgramEvaluationQuestions', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionEvaluationQuestionsDeleted'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramEvaluationQuestionsSignalRModelRequestIds.BulkAssignSelectedProgramIds, ModuleEnum.ProgramEvaluationQuestions, SubModuleEnum.EditSelectedProgramIds,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramEvaluationQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.EditOperation', '', 'Notifications.AssigningProgramEvaluationQuestionItemSelectedProgramIds', 'Notifications.AssignedProgramEvaluationQuestionItemSelectedProgramIds', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionEvaluationQuestionAssignSelectedProgramIds'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramEvaluationQuestionsSignalRModelRequestIds.BulkMakeAnonymous, ModuleEnum.ProgramEvaluationQuestions, SubModuleEnum.MakeAnonymous,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramEvaluationQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.EditOperation', '', 'Notifications.EvaluationQuestionsMakeAnonymous', 'Notifications.EvaluationQuestionsMakeAnonymousSaved', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionEvaluationQuestionToggleIsAnonymous'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramEvaluationQuestionsSignalRModelRequestIds.BulkMakeNotAnonymous, ModuleEnum.ProgramEvaluationQuestions, SubModuleEnum.MakeNotAnonymous,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramEvaluationQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.EditOperation', '', 'Notifications.EvaluationQuestionsMakeNotAnonymous', 'Notifications.EvaluationQuestionsMakeNotAnonymousSaved', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionEvaluationQuestionToggleIsAnonymous'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramEvaluationQuestionsSignalRModelRequestIds.SingleCreate, ModuleEnum.ProgramEvaluationQuestions, SubModuleEnum.SingleCreate,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramEvaluationQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.SaveOperation', '', 'Notifications.SavingProgramEvaluationQuestionItem', 'Notifications.SavedProgramEvaluationQuestionItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleEvaluationQuestion'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramEvaluationQuestionsSignalRModelRequestIds.SingleEdit, ModuleEnum.ProgramEvaluationQuestions, SubModuleEnum.SingleEdit, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramEvaluationQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingProgramEvaluationQuestionItem', 'Notifications.UpdatedProgramEvaluationQuestionItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleEvaluationQuestion'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramEvaluationQuestionsSignalRModelRequestIds.SingleDelete, ModuleEnum.ProgramEvaluationQuestions, SubModuleEnum.SingleDelete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramEvaluationQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleEvaluationQuestionItem', 'Notifications.DeletedSingleEvaluationQuestionItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleEvaluationQuestion'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramEvaluationQuestionsSignalRModelRequestIds.SingleToggleGlobalEvaluationQuestion, ModuleEnum.ProgramEvaluationQuestions, SubModuleEnum.EditGlobalEvaluationQuestion,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramEvaluationQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.UpdateOperation', '', 'Notifications.ApplyEvaluationtoEntireProgram', 'Notifications.AppliedEvaluationtoEntireProgram', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleEvaluationQuestion'));
  }


  // Perform Evaluation Questions Batch Operations
  public PerformEvaluationQuestionBatchOperation(signalRModelRequest: SignalRModelRequest) {
    // disable Evaluation Questions
    if (signalRModelRequest.SelectedIds.length > 0) {
      this.DisableEvaluationQuestions(signalRModelRequest);
    }
    this.SendSignalRModelRequestToHub(signalRModelRequest, this.EvaluationQuestionsBatchOperationURL);
    this.rootObj.$emit('program-evaluationquestion-tab-select-operation');
  }

  // Perform Evaluation Questions Single Operations
  public PerformEvaluationSingleOperation(signalRModelRequest: SignalRModelRequest) {
    // disable session Questions
    if (signalRModelRequest.SelectedIds.length > 0) {

      if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate && signalRModelRequest.SubModule !== SubModuleEnum.EditGlobalEvaluationQuestion ) {
        this.DisableEvaluationQuestions(signalRModelRequest);
      }
    }


    this.SendSignalRModelRequestToHubWithoutAddingToQueue(signalRModelRequest, this.EvaluationQuestionsPostToClientsSignalRURL);


    this.SendSignalRModelRequestToQueueWithoutEmit(signalRModelRequest, this.EvaluationQuestionsBatchOperationURL);

  }

  // Method that fires when Program SignalR response model is received from Hub
  public ProgramSignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    this.SignalRModelResponseReceived(signalRModelRequest);
    // enable Evaluation Questions

    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate && signalRModelRequest.SubModule !== SubModuleEnum.SingleDelete) {
      this.EnableEvaluationQuestions(signalRModelRequest);
    }
  }

  // Disable Evaluation Questions and add to Notification Popup that an operation has started
  public DisableEvaluationQuestionsAndAddToNotificationPopup(signalRModelRequest: SignalRModelRequest) {

    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate && signalRModelRequest.SubModule !== SubModuleEnum.EditGlobalEvaluationQuestion) {
      this.DisableEvaluationQuestions(signalRModelRequest);
    }
    this.EmitAddNewNotifications(signalRModelRequest);
  }

  // Get Evaluation Question Item to send to SignalR as JSON data
  public GetEvaluationQuestionToSendToSignalR(evaluationQuestion: ProgramEvaluationQuestionsItem): string {

    const item: ProgramEvaluationQuestionsItemSignalR = ProgramEvaluationQuestionsItemSignalR.GetProgramEvaluationQuestionsItemSignalR(evaluationQuestion);
    return JSON.stringify(item);
  }

  // Update Evaluation Question in array based on Index for data received from Signal R
  public FillEvaluationQuestionObjectFromSignalRResponse(
    evaluationQuestion: ProgramEvaluationQuestionsItemSignalR,
    objIndex: number,
    evaluationQuestionsList: ProgramEvaluationQuestionsItem[],
  ): ProgramEvaluationQuestionsItem {
    const question = this.GetEvaluationQuestion(evaluationQuestionsList[objIndex], evaluationQuestion);
    return question;
  }

  // Create new Evaluation Question Item based on Response received from SignalR
  public FillProgramEvaluationObjectFromSignalRResponseNewItem(
    evaluationQuestion: ProgramEvaluationQuestionsItemSignalR,
  ): ProgramEvaluationQuestionsItem {
    const question = this.GetEvaluationQuestion(ProgramEvaluationQuestionsItem.createEmpty(), evaluationQuestion);
    return question;
  }

  // Disable Selected Evaluation Questions based on EvaluationQuestion Ids
  private DisableEvaluationQuestions(signalRModelRequest: SignalRModelRequest) {
    this.rootObj.$emit(EmitEnum.disableProgramEvaluationQuestionItems, signalRModelRequest.SelectedIds);
  }

  // Enable Selected Evaluation Questions based on EvaluationQuestion Ids
  private EnableEvaluationQuestions(signalRModelRequest: SignalRModelRequest) {
    this.rootObj.$emit(EmitEnum.enableProgramEvaluationQuestionItems, signalRModelRequest.SelectedIds);
  }
  // Get Program Item from ProgramSignalR Item

  private GetEvaluationQuestion(evalItem: ProgramEvaluationQuestionsItem, evaluationItem: ProgramEvaluationQuestionsItemSignalR): ProgramEvaluationQuestionsItem {

    evalItem = ProgramEvaluationQuestionsItem.GetEvaluationQuestionItemFromProgramEvaluationQuestionItemSignalR(evalItem, evaluationItem);
    return evalItem;
  }
}
