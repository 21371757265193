/* eslint-disable max-len */
import { AxiosInstance } from 'axios';

export class PricingCalculatorType {
  public static createEmpty(): PricingCalculatorType {
    return new PricingCalculatorType();
  }
  constructor(
    public Id: number = 0,
    public Name: string = '',
    public Heading: string = '',
    public Line1: string = '',
    public Line2: string = '',
    public Line3: string = '',
    public Line4: string = '',
    public Line5: string = '',
    public Line6: string = '',
    public Line7: string = '',
    public Line8: string = '',
  ) { }
}
export class PricingCalculatorTypes extends PricingCalculatorType {
  public arrPricingCalculatorTypes: PricingCalculatorType[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(rootObj: any, axios: AxiosInstance, $store: any, $t: any) {
    super();

    this.arrPricingCalculatorTypes.push({Id: 1, Name: 'MeetApp', Heading: rootObj.$t('VirtualMeetings.MeetAppLivePricingHeader'), Line1: rootObj.$t('VirtualMeetings.MeetAppLivePricingLine1'), Line2: rootObj.$t('VirtualMeetings.MeetAppLivePricingLine2'), Line3: rootObj.$t('VirtualMeetings.MeetAppLivePricingCurrentPrice'), Line4: rootObj.$t('VirtualMeetings.MeetAppLivePricingExample'), Line5: rootObj.$t('VirtualMeetings.MeetAppLivePricingExampleLine1'), Line6: rootObj.$t('VirtualMeetings.MeetAppLivePricingExampleLine2'), Line7: rootObj.$t('VirtualMeetings.MeetAppLiveExceedparticipanWarning'), Line8: rootObj.$t('VirtualMeetings.MeetAppLivePricingNoteLine')});

    this.arrPricingCalculatorTypes.push({Id: 2, Name: 'Vod', Heading: rootObj.$t('VirtualMeetings.VodPricingHeader'), Line1: rootObj.$t('VirtualMeetings.VodPricingLine1'), Line2: rootObj.$t('VirtualMeetings.VodPricingLine2'), Line3: rootObj.$t('VirtualMeetings.MeetAppLivePricingCurrentPrice'), Line4: rootObj.$t('VirtualMeetings.MeetAppLivePricingExample'), Line5: rootObj.$t('VirtualMeetings.MeetAppLivePricingExampleLine1'), Line6: rootObj.$t('VirtualMeetings.MeetAppLivePricingExampleLine2'), Line7: rootObj.$t('VirtualMeetings.VodExceedparticipanWarning'), Line8: rootObj.$t('VirtualMeetings.VodPricingNoteLine')});

    this.arrPricingCalculatorTypes.push({Id: 3, Name: 'Rtmp', Heading: rootObj.$t('VirtualMeetings.RtmpPricingHeader'), Line1: rootObj.$t('VirtualMeetings.RtmpPricingLine1'), Line2: rootObj.$t('VirtualMeetings.RtmpPricingLine2'), Line3: rootObj.$t('VirtualMeetings.MeetAppLivePricingCurrentPrice'), Line4: rootObj.$t('VirtualMeetings.MeetAppLivePricingExample'), Line5: rootObj.$t('VirtualMeetings.MeetAppLivePricingExampleLine1'), Line6: rootObj.$t('VirtualMeetings.MeetAppLivePricingExampleLine2'), Line7: rootObj.$t('VirtualMeetings.RtmpExceedparticipanWarning'), Line8: rootObj.$t('VirtualMeetings.VodPricingNoteLine')});

    this.arrPricingCalculatorTypes.push({Id: 4, Name: 'MeetAppLiveRecording', Heading: rootObj.$t('VirtualMeetings.MeetAppLiveRecordingPricingHeader'), Line1: rootObj.$t('VirtualMeetings.MeetAppLiveRecordingPricingLine1'), Line2: rootObj.$t('VirtualMeetings.MeetAppLiveRecordingPricingLine2'), Line3: rootObj.$t('VirtualMeetings.MeetAppLiveRecordingPricingCurrentPrice'), Line4: '', Line5: '', Line6: '', Line7: '', Line8: ''});
  }
}
