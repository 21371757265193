
































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import {
  ProgramEvaluationQuestionsItem,
  ProgramEvaluationQuestionsItemSignalR,
} from '@/entities/Program/ProgramEvaluationQuestionsItem';
import { ProgramEvaluationQuestionsViewModel } from '@/entities/Program/ProgramEvaluationQuestionsViewModel';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import EmitEnum from '@/enums/EmitEnums';
import draggable from 'vuedraggable';

import { ProgramEvaluationQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramEvaluationQuestionsSignalRModel';
import { ProgramEvaluationQuestionItemResult } from '@/entities/Program/ProgramEvaluationQuestionItemResult';
@Component({
  components: {
    loading,
    ModalPopup,
    draggable,
  },
})
export default class EvaluationQuestionsListView extends Vue {
  @Prop() private evaluationQuestions?: ProgramEvaluationQuestionsItem[];
  @Prop() private selectAllItems?: boolean;
  @Prop() private programItems?: ProgramItem[];
  @Prop()
  private editingProgramEvaluationQuestionItem?: ProgramEvaluationQuestionsItem;
  private dragging = false;
  private showLoading = false;
  private localevaluationQuestions?: ProgramEvaluationQuestionsItem[] = [];
  private selectedItems: ProgramEvaluationQuestionsItem[] = [];
  private viewModel: ProgramEvaluationQuestionsViewModel = ProgramEvaluationQuestionsViewModel.createEmpty();
  private emptySelectedItems = true;
  private evaluationQuestionDivBackgroundColor = '#f9f9f9';
  private whiteColor = '#FFFFFF';
  private temporarySelectedItems: ProgramEvaluationQuestionsItem[] = [];

  private selectedProgramEvaluationQuestionItem: ProgramEvaluationQuestionsItem = ProgramEvaluationQuestionsItem.createEmpty();

  private selectedProgramEvaluationQuestionsItemSignalR: ProgramEvaluationQuestionsItemSignalR = ProgramEvaluationQuestionsItemSignalR.createEmpty();
  private loaderBorderColor = '';
  private globalProgramEvaluation = false;
  private selectedItemId = 0;
  // modal popup properties
  private modalPopupVisible = false;
  private showCloseButton = true;
  private showSection_ModalPopup = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private localSelectedEvaluationQuestionId = 0;
  // read only properties
  private readonly GlobalEvaluation = 'Global Evaluation';
  private readonly ChangeEvalItem = 'Change Evaluation';
  private readonly Notification = 'Notification';
  private readonly NotificationUpdated = 'NotificationUpdated';
  private readonly NotificationBulkUpdated = 'NotificationBulkUpdated';
  private readonly NotificationProgramDeleted = 'NotificationProgramDeleted';
  private readonly WarningMessage = 'WarningMessage';
  private readonly NotificationNewProgramModified = 'NotificationNewProgramModified';
  private isMobileView = false;
  private modalPopupBodyHeading: string = this.getValueByLanguageKey(
    'EvaluationQuestions.ApplyAllEvaluationQuestionWarningMessageHeading',
  );
  private modalPopupBodyContent: string = this.getValueByLanguageKey(
    'EvaluationQuestions.ApplyAllEvaluationQuestionWarningMessageContent',
  );

  private toggleEvaluation(event: any) {
    const self = this;
    setTimeout(() => {
      if (self.globalProgramEvaluation) {
        self.showSection_ModalPopup = self.GlobalEvaluation;
        self.modalPopupBodyHeading = self.getValueByLanguageKey(
          'EvaluationQuestions.ApplyAllEvaluationQuestionWarningMessageHeading',
        );
        self.modalPopupBodyContent = self.getValueByLanguageKey(
          'EvaluationQuestions.ApplyAllEvaluationQuestionWarningMessageContent',
        );
        self.SetModalPopupDefaultButtonText();
        self.modalPopupVisible = true;
        self.showCloseButton = true;
        event.preventDefault();
      } else {
        self.SetGlobalEvaluationToFalse();
      }
    }, 500);
  }
  private SetModalPopupDefaultButtonText() {
    this.modalPopupShowCancelButton = true;
    this.modalPopupConfirmButtonText = this.$t('YesButton').toString();
    this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
  }

  private getValueByLanguageKey(key: string): string {
    return this.$t(key).toString();
  }

  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    // this.resetProgramItemsBackgroundColor();
    if (confirmed) {
      if (this.showSection_ModalPopup === this.GlobalEvaluation) {
        this.saveProgramEvaluationsAll();
      } else if (this.showSection_ModalPopup === this.ChangeEvalItem) {
        this.UpdateSelectedItem(this.selectedProgramEvaluationQuestionItem);
      } else if (this.showSection_ModalPopup === this.Notification) {
        this.programEvaluationQuestionItemDeletedOperation(this.selectedItemId);
      } else if (this.showSection_ModalPopup === this.NotificationUpdated) {
        this.ReloadConfirmedEvaluationQuestionItem();
      } else if (this.showSection_ModalPopup === this.NotificationBulkUpdated) {
        this.LoadEvaluationsData();
        this.selectedItems = [];
        this.$emit('uncheck-select-all-evaluation-questions', false);
      } else if (this.showSection_ModalPopup === this.NotificationNewProgramModified) {
        this.GetProgramEvaluationQuestionsViewModelModifiedProgram();
      }
    } else {
      if (this.showSection_ModalPopup === this.GlobalEvaluation) {
        this.globalProgramEvaluation = false;
      }
      // emit property to evaluation edit item pane
      // this.$root.$emit("global-program-evaluation",this.globalProgramEvaluation);
    }
  }
  private ReloadConfirmedEvaluationQuestionItem() {
    const pq: ProgramEvaluationQuestionsItemSignalR = this
      .selectedProgramEvaluationQuestionsItemSignalR;
    const objIndex = this.localevaluationQuestions!.findIndex(
      (obj: ProgramEvaluationQuestionsItem) => obj.Id === pq.Id,
    );
    const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );

    this.PerformSelectedItemUpdateOperation(
      this.SetEvaluationQuestionItemInList(objIndex, signalR, pq),
    );
  }
  private saveProgramEvaluationsAll() {
    this.SetGlobalEvaluation(true);
  }

  private SetGlobalEvaluationToFalse() {
    this.SetGlobalEvaluation(false);
  }

  private SetGlobalEvaluation(isglobalEvaluationQuestion: boolean) {
    this.showLoading = true;
    const signalObject = this.SendNotificationRequest();
    this.$axios
      .post('/Program/SaveProgramEvaluations', {
        isGlobalProgramEvaluation: isglobalEvaluationQuestion,
      })
      .then((response) => {
        if (response.data.success === false) {
          alert(this.$t('Message.UnableToSave'));
        } else {
          // Successful update / save
          this.globalProgramEvaluation = isglobalEvaluationQuestion;
          if (isglobalEvaluationQuestion) {
            this.localevaluationQuestions!.forEach((element) => {
              element.SelectedPrograms = 'All';
              element.SelectedProgramIds = '';
            });
          }
          this.emitProgramEvaluationLinkedToAllProgramItems(
            this.globalProgramEvaluation,
          );
          this.$root.$emit(
            'global-program-evaluation',
            this.globalProgramEvaluation,
          );
          this.SendNotificationRequestToQueue(signalObject);
        }
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private SendNotificationRequestToQueue(signalObject: SignalRModelRequest) {
    const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    signalObject.JSONData = JSON.stringify({
      isglobalProgramEvaluation: this.globalProgramEvaluation,
    });
    signalR.PerformEvaluationSingleOperation(signalObject);
  }
  private SendNotificationRequest(): SignalRModelRequest {
    const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    signalR.ShowNotificationsPopup();
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    signalObject = signalR.SingleToggleGlobalEvaluationQuestion;
    const arr: number[] = [];
    this.localevaluationQuestions!.forEach((element) => {
      arr.push(element.Id);
    });
    signalObject.SelectedIds = arr;
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(
        signalObject,
        this.$t('UserName.You').toString(),
      ),
    }).toString();
    signalObject.JSONData = JSON.stringify({
      isglobalProgramEvaluation: this.globalProgramEvaluation,
    });
    signalR.DisableEvaluationQuestionsAndAddToNotificationPopup(signalObject);
    return signalObject;
  }

  // Draggable component onMove Event
  private checkMove() {
    // Do something when we perform move event
  }
  // Draggable component on drag start event
  private draggingStart() {
    this.dragging = true;
  }
  // Draggable component on drag end event
  private draggingEnd() {
    this.dragging = false;
    this.SaveEvaluationsSortOrder();
  }

  private SaveEvaluationsSortOrder() {
    const evaluationIds: number[] = [];
    this.localevaluationQuestions!.forEach((element) => {
      evaluationIds.push(element.Id);
    });
    this.showLoading = true;
    this.$axios
      .post<ProgramEvaluationQuestionItemResult>(
      '/Program/SaveEvaluationQuestionsOrder',
      {
        idsInOrders: evaluationIds.toString(),
      },
    )
      .then(() => {
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  // Mounted event of Component
  private mounted() {
    if (this.evaluationQuestions != null) {
      this.localevaluationQuestions = this.evaluationQuestions;
    }
    // Get Evaluation questions from API
    this.GetProgramEvaluationQuestionsViewModel();

    // Call root Level emits
    this.callrootlevelemits();

    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  // Get Evaluation questions from API
  private GetProgramEvaluationQuestionsViewModel() {
    this.showLoading = true;
    this.LoadEvaluationsData();
  }

  // Emit Program Evaluation QuestionTypes to Landing Page and Create New Question
  private emitProgramEvaluationQuestionTypeDtos() {
    this.$emit(
      'program-evaluation-question-types',
      this.viewModel.ProgramEvaluationQuestionTypes,
    );
  }

  private emitProgramEvaluationLinkedToAllProgramItems(
    globalProgramEvaluation: boolean,
  ) {
    this.$emit(
      'program-evaluation-question-isGlobalProgramEvaluation',
      globalProgramEvaluation,
    );
  }

  private emitExistingProgramEvaluationsCount() {
    const length = this.localevaluationQuestions!.length;
    this.$emit('program-evaluation-question-count', length);
  }
  // Call all root level emits
  private callrootlevelemits() {
    this.$root.$on('update-evaluation-selected-programs', (object: any) => {
      if (object.isallSelected) {
        this.localevaluationQuestions!.forEach((element) => {
          element.SelectedPrograms = 'All';
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const items = this.GetSelectedPrograms(object.selectedProgramIds);

        this.localevaluationQuestions!.forEach((element) => {
          element.SelectedPrograms = items;
          element.SelectedProgramIds = object.selectedProgramIds;
        });
      }
    });
    this.$root.$on('clearEvaluationQuestionCheckboxes', () => {
      this.ClearAllCheckBoxes();
      this.resetEvaluationQuestionsBackgroundColor();
    });
    this.$root.$on(
      'program-evaluationQuestion-item-cancel-operation',
      (newItem: ProgramEvaluationQuestionsItem) => {
        this.ClearAllCheckBoxes();
        this.resetEvaluationQuestionsBackgroundColor();

        this.programEvaluationQuestionItemCancelledOperation(newItem);
      },
    );

    this.$root.$on(
      'createEmptyEvaluationQuestionItem',
      (newItem: ProgramEvaluationQuestionsItem) => {
        this.ClearAllCheckBoxes();
        this.selectedItems = [newItem];
        this.resetEvaluationQuestionsBackgroundColor();
      },
    );

    this.$root.$on(
      'program-evaluationquestion-item-delete-operation',
      (pqId: number) => {
        this.programEvaluationQuestionItemDeletedOperation(pqId);
      },
    );

    this.$root.$on(
      'program-evaluationquestion-item-edit-operation',
      (pq: ProgramEvaluationQuestionsItem) => {
        this.programEvaluationQuestionItemEditedOperation(pq);
      },
    );
    this.$root.$on(
      'program-evaluationquestions-bulk-item-deleted',
      (evaluationQuestionIds: number[]) => {
        evaluationQuestionIds.forEach((element) => {
          this.programEvaluationQuestionItemDeletedOperation(element);
        });
      },
    );

    this.$root.$on(
      'program-evaluationquestions-batch-item-edit-operation',
      (pq: ProgramEvaluationQuestionsItem[]) => {
        pq.forEach((element) => {
          this.programEvaluationQuestionItemEditedOperation(element);
        });
        this.selectedItems = [];
      },
    );

    this.$root.$on(
      'selected-evaluationquestions-item-notification-completed',
      (notification: SignalRModelRequest) => {
        if (notification.IsSuccess) {
          this.PerformOperationOnList(notification);
        }

        const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.ProgramSignalRModelResponseReceived(notification);
      },
    );

    this.$root.$on(
      'batch-selected-evaluation-questions-toggleAnonymous',
      (notification: SignalRModelRequest) => {
        if (notification.IsSuccess) {
          if (this.selectedItems.length === 1) {
            const objIndex = notification.SelectedIds.findIndex(
              (obj: number) => obj === this.selectedItems[0].Id,
            );
            if (objIndex > -1) {
              this.modalPopupVisible = true;
              this.showCloseButton = false;
              this.showSection_ModalPopup = this.NotificationBulkUpdated;
              this.modalPopupShowCancelButton = false;
              this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
              this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
              this.modalPopupBodyContent = '';
              this.modalPopupBodyHeading = this.$t('Message.ItemUpdatedBy', {
                username: notification.UserName,
              }).toString();
            }
          } else {
            const result = JSON.parse(notification.JSONData);
            const isAnonymous: boolean = result.isAnonymous;
            this.programEvaluationQuestionItemBulkToggleAnonymousOperation(
              notification.SelectedIds,
              isAnonymous,
            );
          }

        }
      },
    );
    this.$root.$on(
      'batch-selected-evaluation-questions-assignSelectedIds',
      (notification: SignalRModelRequest) => {
        if (notification.IsSuccess) {
          if (this.selectedItems.length === 1) {
            const objIndex = notification.SelectedIds.findIndex(
              (obj: number) => obj === this.selectedItems[0].Id,
            );
            if (objIndex > -1) {
              this.modalPopupVisible = true;
              this.showCloseButton = false;
              this.showSection_ModalPopup = this.NotificationBulkUpdated;
              this.modalPopupShowCancelButton = false;
              this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
              this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
              this.modalPopupBodyContent = '';
              this.modalPopupBodyHeading = this.$t('Message.ItemUpdatedBy', {
                username: notification.UserName,
              }).toString();
            }
          } else {
            this.programEvaluationQuestionBulkAssignSelectedProgramIds(
              notification,
            );
          }
        }
        const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.ProgramSignalRModelResponseReceived(notification);
      },
    );
    this.$root.$on(
      'reload-evaluation-questions-data',
      () => {
        this.LoadEvaluationsData();
      },
    );
    this.$root.$on(
      'update-attached-programids-in-evaluation-questions-with-notification',
      (programId: number, notification: SignalRModelRequest) => {
        this.RemoveAttachedProgramIdsInEvaluationQuestionsWithNotification(programId, notification);
      },
    );
    this.$root.$on(
      'update-attached-programids-in-evaluation-questions-with-notification-new',
      () => {
        this.LoadEvaluationsData();
      },
    );
    this.$root.$on(
      'program-evaluationQuestion-item-removed-with-notification-operation',
      (programEvaluationQuestionItemId: number, notification: SignalRModelRequest) => {
        this.UpdateSelectedEvaluationQuestionsWhenDetachedfromProgram(programEvaluationQuestionItemId, notification);
      },
    );
    this.$root.$on(
      EmitEnum.disableProgramEvaluationQuestionItems,
      (selectedIds: number[]) => {
        selectedIds.forEach((element) => {
          this.localevaluationQuestions!.find(
            (x) => x.Id === element,
          )!.DisableItem = true;
        });
      },
    );
    this.$root.$on(
      EmitEnum.enableProgramEvaluationQuestionItems,
      (selectedIds: number[]) => {
        selectedIds.forEach((element) => {
          this.localevaluationQuestions!.find(
            (x) => x.Id === element,
          )!.DisableItem = false;
        });
      },
    );
  }


  private RemoveAttachedProgramIdsInEvaluationQuestionsWithNotification(programId: number, notification: SignalRModelRequest) {
    this.localevaluationQuestions!.forEach((element) => {
      if (this.selectedItems.length === 1 && element.Id === this.selectedItems[0].Id) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSection_ModalPopup = this.NotificationNewProgramModified;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t(
          'Button.Ok',
        ).toString();
        this.modalPopupCancelButtonText = this.$t(
          'CancelButton',
        ).toString();
        this.modalPopupBodyContent = '';
        this.modalPopupBodyHeading = this.$t('Message.ItemUpdatedBy', {username: notification.UserName}).toString();
        this.localSelectedEvaluationQuestionId = this.selectedItems[0].Id;
      } else {
        this.LoadEvaluationsData();
      }

    });
  }

  private UpdateSelectedEvaluationQuestionsWhenDetachedfromProgram(programEvaluationQuestionItemId: number, notification: SignalRModelRequest) {
    if (this.selectedItems.length === 1 && programEvaluationQuestionItemId === this.selectedItems[0].Id) {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSection_ModalPopup = this.NotificationNewProgramModified;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t(
        'Button.Ok',
      ).toString();
      this.modalPopupCancelButtonText = this.$t(
        'CancelButton',
      ).toString();
      this.modalPopupBodyContent = '';
      this.modalPopupBodyHeading = this.$t('Message.ItemUpdatedBy', {username: notification.UserName}).toString();
      this.localSelectedEvaluationQuestionId = this.selectedItems[0].Id;
    } else  {
      this.LoadEvaluationsData();
    }
  }

  private programEvaluationQuestionBulkAssignSelectedProgramIds(
    notification: SignalRModelRequest,
  ) {
    const evaluationQuestionIds: number[] = notification.SelectedIds;
    const result = JSON.parse(notification.JSONData);
    const programIds: number[] = result.selectedProgramIds;
    evaluationQuestionIds.forEach((element) => {

      const objIndex = this.localevaluationQuestions!.findIndex(
        (obj: ProgramEvaluationQuestionsItem) => obj.Id === element,
      );
      if (objIndex > -1) {
        const evaluationItem: ProgramEvaluationQuestionsItem = this
          .localevaluationQuestions![objIndex];
        evaluationItem.SelectedProgramIds = programIds.toString();

        Vue.set(this.localevaluationQuestions!, objIndex, evaluationItem); // Update array w reactivity
      }
    });
  }
  private PerformOperationOnList(notification: SignalRModelRequest) {
    if (
      notification.Module === ModuleEnum.ProgramEvaluationQuestions &&
      notification.SubModule === SubModuleEnum.SingleDelete
    ) {
      if (notification.SelectedIds.length > 0) {
        if (
          this.selectedItems.length === 1 &&
          this.selectedItems[0].Id === notification.SelectedIds[0]
        ) {
          this.CheckIfDeletedItemIsOpenInEditMode(notification);
        } else {
          this.programEvaluationQuestionItemDeletedOperation(
            notification.SelectedIds[0],
          );
        }
      }
    } else if (
      notification.Module === ModuleEnum.ProgramEvaluationQuestions &&
      notification.SubModule === SubModuleEnum.EditGlobalEvaluationQuestion
    ) {
      const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
        this.$root,
        this.$axios,
        this.$store,
        this.$t,
      );
      if (signalR.IsLoggedInUserIsMe(notification)) {
        this.LoadEvaluationsData();
        this.UncheckSelectAllCheckbox();
        this.selectedItems = [];
      }  else {
        if (this.selectedItems.length > 0) {
          this.modalPopupVisible = true;
          this.showCloseButton = false;
          this.showSection_ModalPopup = this.NotificationBulkUpdated;
          this.modalPopupShowCancelButton = false;
          this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
          this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
          this.modalPopupBodyContent = '';
          if (this.selectedItems.length > 1) {
            this.modalPopupBodyHeading = this.$t('Message.ItemsUpdatedBy', {
              username: notification.UserName,
            }).toString();
          } else {
            this.modalPopupBodyHeading = this.$t('Message.ItemUpdatedBy', {
              username: notification.UserName,
            }).toString();
          }
        } else {

          this.LoadEvaluationsData();
        }
      }
    } else {
      this.programEvaluationQuestionItemEditedOperationWithNotification(
        notification,
      );
    }
  }
  private CheckIfDeletedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {

    const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    if (!signalR.IsLoggedInUserIsMe(notification)) {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSection_ModalPopup = this.Notification;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupBodyContent = '';
      this.modalPopupBodyHeading = this.$t('Message.ItemDeletedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedItemId = notification.SelectedIds[0];
    } else {
      this.programEvaluationQuestionItemDeletedOperation(
        notification.SelectedIds[0],
      );
    }
  }

  // Method to clear all chackboxes
  private ClearAllCheckBoxes() {
    this.selectedItems = [];
  }
  // Open Create new Question Pane
  private OpenCreateNewQuestionpane() {
    this.$emit('create-new-question-click', '');
  }

  // Reset Background color for all evaluation Questions listing
  private resetEvaluationQuestionsBackgroundColor() {
    this.localevaluationQuestions!.forEach(function(item) {
      item.defaultBGColor = '#f9f9f9';
    });
  }

  // Remove Evaluation Question from Evaluation Question Listing after it has been deleted
  private programEvaluationQuestionItemDeletedOperation(pqId: number) {
    const objIndex = this.localevaluationQuestions!.findIndex(
      (obj: ProgramEvaluationQuestionsItem) => obj.Id === pqId,
    );
    if (objIndex > -1) {
      this.localevaluationQuestions!.splice(objIndex, 1);
      this.selectedItems = [];
      this.emitExistingProgramEvaluationsCount();
    }
  }

  // Update Evaluation Question values in Evaluation Question Listing after updates
  private programEvaluationQuestionItemEditedOperationWithNotification(
    notification: SignalRModelRequest,
  ) {
    const pq: ProgramEvaluationQuestionsItemSignalR = JSON.parse(
      notification.JSONData,
    );
    const objIndex = this.localevaluationQuestions!.findIndex(
      (obj: ProgramEvaluationQuestionsItem) => obj.Id === pq.Id,
    );
    const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    // New Evaluation Question Added
    if (objIndex === -1) {
      const pItem = signalR.FillProgramEvaluationObjectFromSignalRResponseNewItem(
        pq,
      );
      this.localevaluationQuestions!.push(pItem);
      this.emitExistingProgramEvaluationsCount();
      // scroll to element
      if (signalR.IsLoggedInUserIsMe(notification)) {
        this.RedirectToItem(this.localevaluationQuestions!.length - 1);
      }
    } else if (objIndex > -1) {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === pq.Id
      ) {
        this.CheckIfUpdatedItemIsOpenInEditMode(
          notification,
          signalR,
          objIndex,
          pq,
        );
      } else {
        this.SetEvaluationQuestionItemInList(objIndex, signalR, pq);
      }
      // scroll to element
      if (signalR.IsLoggedInUserIsMe(notification)) {
        this.RedirectToItem(objIndex);
      }
    }
  }


  private CheckIfUpdatedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
    signalR: ProgramEvaluationQuestionsSignalRModel,
    objIndex: number,
    pq: ProgramEvaluationQuestionsItemSignalR,
  ) {
    if (signalR.IsLoggedInUserIsMe(notification)) {
      this.PerformSelectedItemUpdateOperation(
        this.SetEvaluationQuestionItemInList(objIndex, signalR, pq),
      );
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSection_ModalPopup = this.NotificationUpdated;
      this.modalPopupShowCancelButton = true;
      this.modalPopupConfirmButtonText = this.$t('Button.Reload').toString();
      this.modalPopupCancelButtonText = this.$t('Button.Ignore').toString();
      this.modalPopupBodyContent = '';
      this.modalPopupBodyHeading = this.$t('Message.ItemUpdatedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedProgramEvaluationQuestionsItemSignalR = pq;

      // if (!confirm('This item has been updated. Reload?')) {
      //   return;
      // } else {
      //   this.PerformSelectedItemUpdateOperation(
      //     this.SetEvaluationQuestionItemInList(objIndex, signalR, pq),
      //   );
      // }
    }
  }

  private PerformSelectedItemUpdateOperation(
    pItem: ProgramEvaluationQuestionsItem,
  ) {
    this.selectedProgramEvaluationQuestionItem = pItem;
    this.selectedItems[0] = pItem;
    this.$root.$emit('program-evaluation-questions-updated', pItem);
  }

  private SetEvaluationQuestionItemInList(
    objIndex: number,
    signalR: ProgramEvaluationQuestionsSignalRModel,
    pq: ProgramEvaluationQuestionsItemSignalR,
  ): ProgramEvaluationQuestionsItem {
    const pItem = signalR.FillEvaluationQuestionObjectFromSignalRResponse(
      pq,
      objIndex,
      this.localevaluationQuestions!,
    );
    Vue.set(this.localevaluationQuestions!, objIndex, pItem); // Update array w reactivity
    return pItem;
  }
  private LoadEvaluationsData() {
    this.$axios
      .post<ProgramEvaluationQuestionsViewModel>(
      '/Program/GetProgramEvaluationQuestionsViewModel',
    )
      .then((response) => {
        const copyViewModel = response.data;
        this.viewModel = copyViewModel;
        this.localevaluationQuestions = this.viewModel.ProgramEvaluationQuestionDtos;
        this.globalProgramEvaluation = this.viewModel.GlobalProgramEvaluation!;
        this.showLoading = false;
        this.emitProgramEvaluationQuestionTypeDtos();
        this.emitProgramEvaluationLinkedToAllProgramItems(
          this.viewModel.GlobalProgramEvaluation!,
        );
        this.emitExistingProgramEvaluationsCount();
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private GetProgramEvaluationQuestionsViewModelModifiedProgram() {
    this.$axios
      .post<ProgramEvaluationQuestionsViewModel>(
      '/Program/GetProgramEvaluationQuestionsViewModel',
    )
      .then((response) => {
        const copyViewModel = response.data;
        this.viewModel = copyViewModel;
        this.localevaluationQuestions = this.viewModel.ProgramEvaluationQuestionDtos;
        this.globalProgramEvaluation = this.viewModel.GlobalProgramEvaluation!;
        this.showLoading = false;
        this.emitProgramEvaluationQuestionTypeDtos();
        this.emitProgramEvaluationLinkedToAllProgramItems(
          this.viewModel.GlobalProgramEvaluation!,
        );
        this.emitExistingProgramEvaluationsCount();

        this.selectedItems = [];
        const objIndex = this.localevaluationQuestions!.findIndex(
          (obj: ProgramEvaluationQuestionsItem) => obj.Id === this.localSelectedEvaluationQuestionId,
        );
        if (objIndex > -1) {
          const evaluationQuestion = this.localevaluationQuestions![objIndex];
          this.UpdateSelectedItem(evaluationQuestion);
        }
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private programEvaluationQuestionItemBulkToggleAnonymousOperation(
    evaluationQuestionIds: number[],
    isAnonymous: boolean,
  ) {
    evaluationQuestionIds.forEach((element) => {
      const objIndex = this.localevaluationQuestions!.findIndex(
        (obj: ProgramEvaluationQuestionsItem) => obj.Id === element,
      );
      if (objIndex > -1) {
        const selectedEval = this.localevaluationQuestions![objIndex];
        selectedEval.Anynomous = isAnonymous;
        Vue.set(this.localevaluationQuestions!, objIndex, selectedEval); // Update array w reactivity
      }
    });
  }

  private programEvaluationQuestionItemEditedOperation(
    pq: ProgramEvaluationQuestionsItem,
  ) {
    const objIndex = this.localevaluationQuestions!.findIndex(
      (obj: ProgramEvaluationQuestionsItem) => obj.Id === pq.Id,
    );
    // New Evaluation Question Added
    if (objIndex === -1) {
      this.localevaluationQuestions!.push(pq);
      this.emitExistingProgramEvaluationsCount();
      // scroll to bottom
    } else if (objIndex > -1) {
      // if only title is updated then SelectedPrograms and SelectedProgramIds will be null.
      if (pq.SelectedPrograms === null && pq.SelectedProgramIds === null) {
        pq.SelectedPrograms = this.localevaluationQuestions![
          objIndex
        ].SelectedPrograms;
      }
      Vue.set(this.localevaluationQuestions!, objIndex, pq); // Update array w reactivity
    }
    this.selectEvaluationQuestion(pq);
  }
  private programEvaluationQuestionItemCancelledOperation(
    pe: ProgramEvaluationQuestionsItem,
  ) {
    const objIndex = this.localevaluationQuestions!.findIndex(
      (obj: ProgramEvaluationQuestionsItem) => obj.Id === pe.Id,
    );
    // Edited an existing evaluation Question
    if (objIndex > -1) {
      Vue.set(this.localevaluationQuestions!, objIndex, pe); // Update array w reactivity
    }
  }
  @Watch('editingProgramEvaluationQuestionItem')

  private ChangeEditingProgramEvaluationQuestionItem(
    val: ProgramEvaluationQuestionsItem) {

    if (
      ProgramEvaluationQuestionsItem.prototype.CompareProgramObjects({
        val1: val,
        val2: this.selectedItems[this.selectedItems.length - 1],
      })
    ) {
      this.UpdateSelectedItem(this.selectedProgramEvaluationQuestionItem);
    } else {
      this.modalPopupBodyHeading = this.$t('ChangeScreenMessage').toString();
      this.modalPopupBodyContent = '';
      this.showSection_ModalPopup = this.ChangeEvalItem;
      this.SetModalPopupDefaultButtonText();
      this.modalPopupVisible = true;
      this.showCloseButton = true;
    }
  }
  private UpdateSelectedItem(
    selectedProgramEvaluationQuestion: ProgramEvaluationQuestionsItem,
  ) {
    this.UncheckSelectAllCheckbox();
    this.resetEvaluationQuestionsBackgroundColor();
    selectedProgramEvaluationQuestion.defaultBGColor = this.whiteColor;
    this.selectedItems = [];
    if (
      !this.globalProgramEvaluation &&
      selectedProgramEvaluationQuestion.SelectedPrograms === 'All'
    ) {
      selectedProgramEvaluationQuestion.SelectedPrograms = '';
    }
    this.selectedItems.push(selectedProgramEvaluationQuestion);
  }
  // Select a Evaluation question in Evaluation Question Listing and change the background color of the selected item
  private selectEvaluationQuestion(
    selectedProgramEvaluationQuestion: ProgramEvaluationQuestionsItem,
  ) {
    if (this.selectedItems.length === 1) {

      this.$emit('change-evaluation-question-item', {
        selectedItem: selectedProgramEvaluationQuestion,
        moveToAnotherTab: false,
      });
      this.selectedProgramEvaluationQuestionItem = selectedProgramEvaluationQuestion;
    } else {
      this.UpdateSelectedItem(selectedProgramEvaluationQuestion);
    }
  }
  private selectEvaluationQuestionMobileView(
    selectedProgramEvaluationQuestion: ProgramEvaluationQuestionsItem,
  ) {

    if (this.isMobileView) {
      if (this.selectedItems.length === 1) {

        this.$emit('change-evaluation-question-item', {
          selectedItem: selectedProgramEvaluationQuestion,
          moveToAnotherTab: false,
        });
        this.selectedProgramEvaluationQuestionItem = selectedProgramEvaluationQuestion;
      } else {
        this.UpdateSelectedItem(selectedProgramEvaluationQuestion);
      }
    }
  }
  // uncheck select all checkbox
  private UncheckSelectAllCheckbox() {
    this.$emit('uncheck-select-all-evaluation-questions', false);
    this.emptySelectedItems = false;
  }

  // Select Evaluation questions using checkboxes for Single / Batch operations
  private checkEvaluationQuestions(
    val: ProgramEvaluationQuestionsItem,
    index: number,
    event: any,
  ) {
    this.UncheckSelectAllCheckbox();
    this.resetEvaluationQuestionsBackgroundColor();
    setTimeout(() => {
      const programItemChecked = event.target.checked;
      if (!programItemChecked && this.selectedItems.length === 1) {
        this.temporarySelectedItems = this.selectedItems;
        this.temporarySelectedItems = this.temporarySelectedItems.filter(
          (x) => x.Id !== val.Id,
        );
        this.selectEvaluationQuestion(this.temporarySelectedItems[0]);
      } else if (this.selectedItems.length === 0) {
        this.selectEvaluationQuestion(val);
      } else if (this.selectedItems.length > 0) {
        const objIndex = this.selectedItems.findIndex(
          (obj: ProgramEvaluationQuestionsItem) => obj.Id === 0,
        );
        if (objIndex > -1) {
          this.selectedItems.splice(objIndex, 1);
        }
      }
    }, 100);
  }

  private GetSelectedPrograms(selectedProgramIds: number[]): string {
    let selectedPrograms = '';

    if (this.programItems) {
      selectedProgramIds.forEach((element) => {
        const objIndex = this.programItems!.findIndex(
          (obj: ProgramItem) => obj.Id === element,
        );
        if (objIndex > -1) {
          selectedPrograms =
            selectedPrograms + this.programItems![objIndex].Title + ',';
        }
      });

      if (selectedPrograms !== '') {
        selectedPrograms = selectedPrograms.slice(0, -1);
      }
    }

    return selectedPrograms;
  }

  // Event that fires when selectedItems Property change
  @Watch('selectedItems')
  private onPropertyChange(
    val: ProgramEvaluationQuestionsItem[]) {
    this.$emit('program-evaluation-questions-selected', val);
    // emit property if diffirent session question item selected and scroll will go to top
    this.$root.$emit('new-evaluation-question-edit-item-pane', true);
  }
  // Event fires when Select All checkbox is checked or unchecked
  @Watch('selectAllItems')
  private onPropertySelectAllItemsChange(val: boolean) {
    if (this.localevaluationQuestions) {
      if (val === true) {
        this.selectedItems = [];
        this.localevaluationQuestions.forEach((item) => {
          this.selectedItems.push(item);
        });
      } else {
        if (this.emptySelectedItems) {
          this.selectedItems = [];
        }
      }
      this.emptySelectedItems = true;
    }
  }
  private RedirectToItem(index: number) {
    setTimeout(() => {
      const element = document.querySelectorAll(
        '[indexEvaluation="' + index + '"]',
      )[0];
      if (element) {
        element.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
          inline: 'end',
        });
      }
    }, 100);
  }
  private beforeDestroy() {
    this.$root.$off('selected-evaluationquestions-item-notification-completed');
    this.$root.$off('program-evaluationquestions-bulk-item-deleted');
    this.$root.$off('batch-selected-evaluation-questions-toggleAnonymous');
    this.$root.$off('batch-selected-evaluation-questions-assignSelectedIds');
  }
}
