




















































































































































































































































































































































import { VodDetail, VodSignalRStatus } from '@/entities/VodDetail';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VideoPlayer from '@/components/Common/VideoPlayer.vue';
import moment from 'moment-timezone';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import VirtualMeetingtypeParentEnum from '@/entities/VirtualMeetingType';
import VirtualMeetingEnums from '@/enums/VirtualMeetingEnums';
import VodTypeEnums from '@/enums/VodTypeEnums';
import { VodBaseRenderModel } from '@/entities/FactoryPattern/Vod/VodBaseRenderModel';
import VodModuleTypeEnum from '@/enums/VodModuleTypeEnum';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';

@Component({
  components: {
    VideoPlayer,
    ModalPopup,
  },
})
export default class VideoOnDemandComponent extends Vue {
  @Prop() private SelectedVodBaseClasss?: VodBaseRenderModel;
  @Prop() private vonageThresholdLimitReached?: boolean;
  // #region Variables
  private isAddVideoWarningVisible = false;
  private isVideoDetailsVisible = false;
  private videoPlayerVisible = false;
  private isCurrentUserMe = false;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private noThumbnailImage: string = require('@/assets/Images/Nothumbnail.png');
  private uploadingImage: string = require('@/assets/Images/Uploading.png');
  private processingImage: string = require('@/assets/Images/Processinge.png');
  private ErrorImage: string = require('@/assets/Images/ErrorOccuered.png');
  private DeletedImage: string = require('@/assets/Images/videoisdeleted.png');
  private isIEbrowser = false;
  private dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat();
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private timeZones: string =
    this.storeHelper.GetActiveEventIanaTimeZoneFromStore();
  private addVideoCheckbox = false;
  private latestVideoStatus = '';
  private VodDeleteVideo = 'VodDeleteVideo';
  private RtmpDeleteVideo = 'RtmpDeleteVideo';
  private VonageMUXDeleteVideo = 'VonageMUXDeleteVideo';
  private videoDuration = '0';
  private videoDurationNumber = '0';
  private publishDateE: any = new Date();
  // modal popup
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupHeader = '';
  private modalPopupContent = '';
  private showSectionModalPopup = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private isActive = false;
  private scheduledPublishedOn = false;
  private PublishedOn = true;
  private localViewCount = 0;
  private isVideoToBePlayed = false;
  private currentWebHookEvent?: VodSignalRStatus | null;
  private isManageBtnVisible = false;
  private isDisable = false;
  public get virtualMeetingEnum(): typeof VirtualMeetingtypeParentEnum {
    return VirtualMeetingtypeParentEnum;
  }
  private localSubModuleType = 0;
  private UploadRecordedOnLabel = '';
  private localSelectedVodBaseClass: VodBaseRenderModel =
    VodBaseRenderModel.createEmpty();
  private localLinkedProgramLoading = true;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private moduleType: string = this.SelectedVodBaseClasss!.Moduletype;
  // #endregion
  private created() {
    if (
      navigator.appName === 'Microsoft Internet Explorer' ||
      !!(
        navigator.userAgent.match(/Trident/) ||
        navigator.userAgent.match(/rv:11/)
      )
    ) {
      this.isIEbrowser = true;
    }
  }
  private mounted() {
    // set selected vod base class object to local object
    this.localSelectedVodBaseClass = this.SelectedVodBaseClasss!;
    // disable checkbox, if Meetapp live meeting has passed
    this.$root.$on(
      'virtual-meeting-meetapp-live-analytics-available',
      (val: boolean) => {
        this.isDisable = val;
      },
    );
    // set submodule type in local variable
    this.localSubModuleType = this.localSelectedVodBaseClass.SubModuleType;
    this.setDefaultDisplayDates(self);
    // this.getUploadUlr();

    // Check submodule type, set video information heading and show hide analytics button

    if (
      this.localSubModuleType === this.virtualMeetingEnum.ProfessionalStreaming
    ) {
      this.localSelectedVodBaseClass.SubModuleTypeValue =
        VirtualMeetingEnums.RTMPString;
      this.UploadRecordedOnLabel = this.$t(
        'VirtualMeetings.RecordedOn',
      ).toString();
      this.$root.$emit(
        'virtual-meeting-meetapp-live-analytics-available',
        true,
      );
    } else if (
      this.localSubModuleType === this.virtualMeetingEnum.MeetAppLive &&
      this.localSelectedVodBaseClass.SubModuleTypeValue ===
        VirtualMeetingEnums.VonageMUX
    ) {
      // when we have vonageMux virtualmeeting type
      this.localSelectedVodBaseClass.SubModuleTypeValue =
        VirtualMeetingEnums.VonageMUX;
      this.UploadRecordedOnLabel = this.$t(
        'VirtualMeetings.RecordedOn',
      ).toString();
      this.$root.$emit(
        'virtual-meeting-meetapp-live-analytics-available',
        true,
      );
    } else {
      // for MUX
      this.localSelectedVodBaseClass.SubModuleTypeValue =
        VirtualMeetingEnums.MuxString;
      this.UploadRecordedOnLabel = this.$t(
        'VirtualMeetings.PreRecordedDetails.UploadedOn',
      ).toString();
    }
    this.$root.$on('publishedOnDate', (val: any) => {
      this.publishDateE = val;
    });
    // loader off from custom category end
    this.$root.$on('customCategoryDetailLoadingVod', (loading: boolean) => {
      this.localLinkedProgramLoading = loading;
    });
    this.initializeTimeFormat();
    this.GetViewsCount();
    this.isAddVideoWarningVisible =
      this.localSelectedVodBaseClass.EntityId === 0 ? true : false;

    if (
      this.localSelectedVodBaseClass.VodDetail !== null &&
      this.localSelectedVodBaseClass.VodDetail !== undefined &&
      this.localSelectedVodBaseClass.VodDetail.VODLatestStatus !== null &&
      this.localSelectedVodBaseClass.VodDetail.VODLatestStatus !== ''
    ) {
      this.isVideoDetailsVisible =
        this.localSelectedVodBaseClass.VodDetail.Id > 0 ? true : false;
      this.latestVideoStatus =
        this.localSelectedVodBaseClass.VodDetail.VODLatestStatus;
      this.isCurrentUserMe =
        this.latestVideoStatus === 'VideoAssetReady' ||
        this.latestVideoStatus === 'VideoAssetUpdated' ||
        this.latestVideoStatus === 'VideoAssetErrored' ||
        this.latestVideoStatus === 'VideoAssetLiveStreamCompleted'
          ? true
          : false;
    }

    this.GetDuration();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.$root.$on('VodMuxVideoData', ( videoDetails: VodDetail, virtualMeetingType: string,  Id: number, Title: string ) => {
      this.localSelectedVodBaseClass.EntityId =
          this.SelectedVodBaseClasss!.EntityId;
      this.isAddVideoWarningVisible =
          this.localSelectedVodBaseClass.EntityId === 0 ? true : false;
      if (videoDetails !== undefined) {
        this.localSelectedVodBaseClass.VodDetail = videoDetails;
        if (videoDetails !== null && videoDetails !== undefined) {
          if (videoDetails.Id > 0) {
            this.$emit('threshHold', false);
            this.vonageThresholdLimitReached = false;
            if (videoDetails.VodStreamingType === VodTypeEnums.Mux) {
              this.$root.$emit(
                'set-selected-meeting-type',
                VirtualMeetingEnums.MuxString,
              );
              this.isVideoDetailsVisible = true;
              this.setDefaultDisplayDates(this);
              this.latestVideoStatus = videoDetails.VODLatestStatus;
              this.isCurrentUserMe =
                videoDetails.VODLatestStatus === 'VideoAssetReady' ||
                videoDetails.VODLatestStatus === 'VideoAssetUpdated' ||
                videoDetails.VODLatestStatus === 'VideoAssetErrored'
                  ? true
                  : false;

              if (
                this.localSelectedVodBaseClass.VodDetail.VODLatestStatus !==
                  'VideoAssetReady' &&
                this.localSelectedVodBaseClass.VodDetail.VODLatestStatus !==
                  'VideoAssetErrored'
              ) {
                this.$root.$emit(
                  'virtual-meeting-meetapp-live-analytics-available',
                  true,
                );
              }
            }
          } else {
            this.isVideoDetailsVisible = false;
          }
        }
        this.GetViewsCount();
        this.GetDuration();
        this.isDisable = false;
        this.localLinkedProgramLoading = false;
      }
    },
    );
    this.$root.$on('customCategoryNewAdded', () => {
      this.localSelectedVodBaseClass.EntityId = 0;
      this.isVideoDetailsVisible = false;
      this.isAddVideoWarningVisible =
        this.localSelectedVodBaseClass.EntityId === 0 ? true : false;
    });
    this.$root.$on('program-vod-video-uploading-user-is-me', (val: boolean) => {
      this.isCurrentUserMe = val;
    });
    this.$root.$on(
      'entity-item-video-upload-current-status',
      (vodVideoStatus: VodSignalRStatus) => {
        this.currentWebHookEvent = vodVideoStatus;
        if (
          this.currentWebHookEvent !== undefined &&
          this.currentWebHookEvent.Id ===
            this.localSelectedVodBaseClass.VodDetail.Id
        ) {
          if (
            vodVideoStatus.VodStatus === 'VideoUploadCreated' ||
            vodVideoStatus.VodStatus === 'VideoAssetCreated' ||
            vodVideoStatus.VodStatus === 'VideoUploadAssetCreated' ||
            vodVideoStatus.VodStatus === 'VideoAssetReady' ||
            vodVideoStatus.VodStatus === 'VideoAssetErrored'
          ) {
            this.isVideoDetailsVisible = true;
            if (this.localSelectedVodBaseClass.VodDetail === undefined) {
              this.localSelectedVodBaseClass.VodDetail =
                VodDetail.createEmpty();
            }
            this.localSelectedVodBaseClass.VodDetail.VODLatestStatus =
              vodVideoStatus.VodStatus;
            this.latestVideoStatus = vodVideoStatus.VodStatus;
            this.GetPosterUrl();
          }
        }
      },
    );
    this.$root.$on(
      'entity-item-video-upload-current-status-api',
      (vodDetail: VodDetail) => {
        if (vodDetail.VODLatestStatus === 'VideoAssetReady') {
          this.isVideoDetailsVisible = true;
          if (this.localSelectedVodBaseClass.VodDetail === undefined) {
            this.localSelectedVodBaseClass.VodDetail = VodDetail.createEmpty();
          }
          this.localSelectedVodBaseClass.VodDetail = vodDetail;
          this.latestVideoStatus = vodDetail.VODLatestStatus;
          this.GetPosterUrl();
          this.GetDuration();
        }
      },
    );
  }
  private updated() {
    if (
      this.localSelectedVodBaseClass.SubModuleType ===
      this.virtualMeetingEnum.ProfessionalStreaming
    ) {
      this.UploadRecordedOnLabel = this.$t(
        'VirtualMeetings.RecordedOn',
      ).toString();
    } else if (
      this.localSelectedVodBaseClass.SubModuleTypeValue ===
      VirtualMeetingEnums.VonageMUX
    ) {
      // type vonageMux then showing recorded on text
      this.UploadRecordedOnLabel = this.$t(
        'VirtualMeetings.RecordedOn',
      ).toString();
    } else {
      this.UploadRecordedOnLabel = this.$t(
        'VirtualMeetings.PreRecordedDetails.UploadedOn',
      ).toString();
    }
  }
  // #region ThresholdLimits
  private GetThresholdLimitReachedMsg() {
    if (this.localSelectedVodBaseClass.SubModuleType === 3) {
      return this.$t('VirtualMeetings.MeetAppLive.TresholdLimitReached');
    } else {
      return this.$t('VirtualMeetings.PreRecordedDetails.TresholdLimitReached');
    }
  }
  // #endregion

  // #region DateBlock

  // Get UploadedOn date
  private GetUploadedOn() {
    if (
      this.localSelectedVodBaseClass.VodDetail &&
      this.localSelectedVodBaseClass.VodDetail.VodDetailApplicationInstance! &&
      this.localSelectedVodBaseClass.VodDetail.VodDetailApplicationInstance!
        .UploadedOn
    ) {
      return this.formatTime(
        this.localSelectedVodBaseClass.VodDetail.VodDetailApplicationInstance!.UploadedOn.toString(),
      );
    }
    return this.formatTime(new Date().toString());
  }

  // Get AvailableTillDate
  private GetAvailableTillDate() {
    const todayDate: Date = new Date();
    if (
      this.localSelectedVodBaseClass.VodDetail &&
      this.localSelectedVodBaseClass.VodDetail.VodDetailApplicationInstance !==
        null &&
      this.localSelectedVodBaseClass.VodDetail.VodDetailApplicationInstance !==
        undefined
    ) {
      const selectedate =
        this.localSelectedVodBaseClass.VodDetail.VodDetailApplicationInstance
          .StorageTillDate;
      if (
        this.localSelectedVodBaseClass.VodDetail &&
        this.localSelectedVodBaseClass.VodDetail.VodDetailApplicationInstance &&
        this.localSelectedVodBaseClass.VodDetail.VodDetailApplicationInstance
          .StorageTillDate
      ) {
        return moment(selectedate).utc().format('MMM DD, YYYY');
      }
    }
    return this.formatTime(todayDate.toString());
  }

  // set format of time according to browser
  private formatTime(date: string): string {
    if (date !== null) {
      const datepublished: Date = moment(date).toDate();
      if (!this.isIEbrowser) {
        if (this.timeZones !== null && this.timeZones !== '') {
          return moment(datepublished)
            .tz(this.timeZones)
            .format('MMM DD, YYYY');
        }
        return moment(datepublished).format('MMM DD, YYYY');
      } else {
        if (this.timeZones !== null && this.timeZones !== '') {
          return moment(datepublished).tz(this.timeZones).format('DD-MM-YYYY');
        }
        const ieDateForamt = moment(datepublished).format('DD-MM-YYYY');
        return ieDateForamt;
      }
    } else {
      return '-';
    }
  }
  //  set format of PublishedOnDate
  private formatPublishedOnDate(date: string): string {
    if (date !== null) {
      const datepublished: Date = moment(date).toDate();
      const todayDate: Date = new Date();
      const timeDiff = datepublished.getTime() - todayDate.getTime();
      const daysDifference = timeDiff / (1000 * 3600 * 24);
      if (!this.isIEbrowser) {
        if (this.timeZones !== null && this.timeZones !== '') {
          if (daysDifference > 0) {
            const DateFormat =
              this.$t('VirtualMeetings.PreRecordedDetails.Scheduled') +
              ' (' +
              moment(datepublished)
                .tz(this.timeZones)
                .format('MMM DD, YYYY, hh:mm A') +
              ')';
            return DateFormat;
          } else {
            const DateForamt = moment(datepublished)
              .tz(this.timeZones)
              .format('MMM DD, YYYY, hh:mm A');
            return DateForamt;
          }
        } else {
          if (daysDifference > 0) {
            const DateFormat =
              this.$t('VirtualMeetings.PreRecordedDetails.Scheduled') +
              ' (' +
              moment(datepublished).format('MMM DD, YYYY, hh:mm A') +
              ')';
            return DateFormat;
          } else {
            const DateForamt = moment(datepublished).format(
              'MMM DD, YYYY, hh:mm A',
            );
            return DateForamt;
          }
        }
      } else {
        if (this.timeZones !== null && this.timeZones !== '') {
          return moment(datepublished).tz(this.timeZones).format('DD-MM-YYYY');
        }
        return moment(datepublished).format('DD-MM-YYYY');
      }
    } else {
      return '-';
    }
  }
  // format PublishedOnDateNew
  private formatPublishedOnDateNew(obj: any): string {
    if (obj.PublishOption === 1) {
      return this.formatPublishedOnDate(String(obj.PublishDate));
    } else {
      return this.formatPublishedOnDate(String(obj.PublishUTCDate));
    }
  }

  // Initialize and set time format according to browser and timezone
  private initializeTimeFormat() {
    const browsersettings: BrowserSettingsInfo =
      this.storeHelper.GetBrowserSettingsFromStore();
    if (this.timeZones !== '' && this.timeZones !== null && !this.isIEbrowser) {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        timeZone: this.timeZones,
      });
    } else {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });
    }
  }

  // set DefaultDisplayDates
  private setDefaultDisplayDates(self: any) {
    if (self.localSelectedVodBaseClass !== null &&
    self.localSelectedVodBaseClass !== undefined &&
      self.localSelectedVodBaseClass!.VodDetail! !== null &&
      self.localSelectedVodBaseClass!.VodDetail! !== undefined &&
      self.localSelectedVodBaseClass!.VodDetail!
        .VodDetailApplicationInstance! !== null &&
      self.localSelectedVodBaseClass!.VodDetail!
        .VodDetailApplicationInstance! !== undefined &&
      self.localSelectedVodBaseClass!.VodDetail!.VodDetailApplicationInstance!
        .PublishUTCDate !== null &&
      self.localSelectedVodBaseClass!.VodDetail!.VodDetailApplicationInstance!
        .PublishUTCDate !== undefined
    ) {
      self.publishDateE =
        self.localSelectedVodBaseClass!.VodDetail!.VodDetailApplicationInstance!.PublishUTCDate;
    }
  }

  // #endregion

  // #region VideoBlock
  // show VideoDuration
  private showVideoDuration(VideoDuration: string) {
    const d = Number(VideoDuration);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    if (h > 0) {
      return h + ':' + m + ':' + s;
    }
    return m + ':' + s;
  }
  // Show AddVideo component
  private ShowAddVideo() {
    this.$root.$emit('show-add-video-section-byEntity', true);
    this.$root.$emit(
      'show-add-video-section-vod-byEntity',
      this.localSelectedVodBaseClass.SubModuleType,
    );
  }
  // Retry to add video
  private TryAgain() {
    if (this.localSelectedVodBaseClass.VodDetail.Id) {
      // Delete video API call
      this.$axios
        .post<any>(
        '/' +
            this.localSelectedVodBaseClass.Moduletype +
            '/DeleteVideoByVodDetailid',
        {
          vodDetailId: this.localSelectedVodBaseClass.VodDetail.Id,
        },
      )
        .then((response) => {
          if (response.data.success === 'true') {
            this.$root.$emit('show-add-video-section-byEntity', true);
          }
        });
    } else {
      this.$root.$emit('show-add-video-section-byEntity', true);
    }
  }
  // PlayVideo show video player
  private PlayVideo() {
    this.videoPlayerVisible = true;
  }
  // Close Player hide video player
  private ClosePlayer() {
    this.videoPlayerVisible = false;
  }
  // Get duration of video
  private GetDuration() {
    if (
      this.localSelectedVodBaseClass.VodDetail !== null &&
      this.localSelectedVodBaseClass.VodDetail !== undefined &&
      this.localSelectedVodBaseClass.VodDetail.VodVideoDetail !== null &&
      this.localSelectedVodBaseClass.VodDetail.VodVideoDetail !== undefined
    ) {
      this.videoDurationNumber =
        this.localSelectedVodBaseClass.VodDetail.VodVideoDetail.Duration!;
      this.videoDuration = this.showVideoDuration(this.videoDurationNumber);
    }
  }

  // Get PosterUrl of video
  private GetPosterUrl() {
    // Set appropriate image corresponding to status of video upload.
    if (
      this.localSelectedVodBaseClass.VodDetail !== null &&
      this.localSelectedVodBaseClass.VodDetail !== undefined
    ) {
      if (
        this.localSelectedVodBaseClass.VodDetail.VODLatestStatus ===
        'VideoUploadCreated'
      ) {
        return this.uploadingImage;
      } else if (
        this.localSelectedVodBaseClass.VodDetail.VODLatestStatus ===
        'VideoAssetCreated'
      ) {
        this.isVideoToBePlayed = true;
        return this.processingImage;
      } else if (
        this.localSelectedVodBaseClass.VodDetail.VODLatestStatus ===
        'VideoAssetErrored'
      ) {
        return this.ErrorImage;
      } else if (
        this.localSelectedVodBaseClass.VodDetail.VODLatestStatus ===
        'VideoAssetDeleted'
      ) {
        this.isVideoToBePlayed = true;
        return this.DeletedImage;
      } else {
        if (
          this.localSelectedVodBaseClass.SubModuleType ===
            this.virtualMeetingEnum.MeetAppLive &&
          this.localSelectedVodBaseClass.VodDetail.IsDeleted === true
        ) {
          this.isVideoToBePlayed = true;
          return this.DeletedImage;
        } else if (
          this.localSelectedVodBaseClass.VodDetail.VodVideoDetail !== null &&
          this.localSelectedVodBaseClass.VodDetail.VodVideoDetail !== undefined
        ) {
          return this.localSelectedVodBaseClass.VodDetail.VodVideoDetail
            .PosterUrl;
        } else {
          return this.noThumbnailImage;
        }
      }
    } else {
      return this.missingImage;
    }
  }

  // Delete Video Click
  private DeleteVideoClick() {
    // Show warning on video upload clicked.
    if (
      this.localSelectedVodBaseClass.VodDetail !== null &&
      this.localSelectedVodBaseClass.VodDetail !== undefined
    ) {
      if (
        this.localSelectedVodBaseClass.VodDetail.VodStreamingType ===
          VodTypeEnums.Mux &&
        this.localSelectedVodBaseClass.SubModuleType ===
          this.virtualMeetingEnum.MeetAppLive
      ) {
        this.showSectionModalPopup = this.VonageMUXDeleteVideo;
      } else if (
        this.localSelectedVodBaseClass.VodDetail.VodStreamingType ===
        VodTypeEnums.Mux
      ) {
        this.showSectionModalPopup = this.VodDeleteVideo;
      } else if (
        this.localSelectedVodBaseClass.VodDetail.VodStreamingType ===
        VodTypeEnums.RTMP
      ) {
        this.showSectionModalPopup = this.RtmpDeleteVideo;
      }
    }
    this.modalPopupContent = this.$t(
      'VirtualMeetings.AddVideo.DeleteVideoWarning',
    ).toString();
    this.modalPopupVisible = true;
  }
  // Delete Video api hit
  private DeleteVideo(
    detailApplicationInstanceId: number,
    detailId: number,
    selectedEntityId: number,
  ) {
    const signalObject = this.SendNotificationRequest();
    // Delete video API call and set path according to module type
    this.$axios
      .post<any>(
      '/' + this.localSelectedVodBaseClass.Moduletype + '/DeleteVideo',
      {
        vodDetailId: detailId,
        vodDetailApplicationInstanceId: detailApplicationInstanceId,
        entityId: selectedEntityId,
        entityType: this.localSelectedVodBaseClass.Moduletype,
      },
    )
      .then((response) => {
        if (response.data.success === 'true') {
          this.localSelectedVodBaseClass.VodDetail = VodDetail.createEmpty();
          this.$root.$emit('reload-VodDetail-delete');
          this.$root.$emit('reload-VodDetail-delete-program');
          this.isVideoDetailsVisible = false;
          this.SendNotificationRequestToQueue(signalObject);
        }
      });
  }
  // DeleteStreamVideo
  private DeleteStreamVideo(
    selectedEntityId: number,
    detailId: number,
    StreamType: string,
  ) {
    let signalObject: SignalRModelRequest;
    // delete rtmp and vonageMUX (Ventla live)
    if(this.localSelectedVodBaseClass.Moduletype !== 'Entity') {
      signalObject = this.SendNotificationRequest();
    }
    // Delete video API call
    this.$axios
      .post<any>(
      '/' +
            (this.localSelectedVodBaseClass.Moduletype === 'Entity' ? 'ApplicationInstance' : this.localSelectedVodBaseClass.Moduletype) +
          '/DeleteLiveVideoStream',
      {
        programId: selectedEntityId,
        vodDetailId: detailId,
        streamType: StreamType,
      },
    )
      .then((response) => {
        if (response.data.status === true) {
          if (StreamType === 'VonageMUX') {
            this.$root.$emit('reload-program-detail');
            this.$root.$emit('reload-watchlivedelete-detail',response.data.status);
            this.isVideoDetailsVisible = true;
            this.localSelectedVodBaseClass.VodDetail.VODLatestStatus = 'VideoAssetDeleted';
            this.latestVideoStatus = 'VideoAssetDeleted';
          } else {
            this.localSelectedVodBaseClass.VodDetail = VodDetail.createEmpty();
            this.$root.$emit('reload-program-detail');
            this.$root.$emit('reload-VodDetail-delete-program');
            this.isVideoDetailsVisible = true;
            this.$root.$emit(
              'virtual-meeting-meetapp-live-analytics-available',
              true,
            );
            //  this.$root.$emit('set-rtmp-to-Active', true);
          }
          if(this.localSelectedVodBaseClass.Moduletype !== 'Entity') {
            this.SendNotificationRequestToQueue(signalObject);
          }
        }
      });
  }

  // #endregion

  // #region SignalR

  // SendNotificationRequest
  private SendNotificationRequest(): SignalRModelRequest {
    let signalR: any;
    // eslint-disable-next-line prefer-const
    signalR = this.GetSignalRObjectByEntityType(
      this.localSelectedVodBaseClass.Moduletype,
      signalR,
    );

    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    if (
      this.localSelectedVodBaseClass.Moduletype ===
      VodModuleTypeEnum[1].toString()
    ) {
      signalObject = signalR.SingleVodVideoDeleteSignalRModelRequest;
    } else {
      signalObject = signalR.SingleVodVideoDeleteSignalRModelRequest;
    }

    signalObject = signalR.SingleVodVideoDeleteSignalRModelRequest;
    const arr: number[] = [];
    arr.push(this.localSelectedVodBaseClass.EntityId);
    signalObject.SelectedIds = arr;
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      selectedCount: arr.length, // Just single item delete
      user: signalR.GetUserName(
        signalObject,
        this.$t('UserName.You').toString(),
      ),
    }).toString();
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({
      selectedName: this.localSelectedVodBaseClass.Title,
    });
    return signalObject;
  }
  // Get SignalR Object By EntityType
  private GetSignalRObjectByEntityType(entityType: string, signalR: any) {
    switch (entityType) {
    case VodModuleTypeEnum[1].toString():
      signalR = new CustomCategorySignalRModel(
        this.$root,
        this.$axios,
        this.$store,
        this.$t,
      );
      break;
    case VodModuleTypeEnum[0].toString():
      signalR = new ProgramSignalRModel(
        this.$root,
        this.$axios,
        this.$store,
        this.$t,
      );
      break;
    }
    return signalR;
  }
  // Send Notification Request To Queue
  private SendNotificationRequestToQueue(signalObject: SignalRModelRequest) {
    let signalR: any;
    // eslint-disable-next-line prefer-const
    signalR = this.GetSignalRObjectByEntityType(
      this.localSelectedVodBaseClass.Moduletype,
      signalR,
    );
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(this.localSelectedVodBaseClass.EntityId);
      signalObject.SelectedIds = arr;
    }
    switch (this.localSelectedVodBaseClass.Moduletype) {
    case VodModuleTypeEnum[1].toString():
      signalR.PerformCustomCategorySingleOperation(signalObject);
      break;
    case VodModuleTypeEnum[0].toString():
      signalR.PerformProgramSingleOperation(signalObject);
      break;
    }
  }

  // #endregion

  // #region Analytics

  // see DetailedAnalytics
  private seeDetailedAnalytics() {
    this.$root.$emit('show-pre-recorded-video-analytics-section', true);
  }

  // Get ViewsCount of video
  private GetViewsCount() {
    if (
      this.localSelectedVodBaseClass !== null &&
      this.localSelectedVodBaseClass !== undefined &&
      this.localSelectedVodBaseClass.VodDetail !== undefined &&
      this.localSelectedVodBaseClass.VodDetail !== null &&
      this.localSelectedVodBaseClass.VodDetail.VodVideoAnalytics !==
        undefined &&
      this.localSelectedVodBaseClass.VodDetail.VodVideoAnalytics !== null &&
      this.localSelectedVodBaseClass.VodDetail.VodVideoAnalytics
        .TotalTimesWatched !== undefined &&
      this.localSelectedVodBaseClass.VodDetail.VodVideoAnalytics
        .TotalTimesWatched !== null
    ) {
      this.localViewCount =
        this.localSelectedVodBaseClass.VodDetail.VodVideoAnalytics.TotalTimesRecordingWatched!;
    } else {
      this.localViewCount = 0;
    }
  }
  // #endregion

  // #region HTML

  // get Information String value
  private getInformationString(val: boolean) {
    this.isActive = val;
  }

  // show loader
  private showLoader(): boolean {
    if (this.localLinkedProgramLoading && this.moduleType !== 'Program' && this.moduleType !== 'Entity') {
      return true;
    }
    return false;
  }

  // #endregion

  // ConfirmClick from model popup for delete video
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      // check type of video / submodule
      if (this.showSectionModalPopup === this.VodDeleteVideo) {
        this.DeleteVideo(
          this.localSelectedVodBaseClass.VodDetail.VodDetailApplicationInstance!
            .Id,
          this.localSelectedVodBaseClass.VodDetail.Id,
          this.localSelectedVodBaseClass.EntityId,
        );
      } else if (this.showSectionModalPopup === this.RtmpDeleteVideo) {
        this.DeleteStreamVideo(
          this.localSelectedVodBaseClass.EntityId,
          this.localSelectedVodBaseClass.VodDetail.Id,
          'RTMP',
        );
      } else if (this.showSectionModalPopup === this.VonageMUXDeleteVideo) {
        this.DeleteStreamVideo(
          this.localSelectedVodBaseClass.EntityId,
          this.localSelectedVodBaseClass.VodDetail.Id,
          'VonageMUX',
        );
      } else {
        return;
      }
    }
  }

  // #region Watcher

  // Watch localSelectedVodBaseClass change
  @Watch('localSelectedVodBaseClass')
  private onlocalSelectedVodBaseClassChange(val: VodBaseRenderModel) {
    if(val.Moduletype === VodModuleTypeEnum[VodModuleTypeEnum.Program].toString()) {
      if (val.VodDetail !==  undefined &&  val.VodDetail.Id > 0) {
        this.isVideoDetailsVisible = true;
      } else {
        this.isVideoDetailsVisible = false;
      }
    }
  }

  // Watch selectedVodBaseClass change
  @Watch('SelectedVodBaseClasss')
  private onSelectedVodBaseClassChange(val: VodBaseRenderModel,newVal: VodBaseRenderModel) {
    this.SelectedVodBaseClasss!.VodDetail = newVal.VodDetail;
    if (val.VodDetail !== undefined) {
      // set changed object in local object
      this.localSelectedVodBaseClass = val;
    }
    // set module type in local var for show sections according to type
    this.moduleType = this.SelectedVodBaseClasss!.Moduletype;
  }
  // #endregion
}
