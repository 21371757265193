















































































































































































































































































































































































































































































































































































import {
  VodVideoTextDetail,
  VodDetail,
  VodSignalRStatus,
  VodVideoDetail,
  VodDetailApplicationInstance,
} from '@/entities/VodDetail';
import * as UpChunk from '@mux/upchunk';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  Language,
  VirtualMeetingCaptionLanguage,
} from '@/entities/VirtualMeetingCaptionLanguage';
import Datepicker from '@/components/Common/Datepicker.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import VideoModalPopup from '@/components/Common/VideoModalPopup.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { ConsumptionBasedProductPrice } from '@/entities/ConsumptionBasedProductPrice';
import moment from 'moment';
import VodTypeEnums from '@/enums/VodTypeEnums';
import VirtualMeetingtypeParentEnum from '@/entities/VirtualMeetingType';
import VideoScheduleEnums from '@/enums/VideoScheduleEnums';
import VirtualMeetingType from '@/entities/VirtualMeetingType';
import { VodBaseRenderModel } from '@/entities/FactoryPattern/Vod/VodBaseRenderModel';
import VodModuleTypeEnum from '@/enums/VodModuleTypeEnum';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import MeetappPricingDetails from '@/components/Application/MeetappPricingDetails.vue';
import { ProductPrice } from '@/entities/ProductPrice';
import Common from '@/helper/Common';

@Component({
  components: {
    Datepicker,
    VideoModalPopup,
    MeetappPricingDetails,
  },
})
/* eslint-disable @typescript-eslint/no-unused-expressions */
export default class MuxVideo extends Vue {
  // #region variables

  @Prop() private SelectedVodBase!: VodBaseRenderModel;

  private localVodDetails: VodDetail = VodDetail.createEmpty();
  private muxLocation = '';
  private uploadId = '';
  private percentageUploaded = 0;
  private isUploadingInProgress = false;
  private isVodStatusProcess = false;
  private localVirtualMeetingLanguageTypes: Language[] = [];
  private uploadImage = 'file';
  private Iconfilename = '';
  private localCaptions: VodVideoTextDetail[] = [];
  private showCaptionsButton = false;
  private showCaptionsCancelButton = false;
  private captionEditPaneVisible = false;
  private fileExtensions = '.srt, .vtt';
  private isEditPaneVisible = false;
  private errorText = '';
  private videoFileTypeErrorText = '';
  private isIEbrowser = false;
  private uploadingThread: any = '';
  private fileUploadUrl = '/FileUpload/UploadCaptionAndSubtitleFile';
  private selectedLanguage: Language = Language.createEmpty();
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private VodClearCaption = 'VodClearCaption';
  private VodAssetErrored = 'VodAssetErrored';
  private VosAssetCancelUploading = 'VosAssetCancelUploading';
  private captionObjectForDelete?: VodVideoTextDetail =
    VodVideoTextDetail.createEmpty();
  private captionObjectForEdit?: VodVideoTextDetail =
    VodVideoTextDetail.createEmpty();
  private VodStatus = '';
  private progressBarId = '';
  private isProgressBarStriped = false;
  private isCompleted = false;
  private currentWebHookEvent?: VodSignalRStatus | null;
  private isVideoready = false;
  private recentlyAddedTrackId = 0;
  private ShowCaptionSuccess = false;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private isScheduleDateOrPublishChanged = false;
  private isCancelEnabled = true;
  private isExecuted = false;
  private chunkSize = 10240;

  private modalPopupVisible = false;
  private modalPopupMessageIconType = '';
  private showCloseButton = true;
  private modalPopupHeader = '';
  private modalPopupBodyHeading = '';
  private modalPopupContent = '';
  private showSectionModalPopup = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private storageAvailabilitydatetime?: Date;
  private sessionStartTime: Date = new Date();
  private publishDatedatetime: Date = new Date();
  private fifteenMinutesBeforeSessionTime: Date = new Date();
  private storagePricingInfo = false;
  private selectedPublishOption: number = VideoScheduleEnums.Now;
  private isVideoUnavailableInfo = false;
  private updateStoragePricing = '';
  private savedMessageFadeoutTime = 1500;
  private saveCancelButtonsShow = false;
  private preSelectedOption: number = this.selectedPublishOption;
  private prePublishedDate: any;
  private preScheduledDate: any;
  private isNotFirstcall = false;
  private isNotFirstCallPublished = false;
  private isDatesSaved = false;
  private isSaved = true;
  private LoginUsername = '';
  private isStorageAvailabilityDateError = false;
  private isPublishedDateError = false;
  private isSavedClicked = false;
  private isCaptionMsgShow = false;
  private maxAllowedFileSize = 104857600; // 100 MB
  private Step1Heading: string = this.$t(
    'VirtualMeetings.AddVideo.SelectFile',
  ).toString();
  private isDateOnly = false;
  private unPublishedOption: number = VideoScheduleEnums.UnPublished;
  private videoUploadingStatus = '';
  private storageTillDefaultDays = 30;
  private showVodPricingDetailsPopup = false;
  private vodLivePricingInformation: ProductPrice = ProductPrice.createEmpty();
  private pricingPopupType: number = VodTypeEnums.Mux;
  private warningIcon: string = require('@/assets/Images/Icon-warning.svg');
  private videoMinutesOfDurationNotCharged = 10;
  private IsDisablePricingPopUp = false;
  private IsVideoGreaterThanTenMins = false;
  private MailToText = 'mailto:sales@ventla.io';

  private localSelectedVodBase: VodBaseRenderModel =
    VodBaseRenderModel.createEmpty();
  private vodLanguages: VirtualMeetingCaptionLanguage =
    new VirtualMeetingCaptionLanguage(this.$root, this.$store, this.$t);
  private availableLanguages: VirtualMeetingCaptionLanguage =
    new VirtualMeetingCaptionLanguage(this.$root, this.$store, this.$t);
  public get virtualMeetingEnum(): typeof VirtualMeetingtypeParentEnum {
    return VirtualMeetingtypeParentEnum;
  }

  // #endregion

  private created() {
    // set selected vod base object in local object
    this.localSelectedVodBase = this.SelectedVodBase;
    if (
      this.localSelectedVodBase.VodDetail &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
        .PublishOption
    ) {
      // check video is shared or not and set value in variable for show message on screen(Video is shared)
      this.isCaptionMsgShow =
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.IsSharedVodVideo;
      // set publishoption in local var ;
      this.selectedPublishOption =
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishOption;
    }
    this.resetSelectedCaptionType();
    if (
      this.localSelectedVodBase.VodDetail !== null &&
      this.localSelectedVodBase.VodDetail !== undefined
    ) {
      if (this.localSelectedVodBase.VodDetail.Id > 0) {
        // set video details  in local var and set progressbar and percentage to 100%
        this.localVodDetails = this.localSelectedVodBase.VodDetail!;
        this.isUploadingInProgress = true;
        this.percentageUploaded = 100;
      }
    }
    this.GetScrollBarId();
    this.Iconfilename = this.$t(
      'VirtualMeetings.AddVideo.ChooseFile',
    ).toString();
  }
  private mounted() {
    // set selected vod base object in local object
    this.localSelectedVodBase = this.SelectedVodBase;
    if (
      this.localSelectedVodBase.SubModuleType ===
        this.virtualMeetingEnum.ProfessionalStreaming ||
      this.localSelectedVodBase.SubModuleType ===
        this.virtualMeetingEnum.MeetAppLive
    ) {
      // isDateOnly is used for show dates only inn datePicker
      this.isDateOnly = true;
    }
    if (
      this.localSelectedVodBase.VodDetail &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
        .PublishOption &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
        .PublishOption !== undefined
    ) {
      // save preselected data and use when we press cancel from scheduling date buttons
      this.preSelectedOption =
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishOption;
      this.localVodDetails.VodDetailApplicationInstance!.PublishOption =
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishOption;
      this.prePublishedDate =
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishDate;
      this.preScheduledDate =
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.StorageTillDate;
      this.isNotFirstcall = true;
      this.isNotFirstCallPublished = true;
    } else if (
      this.localSelectedVodBase.VodDetail == null ||
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance ==
        null ||
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
        .PublishOption === null ||
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
        .PublishOption === 0 ||
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
        .PublishOption === undefined
    ) {
      // set by default options
      this.selectedPublishOption = VideoScheduleEnums.FifteenMinPriorToSession;
      this.isScheduleDateOrPublishChanged = false;
      this.selectOption(false);
    } else if (
      this.localSelectedVodBase.VodDetail == null ||
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance ==
        null ||
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
        .PublishOption === null ||
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
        .PublishOption === VideoScheduleEnums.UnPublished
    ) {
      // set by default options for RTMP and ventla live
      this.selectedPublishOption = VideoScheduleEnums.UnPublished;
      this.isScheduleDateOrPublishChanged = false;
      this.isNotFirstcall = true;
      this.selectOption(false);
    }
    if (
      !this.isUploadingInProgress &&
      this.localSelectedVodBase.Moduletype === VodModuleTypeEnum[1].toString()
    ) {
      this.selectedPublishOption = this.localSelectedVodBase.DefaultValue;
    }
    // check type of stream and set state of progress bar
    if (
      this.localSelectedVodBase.VodDetail !== null &&
      this.localSelectedVodBase.VodDetail !== undefined
    ) {
      if (
        this.localSelectedVodBase.VodDetail.VODLatestStatus ===
          'VideoAssetReady' ||
        this.localSelectedVodBase.VodDetail.VODLatestStatus ===
          'VideoAssetLiveStreamCompleted'
      ) {
        if (
          this.localSelectedVodBase.VodDetail.VodStreamingType ===
            VodTypeEnums.RTMP ||
          this.localSelectedVodBase.SubModuleType ===
            this.virtualMeetingEnum.MeetAppLive
        ) {
          this.Step1Heading = this.$t(
            'VirtualMeetings.AddVideo.Recording',
          ).toString();
        }
        // if video asset ready set progress bar to 100%
        this.ProgressBarComplete();
      } else if (
        this.localSelectedVodBase.VodDetail.VODLatestStatus ===
        'VideoAssetErrored'
      ) {
        this.isUploadingInProgress = false;
      } else if (this.localSelectedVodBase.VodDetail.VODLatestStatus !== '') {
        this.UploadingStatus(this.localVodDetails.VODLatestStatus);
      } else {
        this.isUploadingInProgress = false;
      }
      if (
        this.selectedPublishOption === VideoScheduleEnums.UnPublished &&
        this.localSelectedVodBase.SubModuleType ===
          this.virtualMeetingEnum.PreRecordedVideo
      ) {
        this.selectedPublishOption =
          VideoScheduleEnums.FifteenMinPriorToSession;
      }
    }
    if (
      this.localSelectedVodBase.VodDetail !== null &&
      this.localSelectedVodBase.VodDetail !== undefined &&
      this.localSelectedVodBase.VodDetail.Id > 0
    ) {
      if (
        this.localSelectedVodBase.VodDetail.VodVideoTextDetails !== null &&
        this.localSelectedVodBase.VodDetail.VodVideoTextDetails !== undefined
      ) {
        if (
          this.localSelectedVodBase.VodDetail.VodVideoTextDetails.length === 0
        ) {
          this.isEditPaneVisible = true;
        }
        this.localCaptions =
          this.localSelectedVodBase.VodDetail.VodVideoTextDetails;
        this.localCaptions.forEach((item) => {
          if (item.IsDeleted === false) {
            this.RemoveCaptionOption(item.LanguageCode);
          }
        });
      } else {
        this.isEditPaneVisible = true;
      }
    } else {
      this.isEditPaneVisible = true;
      // this.UploadingStatus(this.selectedItem!.VodDetail!.VODLatestStatus);
    }
    this.$root.$on(
      'entity-item-video-upload-current-status',
      (vodVideoStatus: VodSignalRStatus) => {
        this.currentWebHookEvent = vodVideoStatus;
        if (
          vodVideoStatus.VodStatus === 'VideoUploadCreated' ||
          vodVideoStatus.VodStatus === 'VideoAssetCreated' ||
          vodVideoStatus.VodStatus === 'VideoUploadAssetCreated' ||
          vodVideoStatus.VodStatus === 'VideoAssetReady' ||
          vodVideoStatus.VodStatus === 'VideoAssetErrored'
        ) {
          this.localSelectedVodBase.VodDetail.VODLatestStatus =
            vodVideoStatus.VodStatus;
        }
        this.UploadingStatus(vodVideoStatus.VodStatus);
        this.saveCancelButtonsShow = false;
        this.saveCancelButtonsDisplay(false);
      },
    );
    this.$root.$on(
      'voddetail-updated',
      (selectedVodBase: VodBaseRenderModel) => {
        this.localSelectedVodBase = selectedVodBase;
      },
    );

    Common.ScrollToTop('dvFileupload');
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    this.LoginUsername = reqInfo.Username;
    if (this.localCaptions.length > 0) {
      this.showCaptionsButton = true;
      this.isCaptionsAddMoreButtonVisible();
    }
    setTimeout(() => {
      this.SetBackGroundColor(true);
    }, 100);

    this.$root.$on('show-meetapp-live-pricing-popup', (val: boolean) => {
      this.showVodPricingDetailsPopup = val;
    });
    this.showVodStreamingCostMessage();

    this.$root.$on(
      'selected-program-video-upload-progress',
      (notification: SignalRModelRequest) => {
        if (notification.VodDetail !== null && notification.VodDetail !== undefined &&  notification.VodDetail.VodVideo !== null && notification.VodDetail.VodVideo !== undefined) {
          const sec = parseFloat(notification.VodDetail.VodVideo.Duration);
          const mins = sec / 60;
          if (mins > 10) {
            this.IsVideoGreaterThanTenMins = true;
          }
        }
      },
    );
    this.$root.$on(
      'entity-item-video-upload-current-status-api',
      (vodDetail: VodDetail) => {
        if (vodDetail.VODLatestStatus === 'VideoAssetReady') {
          this.localSelectedVodBase.VodDetail = vodDetail;
        }
      },
    );
  }

  // #region HTML

  // Set BackGround Color
  private SetBackGroundColor(val: boolean) {
    const el = this.$el.getElementsByClassName('file-custom')[0];
    if (el !== null && el !== undefined) {
      if (val && !this.isVideoready) {
        el.classList.add('grey-Background');
      } else if (!val) {
        el.classList.remove('grey-Background');
      }
    }
  }
  // Check is Publish DropDown Visible
  private isDropDownVisible() {
    // show or hide publish option drop down when we have meetApp live or rtmp
    if (
      this.selectedPublishOption === VideoScheduleEnums.UnPublished &&
      (this.localSelectedVodBase.SubModuleType ===
        this.virtualMeetingEnum.ProfessionalStreaming ||
        this.localSelectedVodBase.SubModuleType ===
          this.virtualMeetingEnum.MeetAppLive)
    ) {
      return false;
    }
    return true;
  }
  // ShowSecondStep
  private ShowSecondStep() {
    // show and hide second step (caption) when selected meeting option is MeetAppLive and rtmp
    if (
      this.localSelectedVodBase.VodDetail !== null &&
      this.localSelectedVodBase.VodDetail !== undefined
    ) {
      if (
        (this.localSelectedVodBase.VodDetail.VODLatestStatus ===
          'VideoAssetReady' &&
          (this.localSelectedVodBase.SubModuleType ===
            this.virtualMeetingEnum.ProfessionalStreaming ||
            this.localSelectedVodBase.SubModuleType ===
              this.virtualMeetingEnum.MeetAppLive)) ||
        this.localSelectedVodBase.VodDetail.VODLatestStatus ===
          'VideoAssetLiveStreamCompleted'
      ) {
        if (
          this.localSelectedVodBase.VodDetail.VodStreamingType ===
            VodTypeEnums.RTMP ||
          this.localSelectedVodBase.SubModuleType ===
            this.virtualMeetingEnum.MeetAppLive
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
    return true;
  }

  // Set PublishDateDisable
  private SetPublishDateDisable() {
    // publish date diable when selected meeting option is meetApp live or rtmp
    if (!this.isVideoready) {
      return true;
    } else if (
      (this.selectedPublishOption === VideoScheduleEnums.UnPublished &&
        this.localSelectedVodBase.SubModuleType ===
          this.virtualMeetingEnum.ProfessionalStreaming) ||
      this.localSelectedVodBase.SubModuleType ===
        this.virtualMeetingEnum.MeetAppLive
    ) {
      return true;
    }
    return false;
  }

  // RenameStep
  private RenameStep() {
    // rename step 3 to step 2 when selected meeting option is meetApp live or rtmp
    if (
      (this.selectedPublishOption === VideoScheduleEnums.UnPublished &&
        this.localSelectedVodBase.SubModuleType ===
          this.virtualMeetingEnum.ProfessionalStreaming) ||
      this.localSelectedVodBase.SubModuleType ===
        this.virtualMeetingEnum.MeetAppLive
    ) {
      return this.$t('VirtualMeetings.AddVideo.Step2').toString();
    }
    return this.$t('VirtualMeetings.AddVideo.Step3').toString();
  }
  // Save Cancel Buttons Display
  private saveCancelButtonsDisplay(isModified: boolean) {
    this.saveCancelButtonsShow = isModified;
  }

  // #endregion

  // #region Upload video

  private UploadVideoButtonClick(): void {
    const picker = document.getElementById('picker') as HTMLInputElement;
    if (picker !== null) {
      picker.value = '';
    }
    picker.click();
  }
  private getUploadUrl() {
    // to Get mux Url where video will
    this.$emit('vodsaveupdate');
    const picker = document.getElementById('picker') as HTMLInputElement;
    const reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
    const isTrialPackage: boolean = reqInfo.IsCurrentPackageTrial;
    const file = picker.files![0];
    if (
      !file.name
        .toLowerCase()
        .match(this.localSelectedVodBase.AcceptedVideoFileTypes)
    ) {
      this.videoFileTypeErrorText = this.$t(
        'VirtualMeetings.AddVideo.InputFileValidationError',
      ).toString();
      return;
    } else if (isTrialPackage && file.size > this.maxAllowedFileSize) {
      this.videoFileTypeErrorText = this.$t(
        'VirtualMeetings.AddVideo.InputFileValidationErrorSize',
      ).toString();
      return;
    } else {
      this.videoFileTypeErrorText = '';
    }
    this.$axios
      .post<any>(this.localSelectedVodBase.UploadUrl, {
      entityId: this.localSelectedVodBase.EntityId.toString(),
      entityType: this.localSelectedVodBase.Moduletype,
      timeOffset: new Date().getTimezoneOffset(),
    })
      .then((response) => {
        this.muxLocation = response.data.resultData.Url;
        this.uploadId = response.data.resultData.Id;
        this.localVodDetails.Id = response.data.entityId;
        if (
          this.localSelectedVodBase.VodDetail !== null &&
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
            null
        ) {
          setTimeout(() => {
            this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.PublishDate =
              response.data.publishDate;
            this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.PublishUTCDate =
              response.data.publishUtcDate;
            this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.StorageTillDate =
              response.data.storageTillDate;
            this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.StorageTillUTCDate =
              response.data.storageTillUtcDate;
          }, 1500);
        } else {
          if (this.localVodDetails.VodDetailApplicationInstance === undefined) {
            this.localVodDetails.VodDetailApplicationInstance =
              new VodDetailApplicationInstance();
            this.localVodDetails.VodDetailApplicationInstance.PublishDate =
              response.data.publishDate;
            this.localVodDetails.VodDetailApplicationInstance.PublishUTCDate =
              response.data.publishUtcDate;
            this.localVodDetails.VodDetailApplicationInstance.StorageTillDate =
              response.data.storageTillDate;
            this.localVodDetails.VodDetailApplicationInstance.StorageTillUTCDate =
              response.data.storageTillUtcDate;
          }
        }
        this.SetVodDetailID(this.localVodDetails);
        this.FileUpload();
      })
      .catch(() => {
        alert('Error !!');
      });
  }
  // SetVodDetailID after upload and save into the DB
  private SetVodDetailID(vodDetail: VodDetail) {
    this.$emit('setVodDetails', vodDetail);
  }
  private FileUpload() {
    /* Set Vod status to VideoUploadCreated even if we haven't received this hook yet
    this will set the Status to Uploading*/
    setTimeout(() => {
      this.isUploadingInProgress = true;
      this.localSelectedVodBase.VodDetail.VODLatestStatus =
        'VideoUploadCreated';
      this.$root.$emit('program-item-video-upload-inprogress', true);
    }, 100);
    const signalObject = this.SendNotificationRequest(
      this.localSelectedVodBase.Moduletype,
    );
    // Get the file from picker
    const picker: HTMLInputElement = document.getElementById(
      'picker',
    ) as HTMLInputElement;
    const endpoint = this.muxLocation;
    const file = picker.files![0];
    this.SendNotificationRequestToQueue(signalObject);
    // Start uphunk process of Video uploading to Mux system using UpChunk
    const upload = UpChunk.createUpload({
      endpoint,
      file,
      chunkSize: this.chunkSize,
    });
    this.$root.$emit('freeze-screen', true, this.LoginUsername);
    this.IsDisablePricingPopUp = true;
    this.isCancelEnabled = false;
    this.VodStatus = this.$t(
      'VirtualMeetings.AddVideo.VideoStatusUploading',
    ).toString();
    this.uploadingThread = upload;
    // subscribe to events
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    upload.on('error', (err: any) => {
      this.showSectionModalPopup = this.VodAssetErrored;
      this.modalPopupMessageIconType = 'error';
      this.modalPopupBodyHeading = this.$t(
        'UnexpectedErrorOccured',
      ).toString();
      this.modalPopupContent = this.$t(
        'VirtualMeetings.AddVideo.UnexpectedErrorDescription',
      ).toString();

      this.modalPopupConfirmButtonText = this.$t(
        'VirtualMeetings.AddVideo.InputFileValidationErrorConfirmMsg',
      ).toString();

      this.modalPopupCancelButtonText = this.$t(
        'VirtualMeetings.AddVideo.InputFileValidationErrorDismiss',
      ).toString();
      this.modalPopupVisible = true;
    });
    upload.on('progress', ({ detail: progress }) => {
      this.$root.$emit('freeze-screen', true, this.LoginUsername);
      this.IsDisablePricingPopUp = true;
      this.isCancelEnabled = false;
      this.$root.$emit('program-vod-video-uploading-user-is-me', true);
      const currentPercentage = Math.round(Number(progress));
      this.setPercentageInProgressBar(currentPercentage);
      // Set Progress in Progress bar
      const progressBar: HTMLElement = document.getElementById(
        this.progressBarId,
      ) as HTMLElement;
      if (progressBar !== null) {
        progressBar.setAttribute('style', 'width:' + currentPercentage + '%');
      }
      const progressBarPercentage: HTMLElement = document.getElementById(
        this.progressBarId + '_Percent',
      ) as HTMLElement;
      if (progressBarPercentage !== null) {
        if (currentPercentage <= 99) {
          progressBarPercentage.innerHTML = currentPercentage.toString() + '%';
        } else {
          setTimeout(() => {
            progressBarPercentage.innerHTML = 99 + '%';
          }, 500);
        }
      }
    });
    upload.on('attempt', ({ detail }) => {
      window.console.log('Chunk uploading started!', detail);
    });

    upload.on('success', () => {
      window.console.log('Success');
    });
    upload.on('chunkSuccess', () => {
      window.console.log('Chunk uploaded!');
    });
  }
  // Uploading Status on video
  private UploadingStatus(str: string) {
    // show uploading status on the progress bar
    window.console.log(str);
    this.videoUploadingStatus = str;
    if (str === 'VideoUploadCreated') {
      // this.$root.$emit('freeze-screen', true, this.LoginUsername);
      // this.isCancelEnabled = false;
      this.VodStatus = this.$t(
        'VirtualMeetings.AddVideo.VideoStatusUploading',
      ).toString();
      this.isVodStatusProcess = false;
    } else if (str === 'VideoAssetCreated' && !this.isCompleted) {
      this.progressBarHundreadPercent();
      setTimeout(() => {
        this.isProgressBarStriped = true;
        this.$root.$emit('freeze-screen', false, this.LoginUsername);
        this.IsDisablePricingPopUp = false;
        this.isCancelEnabled = true;
        this.VodStatus = this.$t(
          'VirtualMeetings.AddVideo.VideoStatusProcessing',
        ).toString();
        this.Processing();
        this.isVodStatusProcess = true;
        this.isNotFirstcall = true;
        this.isNotFirstCallPublished = true;
      }, 1000);
    } else if (str === 'VideoAssetReady') {
      if (this.currentWebHookEvent!.Id === this.localVodDetails.Id) {
        // this.progressBarHundreadPercent();
        this.$root.$emit('freeze-screen', false, this.LoginUsername);
        this.IsDisablePricingPopUp = false;
        this.isCancelEnabled = true;
        this.isProgressBarStriped = false;
        this.isCompleted = true;
        this.isVodStatusProcess = false;
        this.VodStatus = this.$t(
          'VirtualMeetings.AddVideo.VideoStatusUploaded',
        ).toString();
        this.localSelectedVodBase.VodDetail.VODLatestStatus = 'VideoAssetReady';
        this.ProgressComplete();
        this.SetBackGroundColor(false);
        this.isScheduleDateOrPublishChanged = true;
        this.$root.$emit('program-item-video-upload-inprogress', false);
      }
    } else if (str === 'VideoAssetErrored') {
      // show popup when error occuered while uploading
      this.isVodStatusProcess = false;
      this.showSectionModalPopup = this.VodAssetErrored;
      this.modalPopupMessageIconType = 'error';
      this.modalPopupBodyHeading = this.$t(
        'VirtualMeetings.AddVideo.InputFileValidationErrorHeader',
      ).toString();
      this.modalPopupContent = this.$t(
        'VirtualMeetings.AddVideo.InputFileValidationError',
      ).toString();

      this.modalPopupConfirmButtonText = this.$t(
        'VirtualMeetings.AddVideo.InputFileValidationErrorConfirmMsg',
      ).toString();
      this.modalPopupCancelButtonText = this.$t(
        'VirtualMeetings.AddVideo.InputFileValidationErrorDismiss',
      ).toString();
      this.modalPopupVisible = true;
    } else if (str === 'VideoAssetTrackReady') {
      this.ShowCaptionSuccess = true;
      this.showCaptionsButton = true;
      this.isCaptionsAddMoreButtonVisible();
      this.showCaptionsCancelButton = false;
      setTimeout(() => {
        this.ShowCaptionSuccess = false;
        this.recentlyAddedTrackId = 0;
      }, this.savedMessageFadeoutTime);
    } else if (str === 'VideoAssetLiveStreamCompleted') {
      this.ShowCaptionSuccess = true;
      this.showCaptionsButton = true;
      this.isCaptionsAddMoreButtonVisible();
      this.showCaptionsCancelButton = false;
      setTimeout(() => {
        this.ShowCaptionSuccess = false;
        this.recentlyAddedTrackId = 0;
      }, this.savedMessageFadeoutTime);
    }
  }

  // #endregion

  // #region Caption block

  // on CaptionFileSelected for CC
  private onCaptionFileSelected(fileName: string, file: any) {
    this.$emit('vodsaveupdate');
    if (this.selectedLanguage.Id === 0) {
      this.errorText = this.$t(
        'VirtualMeetings.AddVideo.NoCaptionLanguageSelected',
      ).toString();
      setTimeout(() => {
        const selMeetingTypes = document.getElementById(
          'selMeetingTypes',
        ) as HTMLSelectElement;
        if (selMeetingTypes !== null) {
          selMeetingTypes.selectedIndex = 0;
          this.selectedLanguage.Id = 0;
          const uploadImage = document.getElementById(
            'uploadImage',
          ) as HTMLSelectElement;
          if (uploadImage !== null) {
            uploadImage.value = '';
          }
        }
      }, 150);
      return;
    }
    this.Iconfilename = file[0].name;
    const captionFile = file[0];
    // check if caption file has extension of accepted caption file type
    if (
      !captionFile.name
        .toLowerCase()
        .match(this.localSelectedVodBase.AcceptedCaptionFileTypes)
    ) {
      this.errorText = this.$t(
        'VirtualMeetings.AddVideo.CaptionFileWarning',
      ).toString();
      return;
    }
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    formData.append('file', captionFile);
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.$axios
      .post(this.fileUploadUrl, formData, options)
      .then((response) => {
        if (response.data.status === 'success') {
          this.SaveCaptionDetails(String(response.data.fullUri));
          this.Iconfilename = this.$t(
            'VirtualMeetings.AddVideo.ChooseFile',
          ).toString();
        }
      })
      .catch((error) => {
        window.console.log(error);
      });
  }
  // Save details of added caption to Db
  private SaveCaptionDetails(fileUrl: string) {
    const signalObject = this.SendNotificationRequest(
      this.localSelectedVodBase.Moduletype,
    );
    this.$axios
      .post<any>(
      '/' + this.localSelectedVodBase.Moduletype + '/AddCaptionstoVideo',
      {
        vodDetailId: this.localSelectedVodBase.VodDetail.Id,
        languageCode: this.selectedLanguage.LanguageCode,
        languageName: this.selectedLanguage.LanguageName,
        captionFileUrl: fileUrl,
      },
    )
      .then((response) => {
        // show caption uploaded in the list
        const captionEntity: VodVideoTextDetail = response.data.captionEntity;
        this.localCaptions.push(captionEntity);
        this.recentlyAddedTrackId = response.data.captionEntity.Id;
        this.isEditPaneVisible = false;
        // this.resetSelectedCaptionType();
        // to remove added caption language from options to add more captions
        this.RemoveCaptionOption(
          String(response.data.captionEntity.LanguageCode),
        );
        // Add signalR request to queue
        this.SendNotificationRequestToQueue(signalObject);
      })
      .catch((error) => {
        window.console.log(error);
      });
  }
  // delete the caption file from a video
  private DeleteCaptionClicked(captionItem: VodVideoTextDetail) {
    this.showCloseButton = true;
    this.isEditPaneVisible = false;
    this.captionObjectForDelete = captionItem;
    this.showSectionModalPopup = this.VodClearCaption;
    // set message into model popup and show model popup
    this.modalPopupBodyHeading = this.$t(
      'VirtualMeetings.AddVideo.ClearCaptionWarning',
    ).toString();
    this.modalPopupVisible = true;
  }
  // show edit pane for replacing the caption file
  private ReplaceCaptionFile(captionItem: VodVideoTextDetail) {
    this.isEditPaneVisible = false;
    this.captionObjectForEdit = captionItem;
    this.showCaptionsButton = false;
  }

  // DeleteCaptionFile
  private DeleteCaptionFile() {
    this.$emit('vodsaveupdate');
    this.$axios
      .post<any>(
      '/' + this.localSelectedVodBase.Moduletype + '/DeleteCaptionstoVideo',
      {
        vodDetailId: this.localSelectedVodBase.VodDetail.Id,
        languageCode: this.captionObjectForDelete!.LanguageCode,
      },
    )
      .then((response) => {
        if (response.data.status === true) {
          this.localCaptions.find(
            (x) => x.Id === this.captionObjectForDelete!.Id,
          )!.IsDeleted = true;
          // add deleted caption option to drop down

          const deletedCaptionOption =
            this.vodLanguages.arrSupportedLanguages.find(
              (x) =>
                x.LanguageCode === this.captionObjectForDelete!.LanguageCode,
            );
          this.availableLanguages.arrSupportedLanguages.push(
            deletedCaptionOption!,
          );
          this.showCaptionsButton = true;
          setTimeout(() => {
            this.captionObjectForDelete = undefined;
          }, 100);
        }
      });
  }
  // Captions replace functionality
  private onCaptionFileReplace(
    fileName: string,
    file: any,
    captionItem: VodVideoTextDetail,
  ) {
    this.$emit('vodsaveupdate');
    let fileUri = '';
    // Delete the previous caption file first
    this.captionObjectForDelete = captionItem;
    this.$axios
      .post<any>(
      '/' + this.localSelectedVodBase.Moduletype + '/DeleteCaptionstoVideo',
      {
        vodDetailId: this.localSelectedVodBase.VodDetail.Id,
        languageCode: this.captionObjectForDelete.LanguageCode,
      },
    )
      .then((response) => {
        if (response.data.status === true) {
          this.showCaptionsButton = true;
          setTimeout(() => {
            this.captionObjectForDelete = undefined;
          }, 100);
        }
      });
    // Get file selected in file picker
    const captionFile = file[0];
    if (
      !captionFile.name
        .toLowerCase()
        .match(this.localSelectedVodBase.AcceptedCaptionFileTypes)
    ) {
      this.errorText = this.$t(
        'VirtualMeetings.AddVideo.CaptionFileWarning',
      ).toString();
      return;
    }
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    formData.append('file', captionFile);
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    // Upload the caption file
    this.$axios
      .post(this.fileUploadUrl, formData, options)
      .then((response) => {
        if (response.data.status === 'success') {
          this.selectedLanguage.LanguageCode = captionItem.LanguageCode;
          this.selectedLanguage.LanguageName = captionItem.LanguageName;
          fileUri = response.data.fullUri;
          if (fileUri !== null && fileUri !== '') {
            // Save new Caption
            this.$axios
              .post<any>(
              '/' +
                  this.localSelectedVodBase.Moduletype +
                  '/AddCaptionstoVideo',
              {
                vodDetailId: this.localSelectedVodBase.VodDetail.Id,
                languageCode: captionItem.LanguageCode,
                languageName: captionItem.LanguageName,
                captionFileUrl: fileUri,
              },
            )
              .then((responsed) => {
                // show caption uploaded in the list
                const indexToChange = this.localCaptions.findIndex(
                  (x) => x.Id === captionItem.Id,
                );
                this.localCaptions[indexToChange].Id =
                  responsed.data.captionEntity.Id;
                this.localCaptions[indexToChange].VodTrackId =
                  responsed.data.captionEntity.VodTrackId;
                this.captionObjectForEdit = VodVideoTextDetail.createEmpty();
                this.recentlyAddedTrackId = responsed.data.captionEntity.Id;
                this.isEditPaneVisible = false;
                // to remove added caption language from options to add more captions
                this.RemoveCaptionOption(
                  String(responsed.data.captionEntity.LanguageCode),
                );
              })
              .catch((error) => {
                window.console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        window.console.log(error);
      });
  }
  // Remove Language option from drop down if it is already added in caption file
  private RemoveCaptionOption(languageCode: string) {
    const objIndex = this.availableLanguages.arrSupportedLanguages.findIndex(
      (obj: Language) => obj.LanguageCode === languageCode,
    );
    if (objIndex > -1) {
      this.availableLanguages.arrSupportedLanguages.splice(objIndex, 1);
    }
    this.isCaptionsAddMoreButtonVisible();
  }
  // Add caption languge option to drop down if any caption deleted
  private AddCaptionOption(languageCode: string) {
    this.$emit('vodsaveupdate');
    const langToAdd = this.vodLanguages.arrSupportedLanguages.find(
      (obj: Language) => obj.LanguageCode === languageCode,
    );
    if (langToAdd !== null) {
      this.availableLanguages.arrSupportedLanguages.push(langToAdd!);
    }
  }
  // CancelReplaceCaption
  private CancelReplaceCaption() {
    this.showCaptionsButton = true;
    this.captionObjectForEdit = VodVideoTextDetail.createEmpty();
  }
  // Show CaptionEditPane
  private ShowCaptionEditPane(isVisible: boolean) {
    this.errorText = '';
    this.showCaptionsButton = false;
    this.isEditPaneVisible = isVisible;
    this.showCaptionsCancelButton = true;
    setTimeout(() => {
      const selMeetingTypes = document.getElementById(
        'selMeetingTypes',
      ) as HTMLSelectElement;
      if (selMeetingTypes !== null) {
        selMeetingTypes.selectedIndex = 0;
      }
    }, 200);
  }
  // Hide CaptionEditPane
  private HideCaptionEditPane(isVisible: boolean) {
    this.errorText = '';
    this.isEditPaneVisible = isVisible;
    this.showCaptionsButton = true;
    this.showCaptionsCancelButton = false;
  }

  // Reset Selected Caption Type
  private resetSelectedCaptionType() {
    // set default selected
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // eslint-disable-next-line no-unused-expressions
    (this.selectedLanguage.Id = 0),
    (this.selectedLanguage.LanguageName = 'Select Language');
    this.selectedLanguage.LanguageCode = '';
  }

  // Is CaptionsAddMoreButtonVisible
  private isCaptionsAddMoreButtonVisible() {
    if (this.availableLanguages.arrSupportedLanguages.length < 2) {
      this.showCaptionsButton = false;
    }
  }
  // #endregion

  // #region Delete Video

  // ConfirmClick for delete video
  private ConfirmClick(confirmed: boolean) {
    this.$emit('vodsaveupdate');
    this.modalPopupVisible = false;
    if (confirmed) {
      if (this.showSectionModalPopup === this.VodClearCaption) {
        this.DeleteCaptionFile();
      } else if (this.showSectionModalPopup === this.VodAssetErrored) {
        // code to delete the file must be here
        this.isUploadingInProgress = false;
        this.isVideoready = false;
        this.VodStatus = '';
        this.localSelectedVodBase.VodDetail.VODLatestStatus =
          this.showSectionModalPopup;

        if (
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
            null &&
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
            undefined
        ) {
          this.DeleteVideo(
            this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.Id,
            this.localSelectedVodBase.VodDetail.Id,
          );
        } else if (this.localSelectedVodBase.VodDetail.Id) {
          this.DeleteVideoByVideoDetailId(
            this.localSelectedVodBase.VodDetail.Id,
          );
        }
      } else if (this.showSectionModalPopup === this.VosAssetCancelUploading) {
        this.CancelUploadingOfVideo();
      } else {
        return;
      }
    } else {
      if (this.showSectionModalPopup === this.VodAssetErrored) {
        this.$root.$emit('show-add-video-section-byEntity', false);
        setTimeout(() => {
          this.$root.$emit('customCategoryDetailLoadingVod', false);
        }, 500);
        if (
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
            null &&
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
            undefined
        ) {
          this.DeleteVideo(
            this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.Id,
            this.localSelectedVodBase.VodDetail.Id,
          );
          this.localSelectedVodBase.VodDetail.VODLatestStatus = '';
          this.localSelectedVodBase.VodDetail.Id = 0;
        } else if (this.localSelectedVodBase.VodDetail.Id) {
          this.DeleteVideoByVideoDetailId(
            this.localSelectedVodBase.VodDetail.Id,
          );
          this.localSelectedVodBase.VodDetail.VODLatestStatus = '';
          this.localSelectedVodBase.VodDetail.Id = 0;
        }
      } else if (this.showSectionModalPopup === 'VosAssetCancelUploading') {
        this.isCancelEnabled = false;
        this.$root.$emit('freeze-screen', true, this.LoginUsername);
      }
    }
  }
  // Delete Video By VideoDetailId
  private DeleteVideoByVideoDetailId(detailId: number) {
    // Delete video API call
    this.$axios
      .post<any>(
      '/' +
          this.localSelectedVodBase.Moduletype +
          '/DeleteVideoByVodDetailid',
      {
        vodDetailId: detailId,
      },
    )
      .then((response) => {
        if (response.data.success === 'true') {
          this.localSelectedVodBase.VodDetail.VODLatestStatus = '';
          this.localSelectedVodBase.VodDetail.Id = 0;
        }
      });
  }
  // DeleteVideo
  private DeleteVideo(detailApplicationInstanceId: number, detailId: number) {
    // Delete video API call
    this.$axios
      .post<any>('/' + this.localSelectedVodBase.Moduletype + '/DeleteVideo', {
      vodDetailId: detailId,
      vodDetailApplicationInstanceId: detailApplicationInstanceId,
    })
      .then((response) => {
        if (response.data.success === 'true') {
          this.localSelectedVodBase.VodDetail.VODLatestStatus = '';
          this.localSelectedVodBase.VodDetail.Id = 0;
        }
      });
  }

  // #endregion

  // #region progress bar

  // To get poster Url and video upload Url after the progress is complete
  private ProgressComplete() {
    let playbackIdString = '';
    if (this.currentWebHookEvent!.VodVideo !== null) {
      playbackIdString = JSON.parse(
        JSON.stringify(this.currentWebHookEvent!.VodVideo!.PlayBackId),
      );
    }
    // Getting poster Url
    this.localSelectedVodBase.VodDetail.VodVideoDetail =
      VodVideoDetail.createEmpty();
    if (this.currentWebHookEvent!.VodVideo !== null) {
      if (this.currentWebHookEvent!.VodVideo!.PlayBackId !== null) {
        this.$axios
          .post<any>(
          '/' + this.localSelectedVodBase.Moduletype + '/GenerateMuxToken',
          {
            playbackId: playbackIdString,
            muxMedia: 'image',
          },
        )
          .then((response) => {
            if (response.data !== null) {
              const imageUrl = response.data.objectUrl;
              if (imageUrl !== '' && imageUrl !== null) {
                this.localSelectedVodBase.VodDetail.VodVideoDetail!.PosterUrl =
                  imageUrl;
              }
            }
          })
          .catch((error) => {
            window.console.log(
              'Error fetching poster URL. ' + 'Details:' + error,
            );
          });
        // Getting video Url
        this.$axios
          .post<any>(
          '/' + this.localSelectedVodBase.Moduletype + '/GenerateMuxToken',
          {
            playbackId: playbackIdString,
            muxMedia: 'video',
          },
        )
          .then((response) => {
            if (response.data !== null) {
              const videoUrl = response.data.objectUrl;
              if (videoUrl !== '' && videoUrl !== null) {
                this.localSelectedVodBase.VodDetail.VodVideoDetail!.PlayBackUrl =
                  videoUrl;
              }
            }
          })
          .catch((error) => {
            window.console.log(
              'Error fetching MUX video URL. ' + 'Details:' + error,
            );
          });
      }
      this.ProgressBarComplete();
      this.$root.$emit('program-vod-video-uploading-user-is-me', true);
    }
  }
  // ProgressBarComplete
  private ProgressBarComplete() {
    this.isVideoready = true;
    this.isCompleted = true;
    const progressBar = document.getElementById(
      this.progressBarId,
    ) as HTMLElement;
    if (progressBar !== null) {
      setTimeout(() => {
        progressBar.setAttribute('style', 'width: 100%');
        progressBar.classList.remove('progress-bar-animated');
      }, 10);
    }
  }
  // Processing the video upload by chunck
  private Processing() {
    const progressBar = document.getElementById(
      this.progressBarId,
    ) as HTMLElement;
    if (progressBar !== null) {
      setTimeout(() => {
        progressBar.setAttribute('style', 'width: 100%');
      }, 10);
    }
  }
  // set Percentage InProgressBar
  private setPercentageInProgressBar(percent: number) {
    this.percentageUploaded = percent;
    this.$root.$emit('vod-save-current-progress', this.percentageUploaded);
  }

  // Progress Bar Hundread Percent
  private progressBarHundreadPercent() {
    if (
      this.videoUploadingStatus === 'VideoAssetCreated' ||
      this.videoUploadingStatus === 'VideoAssetReady'
    ) {
      const progressBarPercentage = document.getElementById(
        this.progressBarId + '_Percent',
      ) as HTMLElement;
      if (
        progressBarPercentage !== null &&
        progressBarPercentage !== undefined
      ) {
        setTimeout(() => {
          progressBarPercentage.innerHTML = 100 + '%';
        }, 500);
      }
    }
  }
  // Get Scroll BarId
  private GetScrollBarId() {
    /* keep progress bar always unique to make sure that if 2 video are uploading simultaneously, both
    have different progression */
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    this.progressBarId =
      reqInfo.ApplicationId +
      '-' +
      reqInfo.ApplicationInstanceId +
      '-' +
      this.localSelectedVodBase.EntityId;
  }

  // #endregion

  // #region Publish and schedule Date

  // CheckTimeFormat
  private CheckTimeFormat(): boolean {
    const browsersettings: BrowserSettingsInfo =
      this.storeHelper.GetBrowserSettingsFromStore();
    return browsersettings.isFullDayTimeFormat;
  }
  // Get ScheduleDate From PublishDate
  private GetScheduleDateFromPublishDate() {
    let todayDate: Date = new Date();
    if (
      this.localSelectedVodBase.SessionStartDate &&
      this.selectedPublishOption !== VideoScheduleEnums.UnPublished
    ) {
      todayDate = new Date(this.localSelectedVodBase.SessionStartDate);
    } else if (this.selectedPublishOption === VideoScheduleEnums.UnPublished) {
      todayDate = new Date(
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.UploadedOn,
      );
    }
    const addedDefaultDays = todayDate.setDate(
      todayDate.getDate() + this.storageTillDefaultDays,
    );
    const defaultDays = new Date(addedDefaultDays);
    return defaultDays;
  }
  //  Set Value in datepicker for Storage Till
  private GetStorageAvailabilityDate() {
    const defaultDays = this.GetScheduleDateFromPublishDate();

    if (
      this.localSelectedVodBase.VodDetail !== null &&
      this.localSelectedVodBase.VodDetail !== undefined &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
        null &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
        undefined
    ) {
      return this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
        .StorageTillDate === null ||
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .StorageTillDate === undefined
        ? defaultDays
        : this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .StorageTillDate;
    } else {
      return defaultDays;
    }
  }
  // Show PublishDate Option
  private ShowPublishDateOption() {
    if (this.selectedPublishOption === VideoScheduleEnums.Custom) {
      return true;
    } else if (this.selectedPublishOption === VideoScheduleEnums.UnPublished) {
      return true;
    }
    return false;
  }
  // Set Value in datepicker for Publish Date
  private GetDateForPublish() {
    this.getDifferenceInDays();
    const sessionStartTime = this.localSelectedVodBase.SessionStartDate;

    if (
      this.localSelectedVodBase.VodDetail !== null &&
      this.localSelectedVodBase.VodDetail !== undefined &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance! !==
        null &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance! !==
        undefined
    ) {
      if (
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .PublishOption !== VideoScheduleEnums.UnPublished &&
        (this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .PublishDate === null ||
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
            .PublishDate === undefined)
      ) {
        return this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .PublishDate;
      } else if (
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .PublishOption !== VideoScheduleEnums.UnPublished &&
        (this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .PublishDate !== null ||
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
            .PublishDate !== undefined)
      ) {
        return this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .PublishDate;
      } else if (
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .PublishOption === VideoScheduleEnums.UnPublished
      ) {
        return this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .UploadedOn;
      } else {
        return moment(sessionStartTime).subtract(15, 'minutes').toDate();
      }
    } else {
      return moment(sessionStartTime).subtract(15, 'minutes').toDate();
    }
  }
  // Assign date value to storage availability date returned from datepicker component
  private SetStorageAvailabilityDate(date: Date) {
    if (
      date != null &&
      this.localSelectedVodBase.VodDetail != null &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !== null
    ) {
      if (
        this.localSelectedVodBase.VodDetail &&
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance &&
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .StorageTillDate !== null &&
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .StorageTillDate !== date
      ) {
        if (
          this.localSelectedVodBase.VodDetail.VODLatestStatus ===
            'VideoAssetReady' ||
          this.localSelectedVodBase.VodDetail.VODLatestStatus ===
            'VideoAssetCreated' ||
          this.localSelectedVodBase.VodDetail.VODLatestStatus ===
            'VideoAssetLiveStreamCompleted'
        ) {
          if (this.isNotFirstcall) {
            this.saveCancelButtonsDisplay(true);
          }
          this.isNotFirstcall = true;
        }
      }

      if (
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
          null &&
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
          undefined
      ) {
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.StorageTillDate =
          date;
      }
    }
    this.getDifferenceInDays();
  }

  // Assign date value to publish  date returned from datepicker component
  private SetVideoPublishDate(date: Date) {
    this.getDifferenceInDays();
    if (
      this.localSelectedVodBase.VodDetail != null &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance != null
    ) {
      if (this.selectedPublishOption === VideoScheduleEnums.Now) {
        // Now
        const todaysDate = new Date();
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishDate =
          todaysDate;
      } else if (
        this.selectedPublishOption ===
        VideoScheduleEnums.FifteenMinPriorToSession
      ) {
        // 15 min prior
        const sessionStartTime = this.localSelectedVodBase.SessionStartDate;
        this.fifteenMinutesBeforeSessionTime = moment(sessionStartTime)
          .subtract(15, 'minutes')
          .toDate();
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishDate =
          this.fifteenMinutesBeforeSessionTime;
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.StorageTillDate =
          this.GetScheduleDateFromPublishDate();
      } else if (date != null && this.localSelectedVodBase.VodDetail != null) {
        if (
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
            .PublishDate !== null &&
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
            .PublishDate !== date
        ) {
          if (
            this.localSelectedVodBase.VodDetail.VODLatestStatus ===
              'VideoAssetReady' ||
            this.localSelectedVodBase.VodDetail.VODLatestStatus ===
              'VideoAssetCreated'
          ) {
            if (this.isNotFirstCallPublished) {
              this.saveCancelButtonsDisplay(true);
            }
            this.isNotFirstCallPublished = true;
          }
          this.isScheduleDateOrPublishChanged = true;
        }
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishDate =
          date;
      }
    }
    this.getDifferenceInDays();
  }

  // SetPublishOption
  private SetPublishOption(type: number) {
    this.isScheduleDateOrPublishChanged = true;
    this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.PublishOption =
      type;
  }
  // getDateFormat
  private getDateFormat() {
    return 'MMM dd, yyyy';
  }
  // selectOption
  private selectOption(isModified: boolean) {
    this.isStorageAvailabilityDateError = false;
    this.isPublishedDateError = false;
    this.saveCancelButtonsDisplay(isModified);
    this.isScheduleDateOrPublishChanged = isModified;
    this.getDifferenceInDays();
    // Set option value in VodDetail!.PublishOption
    if (
      this.localSelectedVodBase.VodDetail &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
    ) {
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishOption =
        this.selectedPublishOption;
      if (this.selectedPublishOption === VideoScheduleEnums.Now) {
        // Now
        const todaysDate = new Date();
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishDate =
          todaysDate;
        this.isScheduleDateOrPublishChanged = isModified;
      } else if (
        this.selectedPublishOption ===
        VideoScheduleEnums.FifteenMinPriorToSession
      ) {
        // 15 min prior
        const sessionStartTime = this.localSelectedVodBase.SessionStartDate;
        this.fifteenMinutesBeforeSessionTime = moment(sessionStartTime)
          .subtract(15, 'minutes')
          .toDate();
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishDate =
          this.fifteenMinutesBeforeSessionTime;
        this.isScheduleDateOrPublishChanged = isModified;
      } else if (this.selectedPublishOption === VideoScheduleEnums.Custom) {
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.PublishDate =
          new Date();
        this.isScheduleDateOrPublishChanged = isModified;
      } else if (
        this.selectedPublishOption === VideoScheduleEnums.UnPublished
      ) {
        this.SetVideoPublishDate(
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
            .UploadedOn,
        );
        this.isScheduleDateOrPublishChanged = isModified;
      }
    }
  }

  // getDifferenceInDays
  private getDifferenceInDays() {
    if (
      this.localSelectedVodBase.VodDetail &&
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
    ) {
      const storageTillDateM = moment(
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .StorageTillDate,
      ).toDate();
      const publishTillDateM = moment(
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance
          .PublishDate,
      ).toDate();
      const timeDiff = storageTillDateM.getTime() - publishTillDateM.getTime();
      const daysDifference = timeDiff / (1000 * 3600 * 24);
      if (daysDifference >= 90) {
        this.storagePricingInfo = true;
        this.getPreRecordedStorageAmount();
      } else {
        this.storagePricingInfo = false;
      }
    }
  }
  // ScheduledDates Validation
  private scheduledDatesValidation() {
    const storageTillDateM = moment(
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!
        .StorageTillDate,
    ).toDate();
    const publishTillDateM = moment(
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!
        .PublishDate,
    ).toDate();
    const publishTillDateUTC = moment
      .utc(
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!
          .PublishDate,
      )
      .toISOString();
    let timeZone: string =
      this.storeHelper.GetActiveEventIanaTimeZoneFromStore();
    if (timeZone === null || timeZone === undefined || timeZone === '') {
      timeZone = moment.tz.guess();
    }
    const publishDateUTC = new Date(publishTillDateUTC);
    const timeoffset = -moment.tz(timeZone).utcOffset();
    const publishDateUTCTimeStamp = publishDateUTC.setMinutes(
      publishDateUTC.getMinutes() + timeoffset,
    );
    const now = new Date();

    const currentUTCTimestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
    );
    const timeDiffStorageTill =
      storageTillDateM.getTime() - publishTillDateM.getTime();
    const daysDifferenceStorageTill = timeDiffStorageTill / (1000 * 3600 * 24);
    this.isPublishedDateError = false;
    this.isStorageAvailabilityDateError = false;
    if (daysDifferenceStorageTill < -1) {
      this.isStorageAvailabilityDateError = true;
      this.isSaved = false;
    } else if (currentUTCTimestamp >= publishDateUTCTimeStamp) {
      if (
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!
          .PublishOption === VideoScheduleEnums.Now ||
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!
          .PublishOption === VideoScheduleEnums.UnPublished ||
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!
          .PublishOption === VideoScheduleEnums.FifteenMinPriorToSession
      ) {
        this.isSaved = true;
        return;
      }
      this.isPublishedDateError = true;
      this.isSaved = false;
    } else {
      this.isSaved = true;
    }
  }
  // SavedSuccessfully dates
  private SavedSuccessfully() {
    this.scheduledDatesValidation();
    if (this.isSaved) {
      this.isSavedClicked = true;
      this.saveCancelButtonsShow = false;
      this.isDatesSaved = true;
      setTimeout(() => {
        this.isDatesSaved = false;
        this.SaveVideoDetails();
      }, this.savedMessageFadeoutTime);
    }
  }
  // Click on CancelIcon date
  private CancelIcon() {
    this.saveCancelButtonsShow = false;
    this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.StorageTillDate =
      this.preScheduledDate;
    if (this.selectedPublishOption !== VideoScheduleEnums.UnPublished) {
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.PublishOption =
        this.preSelectedOption;
      this.selectedPublishOption = this.preSelectedOption;
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.PublishDate =
        this.prePublishedDate;
      this.SetVideoPublishDate(
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!
          .PublishDate!,
      );
      this.SetStorageAvailabilityDate(
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!
          .StorageTillDate!,
      );
      this.GetStorageAvailabilityDate();
      this.GetDateForPublish();
      this.isStorageAvailabilityDateError = false;
      this.isPublishedDateError = false;
    }
  }

  // #endregion

  private Cancel() {
    if (
      this.localSelectedVodBase.VodDetail.VODLatestStatus === 'VideoAssetReady'
    ) {
      this.ReloadEntity(this.localSelectedVodBase.Moduletype);
      this.$root.$emit('show-add-video-section-byEntity', false);
      this.$root.$emit('program-scroll-to-element', 'programVirtualMeetingDiv');
    } else if (
      this.localSelectedVodBase.VodDetail.VODLatestStatus === 'VodAssetErrored'
    ) {
      if (
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
          null &&
        this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance !==
          undefined
      ) {
        this.DeleteVideo(
          this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance.Id,
          this.localSelectedVodBase.VodDetail.Id,
        );
        this.localSelectedVodBase.VodDetail.VODLatestStatus = '';
        this.localSelectedVodBase.VodDetail.Id = 0;
      }
      this.$root.$emit('show-add-video-section-byEntity', false);
    } else {
      this.ReloadEntity(this.localSelectedVodBase.Moduletype);
    }
    setTimeout(() => {
      this.$root.$emit('customCategoryDetailLoadingVod', false);
    }, 1500);
  }
  // Reload Entity
  private ReloadEntity(entityType: string) {
    if (entityType === VodModuleTypeEnum[1].toString()) {
      this.$root.$emit('reload-customCategory-refresh');
    } else if (entityType === VodModuleTypeEnum[2].toString()) {
      this.$root.$emit('show-add-video-section-byEntity', false);
    } {
      if (!this.isSavedClicked) {
        this.$root.$emit('reload-program-detail');
      } else {
        this.$root.$emit('show-add-video-section-byEntity', false);
        this.$root.$emit(
          'program-scroll-to-element',
          'programVirtualMeetingDiv',
        );
      }
    }
  }

  // #region Save video
  // SaveVideoDetails
  private SaveVideoDetails() {
    if (this.localSelectedVodBase.EntityId != null) {
      this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.EntityId =
        this.localSelectedVodBase.EntityId!;
      this.$axios
        .post<any>(
        '/' +
            (this.localSelectedVodBase.Moduletype === 'Entity' ? 'ApplicationInstance' :this.localSelectedVodBase.Moduletype) +
            '/SaveVideoScheduleInformationByEntityType',
        {
          vodDetail: this.localSelectedVodBase.VodDetail,
          entityType: this.localSelectedVodBase.Moduletype,
        },
      )
        .then((response) => {
          if (response.data !== null) {
            if (
              this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!
                .PublishOption === VideoScheduleEnums.Now
            ) {
              this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.PublishDate = response.data.PublishUTCDate;
              this.$root.$emit(
                'publishedOnDate',
                this.localSelectedVodBase.VodDetail
                  .VodDetailApplicationInstance!.PublishDate,
              );
            } else {
              this.localSelectedVodBase.VodDetail.VodDetailApplicationInstance!.PublishUTCDate =
                response.data.PublishUTCDate;
              this.$root.$emit(
                'publishedOnDate',
                this.localSelectedVodBase.VodDetail
                  .VodDetailApplicationInstance!.PublishUTCDate,
              );
            }
            this.$root.$emit('show-add-video-section-byEntity', false);
            this.$root.$emit(
              'show-add-video-section-vod-byEntity',
              this.localSelectedVodBase.SubModuleType,
            );
            if (
              this.localSelectedVodBase.SubModuleType ===
              VirtualMeetingType.MeetAppLive
            ) {
              // emit value to set virtualMeetingType in VitualMeetingEditPane
              this.$root.$emit(
                'set-virtual-meetingtype-VonageMUX',
                this.localSelectedVodBase.SubModuleType,
              );
            }
          }
          if(this.localSelectedVodBase.Moduletype === VodModuleTypeEnum[VodModuleTypeEnum.Program].toString()){
            this.$root.$emit('reload-program-detail');
          }
          if(this.localSelectedVodBase.Moduletype === VodModuleTypeEnum[VodModuleTypeEnum.Entity].toString()){
            this.$root.$emit('reload-watch-detail',response.data.success);
          }
          setTimeout(() => {
            this.$root.$emit('customCategoryDetailLoadingVod', false);
          }, 1500);
        })
        .catch((error) => {
          window.console.log('Error saving video details. Details:' + error);
        });
      this.$root.$emit('show-add-video-section-byEntity', false);
    } else {
      alert('Something wrong!');
    }
    this.$root.$emit('show-add-video-section-byEntity', false);
  }

  // #endregion

  // #region Cancel video
  // Cancel Uploading Of Video and show popup
  private CancelUploadingOfVideoClicked() {
    // Show popup for cancel video upload
    this.isCancelEnabled = true;
    this.$root.$emit('freeze-screen', false, this.LoginUsername);
    if (!this.isCompleted) {
      this.showSectionModalPopup = this.VosAssetCancelUploading;
      this.modalPopupBodyHeading = this.$t(
        'VirtualMeetings.AddVideo.CancelUploadingWarning',
      ).toString();
      // this.modalPopupHeader = this.$t('VirtualMeetings.AddVideo.InputFileValidationErrorHeader').toString();
      this.modalPopupVisible = true;
    }
  }
  // After confrim Cancel Uploading Of Video
  private CancelUploadingOfVideo() {
    this.$root.$emit('freeze-screen', false, this.LoginUsername);
    this.isCancelEnabled = true;
    // Cancel video upload and stop Uphunk processs
    this.uploadingThread.abort();
    this.$axios
      .post<any>(
      '/' + this.localSelectedVodBase.Moduletype + '/CancleUploadVideo',
      {
        uploadId: this.uploadId,
        vodDetailId: this.localVodDetails.Id,
      },
    )
      .then((response) => {
        if (response.data !== null) {
          /* reintailize and restore VOddetail object as The one that was created while
              uploading was started is deleted*/
          // this.selectedItem!.VodDetail = VodDetail.createEmpty();
          this.isUploadingInProgress = false;
          this.VodStatus = '';
          this.localSelectedVodBase.VodDetail.VODLatestStatus = '';
          this.localSelectedVodBase.VodDetail.Id = 0;
          this.$root.$emit('program-item-video-upload-inprogress', false);
        }
      })
      .catch((error) => {
        window.console.log(
          'Error While canceling upload. ' + 'Details:' + error,
        );
      });
  }

  // #endregion

  // #region SignalR

  // SendNotificationRequest
  private SendNotificationRequest(entityType: string): SignalRModelRequest {
    let signalR: any;
    // eslint-disable-next-line prefer-const
    signalR = this.GetSignalRObjectByEntityType(entityType, signalR);

    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    if (entityType === VodModuleTypeEnum[1].toString()) {
      signalObject =
        signalR.VodVideoUploadCustomCategoryItemSignalRModelRequest;
    } else {
      signalObject = signalR.VodVideoUploadProgramItemSignalRModelRequest;
    }
    const arr: number[] = [];
    arr.push(this.localSelectedVodBase.EntityId);
    signalObject.SelectedIds = arr;
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      selectedCount: arr.length, // Just single item delete
      user: signalR.GetUserName(
        signalObject,
        this.$t('UserName.You').toString(),
      ),
    }).toString();
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({
      selectedName: '',
    });
    return signalObject;
  }
  // Get SignalR Object By EntityType
  private GetSignalRObjectByEntityType(entityType: string, signalR: any) {
    switch (entityType) {
    case VodModuleTypeEnum[1].toString():
      signalR = new CustomCategorySignalRModel(
        this.$root,
        this.$axios,
        this.$store,
        this.$t,
      );
      break;
    case VodModuleTypeEnum[0].toString():
      signalR = new ProgramSignalRModel(
        this.$root,
        this.$axios,
        this.$store,
        this.$t,
      );
      break;
    }
    return signalR;
  }
  // Send Notification Request To Queue
  private SendNotificationRequestToQueue(signalObject: SignalRModelRequest) {
    let signalR: any;
    // eslint-disable-next-line prefer-const
    signalR = this.GetSignalRObjectByEntityType(
      this.localSelectedVodBase.Moduletype,
      signalR,
    );
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(this.localSelectedVodBase.EntityId);
      signalObject.SelectedIds = arr;
    }
    switch (this.localSelectedVodBase.Moduletype) {
    case VodModuleTypeEnum[1].toString():
      signalR.PerformCustomCategorySingleOperation(signalObject);
      break;
    case VodModuleTypeEnum[0].toString():
      signalR.PerformProgramSingleOperation(signalObject);
      break;
    }
  }

  // #endregion

  // #region Watches

  @Watch('SelectedVodBase')
  private onSelectedVodBaseUpdated() {
    this.localSelectedVodBase = this.SelectedVodBase;
  }
  @Watch('localCaptions')
  private onCaptionsUpdated() {
    this.localVodDetails.VodVideoTextDetails = this.localCaptions;
    this.$emit('setVodDetails', this.localVodDetails);
  }

  // #endregion

  // Get Information String
  private getInformationString(val: boolean) {
    this.isVideoUnavailableInfo = val;
  }
  // Get PreRecorded Storage Amount
  private getPreRecordedStorageAmount() {
    const preRecordedVideoStoragepricingInfo: ConsumptionBasedProductPrice =
      this.storeHelper.GetconsumptionBasedProductPricingFromStore();
    const meetAppLivePricingInformation =
      preRecordedVideoStoragepricingInfo.PreRecordedVideoStorageProductPrice!;
    this.updateStoragePricing = this.$t(
      'VirtualMeetings.AddVideo.StorageAvailableMessage',
      { storageAmount: meetAppLivePricingInformation.ProductPriceAmount },
    ).toString();
  }

  // #region vod Streaming cost
  // Show Vod Pricing PopUp
  private ShowVodPricingPopUp() {
    const preRecordedVideopricingInfo: ConsumptionBasedProductPrice =
      this.storeHelper.GetconsumptionBasedProductPricingFromStore();
    this.vodLivePricingInformation =
      preRecordedVideopricingInfo.PreRecordedVideoProductPrice!;
    this.showVodPricingDetailsPopup = true;
  }
  // show pricing details text
  private showPricingDetailText(){
    if (this.localSelectedVodBase.SubModuleTypeValue === VodTypeEnums[VodTypeEnums.Mux].toString() || this.localSelectedVodBase.SubModuleTypeValue === 'PreRecordedVideo') {
      return true;
    }
    return false;
  }
  // show or hide vod streaming cost message if video duration is greater than 10 mins
  private showVodStreamingCostMessage() {
    if (this.localSelectedVodBase.VodDetail !== null && this.localSelectedVodBase.VodDetail !== undefined && this.localSelectedVodBase.VodDetail.Id > 0 && this.localSelectedVodBase.VodDetail.VodVideoDetail !== undefined && this.localSelectedVodBase.VodDetail.VodVideoDetail !== null ) {
      const sec = parseFloat(this.localSelectedVodBase.VodDetail.VodVideoDetail.Duration);
      const mins = sec / 60;
      if (mins > 10) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  // #endregion
}
