













































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProgramTagItem } from '@/entities/Program/ProgramTagItem';
import { ProgramTagItemResult } from '@/entities/Program/ProgramTagItemResult';
import { required, minLength } from 'vuelidate/lib/validators';
import loading from '@/components/Common/loading.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { TagSignalRModel } from '@/signalRRequest/Program/TagSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
@Component({
  components: { loading },
  validations: {
    selectedTagItem: {
      TagName: {
        required,
        minLength: minLength(1),
      },
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
})
export default class ProgramTagItemEditPane extends Vue {
  public showLoading = false;
  public loaderBorderColor = '';
  public initialTagItem: ProgramTagItem = ProgramTagItem.createEmpty();
  @Prop() private selectedTagItem?: ProgramTagItem;
  @Prop() private applicationborderColor?: string;

  private mounted() {
    // store initial value of tag for cancel operation
    this.initialTagItem = Object.assign(this.initialTagItem, this.selectedTagItem);
  }
  private Cancel() {
    this.$v.$reset();
    this.selectedTagItem = this.initialTagItem;
    const item = this.selectedTagItem;
    this.$emit('program-tag-item-cancel-operation', item);
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      this.$root.$emit('show-notification-popup', true);
      this.$root.$emit('show-notification-loading', true);
    }, 100);
    const signalR: TagSignalRModel = new TagSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();

    if (this.selectedTagItem!.Id === 0) {
      signalObject = signalR.SingleCreateTagItemSignalRModelRequest;
    } else {
      signalObject = signalR.SingleUpdateTagItemSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.selectedTagItem!.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
    }).toString();

    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({selectedName: this.selectedTagItem!.TagName});
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }

  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    programTagItem: ProgramTagItem,
  ) {
    const signalR: TagSignalRModel = new TagSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(programTagItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetTagItemSendToSignalR(programTagItem);
    signalR.PerformTagSingleOperation(signalObject);
  }
  private Submit() {
    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }
    this.$v.$reset();
    // No errors submitting.
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    const signalObject = this.SendNotificationRequest();
    this.$axios
      .post<ProgramTagItemResult>(
      '/Program/SaveOrUpdateProgramTag',
      this.selectedTagItem,
    )
      .then((response) => {
        if (response.data.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.data.ValidationErrors[0]));
        } else {
          // Successful update / save
          this.$emit('program-tag-item-edit-operation', response.data.TagItem);

          this.$root.$emit(
            'program-tag-item-edit-operation',
            response.data.TagItem,
          );
          this.SendNotificationRequestToQueue(
            signalObject,
            response.data.TagItem,
          );
        }
      })
      .catch(() => {
        // Log the error if required
      });
  }
  private getPlaceholder(): string {
    return this.$t('Tag.Create.Placeholder').toString();
  }
}
