












































import { Component, Prop, Vue } from 'vue-property-decorator';
import videojs from '@mux/videojs-kit';
import '@mux/videojs-kit/dist/index.css';
import { VodVideoDetail } from '@/entities/VodDetail';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
@Component
export default class VideoPlayer extends Vue {
  @Prop() private vodVideoDetail?: VodVideoDetail;
  @Prop() private entityTitle?: string;
  private mounted() {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    const userHash = reqInfo.UserHash;
    const muxEnvironmentKey = reqInfo.MuxEnvironmentKey;
    const player = videojs('my-player', {
      responsive: true,
      controlBar: {
        pictureInPictureToggle: false,
      },
    });
    player.mux({
      debug: false,
      data: {
        env_key: muxEnvironmentKey,
        video_id: this.vodVideoDetail!.VideoEncryptId,
        player_name: 'Admin',
        player_init_time: Date.now(),
        video_title: this.entityTitle,
        viewer_user_id: userHash,
      },
    });
  }
  private Cancel() {
    videojs.getPlayer('my-player').dispose();
    this.$emit('close-video-player', false);
  }
}
