










































































import PreviewBulkDataViewModel from '@/entities/ImportFile/PreviewBulkDataViewModel';
import SubModuleEnum from '@/enums/SubModuleEnum';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ValidationSummary from '../ValidationSummary.vue';
@Component({
  components: {
    ValidationSummary,
  },
})
export default class PreviewBulkData extends Vue {
  @Prop() PreviewImportedData!: PreviewBulkDataViewModel;
  private localPreviewData: PreviewBulkDataViewModel = PreviewBulkDataViewModel.createEmpty();
  private isValidationSummaryVisible = false;
  private errorRowIndexes: number[] = [];
  private localImportProgramsBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private localbulkEditOperations: any= null;
  private signalR: ProgramSignalRModel = new ProgramSignalRModel(
    this.$root,
    this.$axios,
    this.$store,
    this.$t,
  );


  private mounted() {
    if (this.PreviewImportedData !== null && this.PreviewImportedData !== undefined ) {
      this.localPreviewData = this.PreviewImportedData;
      this.localImportProgramsBulkOperationModel = Object.assign({}, this.signalR.arrSignalRModelRequests.find((e)=> e.SubModule === this.localPreviewData.SubModuleType));
      this.$root.$emit('program-import-inprogress', true);
    }
  }

  private HideImportPreview() {
    this.$emit('hide-import-preview',false);
  }
  private Import() {
    this.errorRowIndexes = this.localPreviewData.ValidationErrors.index;
    if(this.errorRowIndexes.length === 0) {
      this.localImportProgramsBulkOperationModel.JSONData = JSON.stringify(this.localPreviewData.JsonData);
      this.localImportProgramsBulkOperationModel.Heading = this.$t(
        this.localImportProgramsBulkOperationModel.Heading,
      ).toString();
      setTimeout(() => {
        this.$root.$emit('show-notification-popup', true);
        this.$root.$emit('show-notification-loading', true);
      }, 100);
      this.localImportProgramsBulkOperationModel.Description = this.$t(
        this.localImportProgramsBulkOperationModel.SubmitMessage,
        {
          selectedCount: 0,
          user: this.signalR.GetUserName(
            this.localImportProgramsBulkOperationModel,
            this.$t('UserName.You').toString(),
          ),
        },
      ).toString();
      this.$root.$emit('empty-active-sub-component');
      this.localImportProgramsBulkOperationModel.SelectedIds = [];
      this.localImportProgramsBulkOperationModel.SubModule = SubModuleEnum.ImportPrograms;
      this.signalR.PerformProgramBatchOperation(
        this.localImportProgramsBulkOperationModel,
      );
    } else {
      this.isValidationSummaryVisible = true;
    }
  }
  private CloseValidationSummary(val: boolean) {
    this.isValidationSummaryVisible = val;
  }
  private ErrorInRow(index: number) {
    return this.errorRowIndexes.includes(index) ? true : false;
  }
  private DisplayPropertyInPreview(item: any) {
    if(typeof(item) === 'object' && item.ShowInPreview === false) {
      return true;
    } else {
      return false;
    }
  }
  private ShowValue(item: any) {
    if(typeof(item) === 'object') {
      return item.PropName;
    } else {
      return item;
    }
  }
  private beforeDestroy() {
    this.$root.$emit('program-import-inprogress', false);
  }
}
