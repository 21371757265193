

















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import InputTag from '@/components/Common/InputTag.vue';
import { ProgramTagItem } from '@/entities/Program/ProgramTagItem';
import { ProgramItem } from '@/entities/Program/ProgramItem';

@Component({
  components: {
    ValidationSummary,
    InputTag,
  },
})
export default class ProgramEditTags extends Vue {
  @Prop() private availableTags?: ProgramTagItem[];
  @Prop() private selectedItems?: ProgramItem[];
  @Prop() private selectedBulkOperationModel?: SignalRModelRequest;
  private selectedTags: any = [];
  private existingTags: any = [];
  private overwriteLinkedTags = false;
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private selectedProgramTags: ProgramTagItem[] = [];
  private previouslyUsedProgramTags: ProgramTagItem[] = [];
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private signalR: ProgramSignalRModel = new ProgramSignalRModel(
    this.$root,
    this.$axios,
    this.$store,
    this.$t,
  );
  private mounted() {
    this.localSelectedBulkOperationModel = this.selectedBulkOperationModel!;
    this.updateTags();
    this.SelectPreviouslyUsedTags();
  }
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  // Update tags in component
  private updateTags() {
    const allAvailableTags = this.availableTags || [];
    for (const allAvailableTag of allAvailableTags) {
      this.existingTags.push({
        key: allAvailableTag.Id,
        value: allAvailableTag.TagName,
      });
    }
  }
  // Assign selected tags returned from InputTag component
  private SetTagNamesValue(selectedTagNames: any) {
    if (selectedTagNames != null) {
      if (typeof selectedTagNames !== 'undefined') {
        this.selectedProgramTags = [];
        for (const tag of selectedTagNames) {
          this.selectedProgramTags.push(new ProgramTagItem(Number(tag.key), String(tag.value)));
        }
      }
    }
  }
  private CloseTags() {
    this.$emit('selectBack');
  }
  private SaveTags() {
    if (this.selectedProgramTags.length > 0) {
      const selectedProgramIds: number[] = this.signalR.GetSelectedProgramIdsToSendInJson(this.selectedItems!);
      this.localSelectedBulkOperationModel.SelectedIds = selectedProgramIds;

      this.localSelectedBulkOperationModel.JSONData = JSON.stringify({overwriteLinkedTags: this.overwriteLinkedTags, tags: this.selectedProgramTags});
      this.signalR.ShowNotificationsPopup();
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(this.localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      this.signalR.PerformProgramBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      this.$root.$emit('program-uncheck-select-all');
      this.$root.$emit('empty-track-tag');
    } else {
      this.validationErrorFields = [];
      this.validationErrorFields.push(this.$t('ProgramTag.SelectTag').toString());
      this.visibleValidationSummary = true;
    }
  }

  private SelectPreviouslyUsedTags() {
    this.previouslyUsedProgramTags = [];
    this.previouslyUsedProgramTags = this.availableTags!;
  }

  private AppendTags(tag: ProgramTagItem) {
    this.$root.$emit('AppendTags', tag);
  }

  @Watch('selectedItems')
  private onselectedItemsPropertyChange() {
    this.SelectPreviouslyUsedTags();
  }
}
