



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { VonageSessionData } from '@/entities/VonageSessionData';
import VirtualMeetingHighChart from '@/components/Common/VirtualMeetingHighChart.vue';
import VirtualMeetingComprehensiveDetails from '@/components/Common/VirtualMeetingComprehensiveDetails.vue';
@Component({
  components: {
    VirtualMeetingHighChart,
    VirtualMeetingComprehensiveDetails,
  },
})
export default class VirtualMeetingSessionAnalytics extends Vue {
  @Prop() private vonageAnalyticsForGraph?: VonageSessionData;
  @Prop() private selectedProgramId?: number;
  @Prop() private moduleType?: string;
  private vonageAnalyticslocal: VonageSessionData = VonageSessionData.createEmpty();
  private showCancelButton = false;
  private totalMeetingMinutes = 0;
  private averageSessionTime = 0;
  private moderators = 0;
  private created() {
    this.vonageAnalyticslocal = this.vonageAnalyticsForGraph!;
    this.totalMeetingMinutes = this.vonageAnalyticslocal.TotalMinutesOfMeeting;
    this.averageSessionTime =  this.vonageAnalyticslocal.AverageSessionTime;
    this.moderators =  this.vonageAnalyticslocal.ModeratorsCount;
  }

  private Cancel() {
    this.$root.$emit('reload-program-detail');
  }
  private mounted() {
    this.$root.$on('show-consolidated-section', (show: boolean) => {
      this.showCancelButton = show;
    });
  }
}
