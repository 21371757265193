











































































































































































































































































































































































































import { VirtualMeetingType } from '@/entities/VirtualMeetingType';
import VirtualMeetingtypeParentEnum from '@/entities/VirtualMeetingType';
import { StoreHelper } from '@/store/StoreHelper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import VirtualMeetingMeetAppLive from '@/components/Common/VirtualMeetings/VirtualMeetingMeetAppLive.vue';
import MeetAppLiveConsolidatedDetails from '@/components/Common/VirtualMeetings/MeetAppLiveConsolidatedDetails.vue';
import MeetappPricingDetails from '@/components/Application/MeetappPricingDetails.vue';
import { VodDetail } from '@/entities/VodDetail';
import VirtualMeetingEnums from '@/enums/VirtualMeetingEnums';
import VodTypeEnums from '@/enums/VodTypeEnums';
import { VonageSessionData } from '@/entities/VonageSessionData';
import moment from 'moment-timezone';
import { ProductPrice } from '@/entities/ProductPrice';
import { ConsumptionBasedProductPrice } from '@/entities/ConsumptionBasedProductPrice';
import VideoOnDemandComponent from '@/components/Common/VirtualMeetings/VideoOnDemandComponent.vue';
import { VodBaseRenderModel } from '@/entities/FactoryPattern/Vod/VodBaseRenderModel';

@Component({
  components: {
    VirtualMeetingMeetAppLive,
    MeetAppLiveConsolidatedDetails,
    MeetappPricingDetails,
    VideoOnDemandComponent,
  },
})
export default class VirtualMeetingEditPane extends Vue {
  @Prop() private selectedItem?: ProgramItem;
  @Prop() private selectedMeetingOption?: number;
  @Prop() private SelectedVodBase?: VodBaseRenderModel;
  @Prop() private vonageThresholdLimitReached?: boolean;
  // read only properties
  private localselectedMeetingOption = 0;
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private virtualMeetingTypes: VirtualMeetingType[] = [];
  private localVirtualMeetingTypes: VirtualMeetingType[] = [];
  private VirtualMeetingLink = '';
  private VirtualMeetingType = '';
  private VirtualMeetingIcon = '';
  private selectedStreaming = '';
  private isRTMPVideo = false;
  private showStudioLink = false;
  private linkFieldHeading = '';
  private oldProgramItem: ProgramItem = ProgramItem.createEmpty();
  public get virtualMeetingEnum(): typeof VirtualMeetingtypeParentEnum {
    return VirtualMeetingtypeParentEnum;
  }
  private isRTMPWarningVisible = false;
  private streamKeyCheckbox = false;
  private rtmpStreamKey = '';
  private rtmpStreamURl = 'rtmps://global-live.mux.com:443/app';
  private showKeyCopiedMessage?: boolean = false;
  private showURLCopiedMessage?: boolean = false;
  private linkCopiedFadeoutTime = 1500;
  private isLiveStreamRecorded?: boolean = true;
  private vonageAnalytics: VonageSessionData | null = null;
  private pricingPopupType: number = VodTypeEnums.RTMP;
  private showRtmpPricingDetailsPopup = false;
  private rtmpLivePricingInformation: ProductPrice = ProductPrice.createEmpty();
  private isRecordedSessionMessage?: boolean = false;
  private isSelectedMeetingTypeZoom = false;
  private created() {
    this.virtualMeetingTypes = VirtualMeetingType.GetVirtualMeetingTypes(
      this.isEnableVimeoVideos(),
    );
    this.setLocalTypes();
    this.VirtualMeetingIcon = this.virtualMeetingTypes.find(
      (x) => x.Key === VirtualMeetingEnums.Others,
    )!.ImageIcon;
  }

  // use for show/hide meetApp live component
  private showMeetAppLive() {
    if (
      this.localselectedMeetingOption === this.virtualMeetingEnum.MeetAppLive &&
      this.selectedItem!.VirtualMeetingType !== 'VonageMUX'
    ) {
      return true;
    }
    return false;
  }
  private ShowProfessionalStreamingOption() {
    if (
      this.localselectedMeetingOption ===
        this.virtualMeetingEnum.ProfessionalStreaming &&
      (this.selectedItem!.VodDetail === undefined || this.selectedItem!.VodDetail === null)
    ) {
      return true;
    } else if (
      this.localselectedMeetingOption ===
        this.virtualMeetingEnum.ProfessionalStreaming &&
      this.selectedItem!.VodDetail !== null &&
      this.selectedItem!.VodDetail !== undefined &&
      this.selectedItem!.VodDetail.VODLatestStatus !==
        'VideoAssetLiveStreamCompleted' &&
      this.selectedItem!.VodDetail.VODLatestStatus !==
        'VideoLiveStreamActive' &&
      this.selectedItem!.VodDetail.VODLatestStatus !== 'VideoAssetDeleted'
    ) {
      return true;
    } else if (
      this.localselectedMeetingOption === this.virtualMeetingEnum.MeetAppLive &&
      this.selectedItem!.VodDetail !== null &&
      this.selectedItem!.VodDetail !== undefined &&
      this.selectedItem!.VirtualMeetingType !== 'VonageMUX'
    ) {
      // Show ProfessionalStreamingOption when we don't have VonageMUX
      return true;
    }
    return false;
  }
  private ShowAnalyticsSection() {
    if (
      this.localselectedMeetingOption ===
        this.virtualMeetingEnum.ProfessionalStreaming &&
      this.selectedItem!.VodDetail !== null &&
      this.selectedItem!.VodDetail !== undefined &&
      (this.selectedItem!.VodDetail.VODLatestStatus ===
        'VideoAssetLiveStreamCompleted' ||
        this.selectedItem!.VodDetail.VODLatestStatus === 'VideoAssetDeleted')
    ) {
      return true;
    } else if (
      this.selectedItem!.VirtualMeetingType === 'VonageMUX' &&
      this.selectedItem!.VodDetail !== null &&
      this.selectedItem!.VodDetail !== undefined
    ) {
      // Show Analytics Section when we have vonageMUX
      return true;
    } else if (
      this.localselectedMeetingOption ===
        this.virtualMeetingEnum.ProfessionalStreaming &&
      this.selectedItem!.VodDetail !== null &&
      this.selectedItem!.VodDetail !== undefined &&
      this.selectedItem!.VodDetail.VODLatestStatus === 'VideoLiveStreamActive'
    ) {
      return false;
    }
    return false;
  }
  // showThreshHold
  private showThreshHold(val: any) {
    this.$emit('threshHold', val);
  }
  private ShowPrerecordedSection() {
    if (
      this.localselectedMeetingOption ===
      this.virtualMeetingEnum.PreRecordedVideo
    ) {
      return true;
    } else if (
      this.localselectedMeetingOption ===
        this.virtualMeetingEnum.ProfessionalStreaming &&
      this.selectedItem!.VodDetail !== null &&
      this.selectedItem!.VodDetail !== undefined &&
      this.selectedItem!.VodDetail.VODLatestStatus ===
        'VideoAssetLiveStreamCompleted' &&
      this.selectedItem!.VodDetail.IsRecorded
    ) {
      return true;
    } else if (
      this.localselectedMeetingOption ===
        this.virtualMeetingEnum.ProfessionalStreaming &&
      this.selectedItem!.VodDetail !== null &&
      this.selectedItem!.VodDetail !== undefined &&
      this.selectedItem!.VodDetail.VODLatestStatus === 'VideoAssetDeleted' &&
      this.selectedItem!.VodDetail.IsRecorded
    ) {
      return true;
    } else if (
      this.localselectedMeetingOption === this.virtualMeetingEnum.MeetAppLive &&
      this.selectedItem!.VirtualMeetingType === 'VonageMUX' &&
      this.selectedItem!.VodDetail !== null &&
      this.selectedItem!.VodDetail !== undefined &&
      (this.selectedItem!.VodDetail.VODLatestStatus === 'VideoAssetReady' ||
        this.selectedItem!.VodDetail.VODLatestStatus === 'VideoAssetDeleted') &&
      this.selectedItem!.VodDetail.IsRecorded
    ) {
      // Show Prerecorded Section when we have vonageMUX
      return true;
    }
    return false;
  }
  private ShowOthersSection() {
    if (
      (this.selectedMeetingOption! > 0 &&
        this.localselectedMeetingOption !==
          this.virtualMeetingEnum.PreRecordedVideo &&
        this.localselectedMeetingOption !==
          this.virtualMeetingEnum.MeetAppLive) ||
      this.selectedItem!.VirtualMeetingType === 'VonageMUX'
    ) {
      return true;
    }
    return false;
  }
  private ShowImageIcon() {
    if (
      this.VirtualMeetingType !== '' &&
      this.VirtualMeetingType !== null &&
      this.selectedItem!.VirtualMeetingLink !== '' &&
      this.selectedMeetingOption !==
        this.virtualMeetingEnum.ProfessionalStreaming
    ) {
      return true;
    }
    return false;
  }
  private isRtmpStreamingValid(): boolean {
    let isvalidProgram = true;
    const todaynowString = new Date().toUTCString();
    const programEndDateString = new Date(
      this.selectedItem!.EndDateUTC,
    ).toUTCString();
    const todaynow = moment(todaynowString);
    const programEndDate = moment(programEndDateString);
    // this.selectedItem!.EndDate.getTime() + (this.meetAppLiveExtendedMinutes * 60 * 1000);
    // commented code line is for adding extended minutes to the end date of program
    if (
      todaynow > programEndDate &&
      this.VirtualMeetingType === VirtualMeetingEnums.RTMPString
    ) {
      if (
        this.selectedItem!.VodDetail !== undefined &&
        this.selectedItem!.VodDetail !== null
        // &&
        // this.selectedItem!.VodDetail.VodStreamingType === VodTypeEnums.RTMP
      ) {
        if (
          this.selectedItem!.VodDetail.VODLatestStatus ===
            'VideoAssetLiveStreamCompleted' ||
          this.selectedItem!.VodDetail.VODLatestStatus === 'VideoAssetDeleted'
        ) {
          isvalidProgram = true;
        } else {
          isvalidProgram = false;
        }
      }
    }
    return isvalidProgram;
  }
  private mounted() {
    // value emit from add video when we have vonageMUX
    this.$root.$on('set-virtual-meetingtype-VonageMUX', (val: number) => {
      this.localselectedMeetingOption = val;
      this.selectedItem!.VirtualMeetingType = 'VonageMUX';
      this.VirtualMeetingType = 'VonageMUX';
    });
    const self = this;
    this.localselectedMeetingOption = this.selectedMeetingOption!;
    this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType;
    if (
      this.VirtualMeetingType !== '' &&
      this.VirtualMeetingType !== undefined
    ) {
      // get icon of the meeting
      this.VirtualMeetingIcon = this.virtualMeetingTypes.find(
        (x) => x.Key === this.VirtualMeetingType,
      )!.ImageIcon;
    }

    this.showStudioLink =
      this.selectedItem!.VirtualMeetingLink !== '' &&
      this.selectedItem!.VirtualMeetingLink !== null
        ? true
        : false;
    this.getLinkHeading();
    this.isRTMPWarningVisible = this.selectedItem!.Id === 0 ? true : false;
    this.$root.$on('set-meeting-option-pre-recorded', (val: number) => {
      this.localselectedMeetingOption = val;
    });

    this.$root.$on(
      'update-program-details',
      (selectedProgramItem: ProgramItem) => {
        self.selectedItem!.HasExternalZoomLink =
          selectedProgramItem.HasExternalZoomLink;
      },
    );

    this.$root.$on(
      'ProgramVodMuxVideoData',
      (vodDetails: VodDetail, virtualMeetingType: string) => {
        this.SetVodMuxVideoData(vodDetails, virtualMeetingType, self);
      },
    );
    this.$root.$on(
      'ProgramVonageSessionData',
      (vonageData: VonageSessionData) => {
        if (vonageData !== null && vonageData !== undefined) {
          this.$root.$emit(
            'virtual-meeting-meetapp-live-analytics-available',
            true,
          );
          this.vonageAnalytics = vonageData;
        }
      },
    );
    this.$root.$on('show-meetapp-live-pricing-popup', (val: boolean) => {
      this.showRtmpPricingDetailsPopup = val;
    });
    this.$root.$on('set-rtmp-to-Active', (val: boolean) => {
      this.isRTMPVideo = val;
    });
    this.vonageAnalytics = this.selectedItem!.VonageSessionData!;
    this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType;
    if (this.selectedItem !== null && this.selectedItem !== undefined) {
      this.SetVodMuxVideoData(
        this.selectedItem.VodDetail!,
        this.VirtualMeetingType,
        this,
      );
    }
  }
  private SetVodMuxVideoData(
    vodDetails: VodDetail,
    virtualMeetingType: string,
    self: this,
  ) {
    if (vodDetails !== undefined && vodDetails !== null) {
      this.isRTMPVideo = false;

      if (
        vodDetails.Id > 0 &&
        vodDetails.VodStreamingType === VodTypeEnums.RTMP &&
        virtualMeetingType === VirtualMeetingEnums.RTMPString
      ) {
        this.$root.$emit(
          'set-selected-meeting-type',
          VirtualMeetingEnums.RTMPString,
        );
        this.$root.$emit('set-IsRecorded-Session', vodDetails.IsRecorded);
        if (
          vodDetails.VODLatestStatus === 'VideoAssetLiveStreamCompleted' &&
          vodDetails.VodVideoDetail != null
        ) {
          this.isRTMPVideo = false;
          self.selectedItem!.IsRecordedSession = self.isLiveStreamRecorded =
            vodDetails.IsRecorded;
          self.selectedItem!.VirtualMeetingType = self.VirtualMeetingType =
            VirtualMeetingEnums.RTMPString;
          this.$root.$emit(
            'virtual-meeting-meetapp-live-analytics-available',
            true,
          );
        } else if (
          vodDetails.VODLatestStatus !== 'VideoAssetLiveStreamCompleted' &&
          vodDetails.VODLatestStatus !== 'VideoAssetDeleted'
        ) {
          self.selectedItem!.VirtualMeetingType = self.VirtualMeetingType =
            VirtualMeetingEnums.RTMPString;
          self.isRTMPVideo = true;
          self.streamKeyCheckbox = true;
          self.selectedItem!.IsRecordedSession = self.isLiveStreamRecorded =
            vodDetails.IsRecorded;
          self.rtmpStreamKey = vodDetails.LiveStreamKey;
          this.$root.$emit(
            'set-meeting-option-pre-recorded',
            this.virtualMeetingEnum.ProfessionalStreaming,
          );
        }
      } else if (
        vodDetails.Id > 0 &&
        vodDetails.VodStreamingType === VodTypeEnums.Mux &&
        virtualMeetingType === VirtualMeetingEnums.VonageMUX
      ) {
        // emit values when we have vonageMux
        this.$root.$emit(
          'set-selected-meeting-type',
          VirtualMeetingEnums.VonageMUX,
        );
        this.$root.$emit('set-IsRecorded-Session', vodDetails.IsRecorded);
        this.$root.$emit(
          'virtual-meeting-meetapp-live-analytics-available',
          true,
        );
      } else if (
        vodDetails.Id > 0 &&
        vodDetails.VodStreamingType === VodTypeEnums.Mux
      ) {
        // Do nothing
      } else {
        if (virtualMeetingType) {
          self.selectedItem!.VirtualMeetingType = self.VirtualMeetingType =
            virtualMeetingType;
        }
        if (virtualMeetingType !== VirtualMeetingEnums.RTMPString) {
          self.isRTMPVideo = false;
          self.streamKeyCheckbox = false;
        } else {
          self.isRTMPVideo = true;
          self.streamKeyCheckbox = false;
        }
      }
    }
  }

  private OnInvitaionLinkChange() {
    if (this.selectedItem!.VirtualMeetingLink !== null) {
      if (
        this.localselectedMeetingOption ===
        VirtualMeetingtypeParentEnum.ProfessionalStreaming
      ) {
        this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType = 'HLS';
      } else if (
        this.localselectedMeetingOption ===
        VirtualMeetingtypeParentEnum.MeetAppLive
      ) {
        this.selectedItem!.VirtualMeetingType =
          VirtualMeetingEnums.VonageString;
      } else if (
        this.localselectedMeetingOption ===
        VirtualMeetingtypeParentEnum.PreRecordedVideo
      ) {
        this.selectedItem!.VirtualMeetingType = VirtualMeetingEnums.MuxString;
      } else {
        this.selectedItem!.VirtualMeetingType = VirtualMeetingEnums.Others;
      }
      // this.VirtualMeetingType = VirtualMeetingEnums.Others;
      this.VirtualMeetingIcon = this.virtualMeetingTypes.find(
        (x) => x.Key === VirtualMeetingEnums.Others,
      )!.ImageIcon;
      this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType;
      this.SetAutoSuggestedType();
      this.$root.$emit(
        'selected-meeting-type-selected-option',
        this.localselectedMeetingOption,
      );
    }
  }
  private isEnableVideoOnDemand(): boolean {
    const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
    if(this.SelectedVodBase!.Moduletype.toLowerCase() !== 'program') {
      if (appInfo.EnableVideoOnDemand && appInfo.AllowEnableTimeZoneFeature && appInfo.EnableRTMPOnEventSettings) {
        return true;
      }
      return false;
    }
    if (appInfo.EnableVideoOnDemand && appInfo.AllowEnableTimeZoneFeature) {
      return true;
    }
    return false;
  }
  private getInformationString(val: boolean) {
    this.isRecordedSessionMessage = val;
  }
  private ShowRtmpPricingPopUp() {
    const rtmpLivepricingInfo: ConsumptionBasedProductPrice =
      this.storeHelper.GetconsumptionBasedProductPricingFromStore();
    this.rtmpLivePricingInformation = rtmpLivepricingInfo.RTMPLiveProductPrice!;
    this.showRtmpPricingDetailsPopup = true;
  }
  // set Auto suggested type of Video to Program item and show icon in link text field
  private SetAutoSuggestedType() {
    this.localVirtualMeetingTypes.forEach((element: VirtualMeetingType) => {
      if (element.Suggestions != null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const res = this.selectedItem!.VirtualMeetingLink.match(element.Suggestions);
        if (res != null) {
          this.selectedItem!.VirtualMeetingType = element.Key;
          this.VirtualMeetingType = element.Key;
          this.VirtualMeetingIcon = element.ImageIcon;
        }
      }
    });
  }
  // Check if vimei video is enabled at application level
  private isEnableVimeoVideos(): boolean {
    const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
    if (appInfo.EnableVimeoVideos) {
      return true;
    }
    return false;
  }
  /* Get items of virtualMeetingTypes depending upon tab selected. For example, if selected youtube
  or Vimeo video only Youtube and Vimeo item will be pushed to localVirtualMeetingTypes*/
  private setLocalTypes() {
    this.localVirtualMeetingTypes = [];
    this.virtualMeetingTypes.forEach((value) => {
      if (value.ParentType === this.selectedMeetingOption) {
        this.localVirtualMeetingTypes.push(value);
      }
    });
  }
  private ProfessionalMeetingTypeChange(meetingtype: string) {
    this.selectedItem!.VirtualMeetingType = meetingtype;
    if (meetingtype === 'RTMP') {
      this.isRTMPVideo = true;
      this.VirtualMeetingType = 'RTMP';
    } else {
      this.isRTMPVideo = false;
      this.VirtualMeetingType = 'HLS';
    }
  }

  private ProfessionalMeetingZoomExternalLinkTypeChange(
    isExternalLink: string,
  ) {
    if (isExternalLink === 'true') {
      this.selectedItem!.HasExternalZoomLink = true;
    } else {
      this.selectedItem!.HasExternalZoomLink = false;
    }
  }
  private MakeRecordingAvailable() {
    if (
      (this.$refs.makeRTMPStreamAvailableCheckbox as HTMLInputElement).checked
    ) {
      this.selectedItem!.IsRecordedSession = true;
      this.$root.$emit('set-IsRecorded-Session', true);
    } else {
      this.selectedItem!.IsRecordedSession = false;
      this.$root.$emit('set-IsRecorded-Session', false);
    }
  }
  // Get heading for link text field
  private getLinkHeading() {
    if (
      this.localselectedMeetingOption ===
      VirtualMeetingtypeParentEnum.YouTubeOrVimeo
    ) {
      this.linkFieldHeading = this.$t(
        'VirtualMeetings.ExternalStreams.YouTubeLinkHeading',
      ).toString();
    } else if (
      this.localselectedMeetingOption ===
      VirtualMeetingtypeParentEnum.ConferenceSoftware
    ) {
      this.linkFieldHeading = this.$t(
        'VirtualMeetings.ExternalStreams.ConferenceSoftwareLinkHeading',
      ).toString();
    } else if (
      this.localselectedMeetingOption ===
      VirtualMeetingtypeParentEnum.ProfessionalStreaming
    ) {
      this.linkFieldHeading = this.$t(
        'VirtualMeetings.ExternalStreams.ProfessionalStreamingLinkHeading',
      ).toString();
    }
  }
  private GetStreamKey(): any {
    if ((this.$refs.getRTMPStreamKeyCheckbox as HTMLInputElement).checked) {
      this.selectedItem!.VirtualMeetingType = VirtualMeetingEnums.RTMPString;
      this.selectedItem!.IsRecordedSession = true;
      this.$root.$emit(
        'set-IsRecorded-Session',
        this.selectedItem!.IsRecordedSession,
      );
      if (this.selectedItem!.Id > 0) {
        this.$axios
          .post<any>(
          '/' +
              (this.SelectedVodBase!.Moduletype.toLowerCase() === 'program'
                ? this.SelectedVodBase!.Moduletype
                : 'ApplicationInstance') +
              '/CreateLiveVideoStream',
          {
            Id: this.selectedItem!.Id,
            isLiveStreamRecorded: this.isLiveStreamRecorded,
            timeOffset: new Date().getTimezoneOffset(),
            startDate: this.selectedItem!.StartDate,
          },
        )
          .then((response) => {
            if (response.data.streamKey !== null) {
              this.rtmpStreamKey = response.data.streamKey;
              if(this.SelectedVodBase!.Moduletype.toLowerCase() !== 'program') {
                this.selectedItem!.VirtualMeetingLink = this.rtmpStreamKey;
              }
              this.selectedItem!.VirtualMeetingType = this.VirtualMeetingType =
                'RTMP';
              setTimeout(() => {
                this.streamKeyCheckbox = true;
              }, 500);
            }
          });
      } else {
        this.rtmpStreamKey = this.$t(
          'VirtualMeetings.ProfessionalStreaming.LinkWillBeGeneratedAfterSave',
        ).toString();
      }
    } else {
      this.streamKeyCheckbox = false;
      this.selectedItem!.IsRecordedSession = false;
      this.rtmpStreamKey = '';
    }
  }
  private copyLinkToClipboard(val: string, type: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        if (type === 'RTMPKey') {
          this.showKeyCopiedMessage = true;
        } else {
          this.showURLCopiedMessage = true;
        }
      } else {
        alert('Oops, unable to copy');
      }
    } catch (err) {
      alert('Oops, unable to copy');
    }
    if (this.showKeyCopiedMessage || this.showURLCopiedMessage) {
      setTimeout(() => {
        this.showKeyCopiedMessage = false;
        this.showURLCopiedMessage = false;
      }, this.linkCopiedFadeoutTime);
    }
    document.body.removeChild(selBox);
  }
  @Watch('selectedMeetingOption')
  private OnMeetingTypeChange(val: number) {
    this.localselectedMeetingOption = val;
    this.getLinkHeading();
    // clear the link only if selected tab is changed program item is unchanged
    if (this.selectedItem!.Id === this.oldProgramItem.Id) {
      this.selectedItem!.VirtualMeetingLink = '';
      this.selectedItem!.VirtualMeetingType = 'Others';
      this.VirtualMeetingType = '';
      this.VirtualMeetingIcon = '';
      // uncheck the meetapp live "I understand checkbox" if type switched
      this.showStudioLink = false;
    }
    this.setLocalTypes();
    // this.OnInvitaionLinkChange();
    if (
      this.localselectedMeetingOption ===
      VirtualMeetingtypeParentEnum.MeetAppLive
    ) {
      if (
        this.selectedItem!.VirtualMeetingType === VirtualMeetingEnums.VonageMUX
      ) {
        this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType =
          VirtualMeetingEnums.VonageMUX;
      }
    }
    if (
      this.localselectedMeetingOption ===
      VirtualMeetingtypeParentEnum.ProfessionalStreaming
    ) {
      if (
        this.selectedItem!.VirtualMeetingType === VirtualMeetingEnums.RTMPString
      ) {
        this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType =
          VirtualMeetingEnums.RTMPString;
      } else if (
        this.selectedItem!.VirtualMeetingType === VirtualMeetingEnums.HLSString
      ) {
        this.selectedItem!.VirtualMeetingType = this.VirtualMeetingType =
          VirtualMeetingEnums.HLSString;
        this.selectedItem!.VodDetail = VodDetail.createEmpty();
        this.streamKeyCheckbox = false;
        this.isRTMPVideo = false;
      } else if (
        this.selectedItem!.VodDetail !== undefined &&
        this.selectedItem!.VodDetail !== null &&
        this.selectedItem!.VodDetail.Id > 0
      ) {
        if (
          this.selectedItem!.VodDetail.VodStreamingType === VodTypeEnums.Mux
        ) {
          this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType =
            VirtualMeetingEnums.RTMPString;
          this.isRTMPVideo = true;
          this.streamKeyCheckbox = false;
        } else if (
          this.selectedItem!.VodDetail.VodStreamingType === VodTypeEnums.RTMP &&
          this.selectedItem!.VodDetail.VODLatestStatus ===
            'VideoAssetLiveStreamCompleted'
        ) {
          this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType =
            VirtualMeetingEnums.RTMPString;
          this.isRTMPVideo = false;
        } else {
          if(this.SelectedVodBase!.Moduletype.toLowerCase() !== 'program') {
            const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
            if (appInfo.EnableVideoOnDemand && appInfo.AllowEnableTimeZoneFeature && appInfo.EnableRTMPOnEventSettings) {
              this.VirtualMeetingType = VirtualMeetingEnums.HLSString;
              this.isRTMPVideo = false;
            }
          } else {
            this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType =
            VirtualMeetingEnums.RTMPString;
            this.isRTMPVideo = true;
          }
        }
      } else if (this.streamKeyCheckbox) {
        this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType =
          VirtualMeetingEnums.RTMPString;
        this.isRTMPVideo = true;
        this.streamKeyCheckbox = false;
      } else if (this.isEnableVideoOnDemand()) {
        this.selectedItem!.VirtualMeetingType = VirtualMeetingEnums.Others;
        this.VirtualMeetingType = VirtualMeetingEnums.RTMPString;
        this.isRTMPVideo = true;
      } else {
        this.selectedItem!.VirtualMeetingType = VirtualMeetingEnums.HLSString;
        this.VirtualMeetingType = VirtualMeetingEnums.HLSString;
      }
    }
    if (
      this.localselectedMeetingOption !==
      VirtualMeetingtypeParentEnum.ProfessionalStreaming
    ) {
      this.isRTMPVideo = false;
      // this.VirtualMeetingType = VirtualMeetingEnums.Others;
    }
    if (
      this.localselectedMeetingOption ===
      VirtualMeetingtypeParentEnum.PreRecordedVideo
    ) {
      if (this.selectedItem!.VodDetail === null) {
        this.selectedItem!.VodDetail = VodDetail.createEmpty();
      }
    }
    this.oldProgramItem.Id = this.selectedItem!.Id;
    this.SelectedVodBase!.EntityId = this.selectedItem!.Id;
    this.SelectedVodBase!.VodDetail = this.selectedItem!.VodDetail!;
  }
  @Watch('selectedItem')
  private programItemChange(val: ProgramItem) {
    this.oldProgramItem = val;
    this.VirtualMeetingType = this.selectedItem!.VirtualMeetingType;
    this.setLocalTypes();

    // this.OnInvitaionLinkChange();
    this.VirtualMeetingType = val.VirtualMeetingType;

    if (
      this.VirtualMeetingType === 'VonageMUX' &&
      this.selectedItem!.VodDetail !== null &&
      this.selectedItem!.VodDetail !== undefined &&
      this.selectedItem!.VodDetail.VodStreamingType === VodTypeEnums.Mux
    ) {
      // set type while switching b/w programs
      this.VirtualMeetingType = VirtualMeetingEnums.VonageMUX;
    }

    if (
      this.VirtualMeetingType === '' &&
      this.selectedItem!.VodDetail !== null &&
      this.selectedItem!.VodDetail !== undefined &&
      this.selectedItem!.VodDetail.VodStreamingType === VodTypeEnums.RTMP
    ) {
      this.VirtualMeetingType = VirtualMeetingEnums.RTMPString;
    }
    this.showStudioLink = true;
    if (this.VirtualMeetingType !== '') {
      this.VirtualMeetingIcon = this.virtualMeetingTypes.find(
        (x) => x.Key === this.VirtualMeetingType,
      )!.ImageIcon;
    }
  }
}
