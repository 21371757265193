














import ActionButtonViewModel from '@/entities/ActionButtonViewModel';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {

  },
})
export default class ActionButton extends Vue {
  @Prop() ActionButtonData!: ActionButtonViewModel;
  private localActionButtonData: ActionButtonViewModel = ActionButtonViewModel.createEmpty();
  private mounted() {
    if(this.ActionButtonData !== null && this.ActionButtonData !== undefined) {
      this.localActionButtonData = this.ActionButtonData;
    }
  }
  private EmitActionType(type: number) {
    this.$emit('show-upload-bulk-data',type);
  }
  @Watch('ActionButtonData')
  private CheckChangeInActionButtonData(newVal: ActionButtonViewModel) {
    this.localActionButtonData = newVal;
  }
}
