var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.vonageThresholdLimitReached)?_c('div',{staticClass:"top-margin-in-fields"},[_c('div',{staticClass:"alert alert-warning thresh-holdLimit-warning inline-flex100",attrs:{"id":"dvMeetAppLiveThresholdLimitReachedDetails","role":"alert"}},[_vm._m(0),_c('div',{staticClass:"threshold-message message-content"},[_vm._v(" "+_vm._s(_vm.GetThresholdLimitReachedMsg())+" ")])])]):_c('div',[(_vm.showLoader())?_c('div',{staticClass:"ajax-loader gridlisting"},[_c('img',{staticClass:"img-responsive ajax-loader-img",attrs:{"src":_vm.loadingImage,"alt":"Wait"}})]):_c('div',[(_vm.modalPopupVisible)?_c('ModalPopup',{attrs:{"show-modal-popup":_vm.modalPopupVisible,"header-content":_vm.modalPopupHeader,"body-heading":_vm.modalPopupContent,"show-close-button":_vm.showCloseButton,"show-cancel-button":_vm.modalPopupShowCancelButton,"cancel-button-text":_vm.modalPopupCancelButtonText,"confirm-button-text":_vm.modalPopupConfirmButtonText},on:{"confirm-click":function($event){return _vm.ConfirmClick($event)}}}):_vm._e(),(_vm.videoPlayerVisible)?_c('div',[_c('VideoPlayer',{attrs:{"vod-video-detail":_vm.localSelectedVodBaseClass.VodDetail.VodVideoDetail,"entity-title":_vm.localSelectedVodBaseClass.Title},on:{"close-video-player":function($event){return _vm.ClosePlayer($event)}}})],1):_vm._e(),_c('div',{staticClass:"pre-recorded-section parent-information-icon-div",class:[
          {
            'top-margin-in-fields':
              _vm.localSelectedVodBaseClass.SubModuleType !== 4 &&
              _vm.localSelectedVodBaseClass.SubModuleType !== 3,
          },
          {
            borderTop1px:
              _vm.localSelectedVodBaseClass.SubModuleType === 4 ||
              _vm.localSelectedVodBaseClass.SubModuleType === 3,
          } ]},[(!_vm.isVideoDetailsVisible)?_c('div',[_c('div',[_c('i',{staticClass:"fa fa-info-circle information-icon",attrs:{"aria-hidden":"true"},on:{"mouseover":function($event){return _vm.getInformationString(true)},"mouseleave":function($event){return _vm.getInformationString(false)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],staticClass:"information-icon-div"},[_vm._v(" "+_vm._s(_vm.$t("VirtualMeetings.PreRecordedDetails.InformationText"))+" ")]),_c('div',{},[_c('label',{staticClass:"headingColor no-margin prerecordingheading"},[_vm._v(_vm._s(_vm.$t("VirtualMeetings.ExternalStreams.PreRecordedVODHeading", { entityType: _vm.localSelectedVodBaseClass.Title.toLowerCase(), })))])]),(_vm.isAddVideoWarningVisible)?_c('div',{staticClass:"top-margin-in-fields prerecordingheading"},[_c('i',{staticClass:"fa fa-exclamation-triangle margin-right-3"}),_c('span',{staticClass:"top-margin-in-fields italic-text"},[_vm._v(_vm._s(_vm.$t("VirtualMeetings.AddVideo.AddVideoWarningCommon", { entityType: _vm.localSelectedVodBaseClass.Title, })))])]):_vm._e(),(!_vm.isAddVideoWarningVisible)?_c('div',[_c('div',{staticClass:"top-margin-in-fields"},[_c('button',{staticClass:"btn btn-save btn-size-default",class:{
                    'btn-disabled': _vm.localSelectedVodBaseClass.EntityId == 0 || _vm.isDisable === true,
                  },attrs:{"disabled":_vm.localSelectedVodBaseClass.EntityId == 0 || _vm.isDisable === true},on:{"click":function($event){return _vm.ShowAddVideo()}}},[_vm._v(" "+_vm._s(_vm.$t("VirtualMeetings.ExternalStreams.PreRecorded.AddVideo"))+" ")])])]):_vm._e()])]):_c('div',{staticClass:"top-margin-in-fields"},[(
              _vm.localSelectedVodBaseClass.SubModuleType === 4 ||
                _vm.localSelectedVodBaseClass.SubModuleType === 3
            )?_c('span',{staticClass:"headingColorRecorded no-margin"},[_vm._v(_vm._s(_vm.$t("VirtualMeetings.ExternalStreams.RecordedSession")))]):_vm._e(),(_vm.localSelectedVodBaseClass.VodDetail !== null)?_c('div',{staticClass:"inline-flex100 row margin-bottom-15px"},[_c('div',{staticClass:"col-xl-9 video-details-div"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"container",on:{"click":function($event){!_vm.isVideoToBePlayed ? _vm.PlayVideo() : false}}},[_c('img',{staticClass:"poster-image",attrs:{"src":_vm.GetPosterUrl()}}),(
                        _vm.latestVideoStatus == 'VideoAssetReady' ||
                          _vm.latestVideoStatus == 'VideoAssetLiveStreamCompleted'
                      )?_c('div',{staticClass:"overlay"},[_c('a',{staticClass:"icon",attrs:{"href":"javascript:void(0)","title":"Play video"},on:{"click":function($event){return _vm.PlayVideo()}}},[_c('i',{staticClass:"fa fa-play-circle"}),_c('div',{staticClass:"video-duration-div"},[_c('p',{staticClass:"video-duration"},[_vm._v(_vm._s(_vm.videoDuration))])])])]):_vm._e()])]),(_vm.latestVideoStatus !== 'VideoAssetErrored')?_c('div',{staticClass:"col-xl-7 video-details"},[_c('table',{staticClass:"table table-borderless"},[_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}}),_c('td',{staticClass:"field",attrs:{"colspan":"8"}},[_vm._v(" "+_vm._s(_vm.$t("VirtualMeetings.PreRecordedDetails.Views"))),_c('span',{staticClass:"color-red"},[_vm._v(" * ")])]),_c('td',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.localViewCount)+" "),(
                              _vm.localViewCount > 0 &&
                                _vm.localSelectedVodBaseClass.SubModuleType !== 4 &&
                                _vm.localSelectedVodBaseClass.SubModuleType !== 3
                            )?_c('a',{staticClass:"hyper-link",on:{"click":function($event){return _vm.seeDetailedAnalytics()}}},[_vm._v(_vm._s(_vm.$t("VirtualMeetings.Button.SeeDetails")))]):_vm._e()])]),_c('tr',[_c('th',{attrs:{"scope":"row"}}),_c('td',{staticClass:"field",attrs:{"colspan":"8"}},[_vm._v(" "+_vm._s(_vm.UploadRecordedOnLabel)+" ")]),_c('td',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.GetUploadedOn())+" ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}}),_c('td',{staticClass:"field",attrs:{"colspan":"8"}},[_vm._v(" "+_vm._s(_vm.$t( "VirtualMeetings.PreRecordedDetails.Availabletill" ))+" ")]),_c('td',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.GetAvailableTillDate())+" ")])]),(
                          _vm.localSelectedVodBaseClass.SubModuleType !== 4 &&
                            _vm.localSelectedVodBaseClass.SubModuleType !== 3
                        )?_c('tr',[_c('th',{attrs:{"scope":"row"}}),_c('td',{staticClass:"field",attrs:{"colspan":"8"}},[_vm._v(" "+_vm._s(_vm.$t( "VirtualMeetings.PreRecordedDetails.Publishedon" ))+" ")]),_c('td',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.formatPublishedOnDate(_vm.publishDateE))+" ")])]):_vm._e()])])]):_c('div',{staticClass:"col-xl-7 error-message"},[_c('span',[_vm._v(_vm._s(_vm.$t("VirtualMeetings.AddVideo.ErrorDetails"))+" :  ")]),_c('span',[_vm._v(_vm._s(_vm.localSelectedVodBaseClass.VodDetail.ErrorDetail))]),_c('a',{staticClass:"hyper-link",on:{"click":function($event){return _vm.TryAgain()}}},[_vm._v(_vm._s(_vm.$t("VirtualMeetings.AddVideo.TryAgain")))])])])]),(
                _vm.latestVideoStatus !== 'VideoAssetErrored' &&
                  _vm.latestVideoStatus !== 'VideoAssetDeleted'
              )?_c('div',{staticClass:"manage-div-width manage-button col-xl-3"},[_c('div',{staticClass:"dropdown inline-flex visible-manage-button valign"},[_c('button',{staticClass:"\n                    btn btn-size-header\n                    btn-class-header\n                    dropdown-toggle\n                    valign\n                  ",class:{ 'btn-disabled-dropdown': !_vm.isCurrentUserMe },attrs:{"data-toggle":"dropdown"}},[_vm._v(" "+_vm._s(_vm.$t("Button.Manage"))+" "),_c('i',{staticClass:"fa fa-angle-down manage-down-icon"})]),_c('ul',{staticClass:"dropdown-menu"},[_c('li',{staticClass:"manage-list-item",on:{"click":function($event){return _vm.ShowAddVideo()}}},[_c('a',{staticClass:"cursor-pointer"},[_vm._v(_vm._s(_vm.$t("Button.Edit")))])]),(
                      _vm.latestVideoStatus === 'VideoAssetReady' ||
                        _vm.latestVideoStatus === 'VideoAssetLiveStreamCompleted'
                    )?_c('li',{staticClass:"manage-list-item",on:{"click":function($event){return _vm.DeleteVideoClick()}}},[_c('a',{staticClass:"cursor-pointer"},[_vm._v(_vm._s(_vm.$t("Button.Delete")))])]):_vm._e()])])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"information-message"},[_c('span',{staticClass:"color-red"},[_vm._v(" *")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("VirtualMeetings.DisplayRecordingInformaion")))]),_c('br'),(
                _vm.localSelectedVodBaseClass.SubModuleType === 4 ||
                  _vm.localSelectedVodBaseClass.SubModuleType === 3
              )?_c('span',[_c('span',{staticClass:"color-red"},[_vm._v(" #")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("VirtualMeetings.DisplayAttendeesCountInformaion")))])]):_vm._e()])])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"font-icon"},[_c('i',{staticClass:"fa fa-exclamation-circle",attrs:{"aria-hidden":"true"}})])])}]

export { render, staticRenderFns }