













































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import vuecroppie from '@/components/Common/ImageCroppie.vue';
import Common from '@/helper/Common';
import PrefixMediaUpload from '@/entities/MediaPrefix';
@Component({
  components: {
    vuecroppie,
  },
})
export default class FileUpload extends Vue {
  @Prop() private imageSrc?: string;
  @Prop() private imageFullSrc?: string;
  @Prop() private videoThumbnailSrc?: string;
  @Prop() private videoFullSrc?: string;
  @Prop() private HeadingTitle?: string;
  @Prop() private HeadingSubTitle?: string;
  @Prop() private acceptedFileTypes?: [];
  @Prop() private fileExtensions?: string;
  @Prop() private validationMessage?: string;
  @Prop() private validationtype?: string;
  @Prop() private IconImageSizeWidth?: number;
  @Prop() private IconImageSizeHeight?: number;
  @Prop() private Type?: string;
  @Prop() private moduleType?: string;
  @Prop() private SubModuleName?: string;

  private imageIconUrl = '';
  private localImageFullSrc = '';
  private uploadImage = 'file';
  private errorText = '';
  private maxSize = 1;
  private imageMaxSize = 1;
  private uploadUrl = '/FileUpload/UploadMediaDuringEditJson';
  private videoMaxSize = 100000;
  private pdfMaxSize = 100000;
  private uploadFieldName = 'file';
  private loadingImage: string = require('@/assets/Images/loading.png');
  private Iconfilename = 'Choose file...';
  private TypeofImageIconImage = 'IconImage';
  private showImageCropping = false;
  private multimediaType = 'Image';
  private localAllowedExtensions = '';
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private featureMissingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private croppedimageIconUrl = '';
  private isMobileView = false;
  private localModuleType = '';
  private mediaPrefix = '';
  private mounted() {
    if (this.videoFullSrc == null) {
      this.showImageCropping = true;
    }
    if (this.imageFullSrc) {
      this.localImageFullSrc = this.imageFullSrc;
    }

    if (this.Type !== null && (this.Type === 'Video' || this.Type === 'PDF' || this.IsVideoFile())) {
      this.showImageCropping = false;
    }
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (!this.isMobileView) {
      Common.ScrollToTop('dvFileupload');
    }
    if(this.moduleType) {
      this.localModuleType = this.moduleType;
    }
  }
  private GetMediaPrefix() {
    if(this.moduleType !== null && this.moduleType !== undefined && this.SubModuleName !== null && this.SubModuleName !== undefined) {
      this.mediaPrefix = new PrefixMediaUpload().GetPrefix(this.moduleType,this.SubModuleName);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private CroppedImageIconUrl(imgurl: string, mainMediaFullUrl: string) {
    this.croppedimageIconUrl = imgurl;
    if(this.moduleType === 'Notification') {
      this.$emit(
        'SetImageIconUrl',
        this.croppedimageIconUrl,
        this.localImageFullSrc,
        this.multimediaType,
      );
    }
  }
  private GetImageIconUrl() {
    return this.localImageFullSrc === ''
      ? this.featureMissingImage
      : this.localImageFullSrc;
  }
  private GetCroppedImageIconUrl() {
    return this.croppedimageIconUrl === ''
      ? this.imageSrc
      : this.croppedimageIconUrl;
  }
  private IsVideoFile() {
    if (this.imageFullSrc != null) {
      return this.imageFullSrc.includes('/img/Play_75x75') === true ? true : false;
    }
    return false;
  }
  private clickOnBackParentEdit() {
    this.$emit('selectBack', false);
    setTimeout(() => {
      this.$root.$emit('customCategoryDetailLoadingVod', false);
    }, 500);
  }
  private clearImageIconUrl() {
    if (this.multimediaType === 'PDF') {
      this.$emit('SetImageIconUrl', '', '', this.multimediaType);
      this.showImageCropping = false;
    } else {
      this.$emit('selectImageUrl', '');
      this.$emit('SetImageIconUrl', '', '', this.multimediaType);
      this.$emit(
        'PreviousIconUrl',
        this.imageSrc,
        this.localImageFullSrc,
        this.multimediaType,
      );
      this.showImageCropping = true;
    }
    this.imageIconUrl = this.missingImage;
    this.Iconfilename = 'Choose file...';
    this.croppedimageIconUrl = '';
    this.localImageFullSrc = '';
    if (this.Type !== null && (this.Type === 'Video' || this.Type === 'PDF' || this.IsVideoFile())) {
      this.showImageCropping = false;
    }
    this.showImageCropping = false;
  }
  private IsImageIconUrlExists() {
    let selectedImage: string | undefined = this.imageIconUrl;
    if (this.imageIconUrl === '') {
      selectedImage = this.localImageFullSrc;
    }
    if (selectedImage === '' || typeof selectedImage === 'undefined') {
      return false;
    }
    if (!selectedImage.includes('data:image/png')) {
      return true;
    } else {
      return false;
    }
  }

  private onImageIconFileChange(fieldName: string, file: any) {
    this.imageIconUrl = '';
    const { maxSize } = this;
    const uploadedFile = file[0];
    if (file.length > 0) {
      this.errorText = '';
      const size = uploadedFile.size / (maxSize * 1000) / (maxSize * 1000);
      if (uploadedFile.type.match('video.*')) {
        this.multimediaType = 'Video';
        this.maxSize = this.videoMaxSize;
        this.showImageCropping = false;
      } else if (uploadedFile.type.match('pdf.*')) {
        this.maxSize = this.pdfMaxSize;
        this.showImageCropping = false;
        this.multimediaType = 'PDF';
      } else {
        this.maxSize = this.imageMaxSize;
        this.showImageCropping = true;
        this.multimediaType = 'Image';
      }
      const acceptedFiles = this.acceptedFileTypes!.join('|');
      if (this.fileExtensions === '.pdf') {
        this.showImageCropping = false;
      }
      if (!uploadedFile.type.match(acceptedFiles)) {
        this.errorText = this.validationMessage!;
      } else if (size > this.maxSize) {
        this.errorText = 'FileUpload.ErrorFileSize';
      } else {
        this.Iconfilename = file[0].name;
        const url = URL.createObjectURL(uploadedFile);
        this.ValidateUploadedFileSize(
          uploadedFile,
          url,
          this.imageIconUrl,
          this.validationtype!,
          this.IconImageSizeWidth!,
          this.IconImageSizeHeight!,
        );
      }
    }
  }
  private ValidateUploadedFileSize(
    file: any,
    url: string,
    imageFile: string,
    operation: string,
    width: number,
    height: number,
  ) {
    if (this.multimediaType === 'Video' || this.multimediaType === 'PDF') {
      this.imageIconUrl = this.loadingImage;
      this.croppedimageIconUrl = this.loadingImage;
      this.UploadFile(
        this.uploadUrl,
        file,
        this.IconImageSizeWidth!.toString(),
        this.IconImageSizeHeight!.toString(),
      );
    } else {
      let isSizeOk = true;
      this.multimediaType = 'Image';
      const img = new Image();
      img.src = url;
      img.onload = () => {
        const tempWidth = img.width;
        const tempHeight = img.height;
        if (operation === 'lessthan') {
          if (tempWidth < width || tempHeight < height) {
            this.errorText = 'Image.Upload.ErrorImageDimensions';
            isSizeOk = false;
          } else {
            isSizeOk = true;
          }
        } else {
          isSizeOk = false;
          const resultOfModWidth = tempWidth % 2;
          const resultOfModHeight = tempHeight % 2;
          if (tempWidth <= width || tempHeight <= height) {
            // Height % 2 == 0 &&  Width % 2 == 0 must be true
            if (resultOfModHeight === 0 && resultOfModWidth === 0) {
              // Height == Width OR (Width * 2 == Height) must be true
              if (tempWidth === tempHeight || tempWidth === tempHeight * 2) {
                isSizeOk = true;
              }
            }
          }

          if (!isSizeOk) {
            this.errorText = 'Image.Upload.Error360ImageDimensions';
          }
        }
        if (isSizeOk) {
          this.imageIconUrl = this.loadingImage;
          this.localImageFullSrc = this.loadingImage;
          this.croppedimageIconUrl = this.loadingImage;
          this.UploadFile(
            this.uploadUrl,
            file,
            this.IconImageSizeWidth!.toString(),
            this.IconImageSizeHeight!.toString(),
          );
        }
      };
    }
  }

  private UploadFile(
    url: string,
    file: any,
    width: string,
    height: string,
  ) {
    const formData = new FormData();
    this.GetMediaPrefix();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    formData.append('file', file);
    formData.append('thumbnailWidth', width);
    formData.append('thumbnailHeight', height);
    formData.append('mediaPrefix',this.mediaPrefix);
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.$axios
      .post(url, formData, options)
      .then((response) => {
        this.imageIconUrl = response.data.thumbUri;
        this.localImageFullSrc = response.data.fullUri;
        this.croppedimageIconUrl = response.data.thumbUri;
        this.$emit(
          'SetImageIconUrl',
          response.data.thumbUri,
          response.data.fullUri,
          this.multimediaType,
        );
      })
      .catch(() => {
        // Log error if required
      });
  }
}
