














































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import ProgramListView from '@/components/Program/ProgramDetail/ProgramListView.vue';
import ProgramTrackListView from '@/components/Program/ProgramDetail/Tracks/ProgramTrackListView.vue';
import ProgramEditItemPane from '@/components/Program/ProgramDetail/ProgramEditItemPane.vue';
import ProgramBatchEditPane from '@/components/Program/ProgramDetail/ProgramBatchEditPane.vue';
import SessionQuestionBatchPane from '@/components/Program/SessionQuestions/SessionQuestionBatchPane.vue';
import ProgramTrackItemEditPane from '@/components/Program/ProgramDetail/Tracks/ProgramTrackEditItemPane.vue';
import ImportFile from '@/components/Common/FileImport/ImportFile.vue';
import { ProgramItem, ProgramItemSignalR } from '@/entities/Program/ProgramItem';
import { ProgramViewModel } from '@/entities/Program/ProgramViewModel';
import {
  ProgramTrackItem,
  ProgramTrackItemSignalR,
} from '@/entities/Program/ProgramTrackItem';
import {
  ProgramTagItem,
  ProgramTagItemSignalR,
} from '@/entities/Program/ProgramTagItem';
import ProgramTagListView from '@/components/Program/ProgramDetail/Tags/ProgramTagListView.vue';
import ProgramTagItemEditPane from '@/components/Program/ProgramDetail/Tags/ProgramTagItemEditPane.vue';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import SessionQuestionsEditItemPane from '@/components/Program/SessionQuestions/SessionQuestionsEditItemPane.vue';
import SessionQuestionsLandingPage from '@/components/Program/SessionQuestions/SessionQuestionsLandingPage.vue';
import SessionQuestionsListView from '@/components/Program/SessionQuestions/SessionQuestionsListView.vue';
import { ProgramSessionQuestionsItem } from '@/entities/Program/ProgramSessionQuestionsItem';
import { ProgramSessionQuestionType } from '@/entities/Program/ProgramSessionQuestionType';
import EvaluationQuestionBatchPane from '@/components/Program/EvaluationQuestions/EvaluationQuestionBatchPane.vue';
// eslint-disable-next-line max-len
import EvaluationQuestionsEditItemPane from '@/components/Program/EvaluationQuestions/EvaluationQuestionsEditItemPane.vue';
// eslint-disable-next-line max-len
import EvaluationQuestionsLandingPage from '@/components/Program/EvaluationQuestions/EvaluationQuestionsLandingPage.vue';
import EvaluationQuestionsListView from '@/components/Program/EvaluationQuestions/EvaluationQuestionsListView.vue';
// eslint-disable-next-line max-len
import EvaluationQuestionExportResultsToEmailModalPopup from '@/components/Program/EvaluationQuestions/EvaluationQuestionExportResultsToEmailModalPopup.vue';
import { ProgramEvaluationQuestionsItem } from '@/entities/Program/ProgramEvaluationQuestionsItem';
import { ProgramEvaluationQuestionType } from '@/entities/Program/ProgramEvaluationQuestionType';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import SubModuleEnum from '@/enums/SubModuleEnum';
// eslint-disable-next-line max-len
import { ProgramEvaluationQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramEvaluationQuestionsSignalRModel';
import { ProgramSessionQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramSessionQuestionsSignalRModel';
import { TrackSignalRModel } from '@/signalRRequest/Program/TrackSignalRModel';
import ModuleEnum from '@/enums/ModuleEnum';
import { TagSignalRModel } from '@/signalRRequest/Program/TagSignalRModel';
import { FontAwesomeIcon } from '@/entities/FontAwesomeViewModel';
import ImportModuleType from '@/enums/ImportModuleType';
@Component({
  components: {
    ProgramListView,
    ProgramEditItemPane,
    ProgramBatchEditPane,
    SessionQuestionBatchPane,
    ProgramTrackListView,
    ProgramTrackItemEditPane,
    ProgramTagListView,
    ProgramTagItemEditPane,
    loading,
    SessionQuestionsEditItemPane,
    SessionQuestionsLandingPage,
    SessionQuestionsListView,
    ModalPopup,
    EvaluationQuestionsEditItemPane,
    EvaluationQuestionsLandingPage,
    EvaluationQuestionsListView,
    EvaluationQuestionExportResultsToEmailModalPopup,
    EvaluationQuestionBatchPane,
    ImportFile,
  },
})
export default class Program extends Vue {
  private classicMode = false;
  private enableProgramQuestion = false;
  private showLoading = false;
  private src = '../src/assets/adminUserInspirationHTML/programInspirationHTML.html';
  private loaderBorderColor = '';
  private viewModel: ProgramViewModel = ProgramViewModel.empty();
  private selectedProgramItems: ProgramItem[] = [];
  private selectedTrackItem: ProgramTrackItem | null = null;
  private activeComponent = 'program';
  private activeSubComponent = '';
  private selectAllProgramItems = false;
  private selectedSessionQuestions: ProgramSessionQuestionsItem[] = [];
  private selectAllSessionQuestions = false;
  private selectedTagItem: ProgramTagItem | null = null;
  private isMobileView = false;
  private programQuestionTypes?: ProgramSessionQuestionType[] = [];
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private showSection_ModalPopup = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private fileDownload = require('js-file-download');
  private selectedEvaluationQuestions: ProgramEvaluationQuestionsItem[] = [];
  private selectAllEvaluationQuestions = false;
  private programEvaluationQuestionTypes?: ProgramEvaluationQuestionType[] = [];
  private programEvaluationsLinkedToAllPrograms?: boolean = false;
  private baseprogramEvaluationsLinkedToAllPrograms?: boolean = false;
  private editSelectedProgramsPopupVisible?: boolean = false;
  private evaluationQuestionExportToEmailPopupVisible?: boolean = false;
  private selectedEvaluationProgramIds?: number[] = [];
  private existingEvaluationQuestionsCount = 0;
  private existingSessionQuestionsCount = 0;
  private applicationId = 0;
  private applicationInstanceId = 0;
  private globalProgramEvaluation = false;
  private localEditedProgramItem: any = null;
  private localEditedProgramSessionQuestionItem: any = null;
  private localEditedProgramEvaluationQuestionItem: any = null;
  private programEditPaneValues: any = null;
  private programSessionQuestionEditPaneValues: any = null;
  private programEvaluationQuestionEditPaneValues: any = null;
  private previouslySelectedFontAwesomeIcon: FontAwesomeIcon = FontAwesomeIcon.createEmpty();

  private selectedProgramItemSignalR: ProgramItemSignalR = ProgramItemSignalR.createEmpty();
  private notificationSignalRModelRequest: SignalRModelRequest = new SignalRModelRequest();
  private isVideoUploadInProgress = false;
  private isHavingRTMPStreams = false;
  private moduleType = ImportModuleType.Program;
  private programBulkImportInprogress = false;
  // constants
  private readonly Track = 'Track';
  private readonly Tag = 'Tag';
  private readonly Import = 'Import';
  private readonly Program = 'Create Program';
  private readonly ProgramLandingPage = 'ProgramLandingPage';
  private readonly SelectedProgram = 'selectedprogram';
  private readonly SessionQuestions = 'SessionQuestions';
  private readonly SessionQuestionsLandingPage = 'SessionQuestionsLandingPage';
  private readonly BulkDeleteSessionQuestions = 'BulkDeleteSessionQuestions';
  private readonly EvaluationQuestions = 'EvaluationQuestions';
  private readonly VodVideoUploadInProgressEvaluationClicked = 'VodVideoUploadInProgressEvaluationClicked';
  private readonly VodVideoUploadInProgressSessionQuestionsClicked = 'VodVideoUploadInProgressSessionQuestionsClicked';
  private readonly EvaluationQuestionsLandingPage =
    'EvaluationQuestionLandingPage';
  private readonly BulkDeleteEvaluationQuestions =
    'BulkDeleteEvaluationQuestions';
  private readonly NotificationUpdated = 'NotificationUpdated';
  private readonly NotificationProgramBulkUpdated =
    'NotificationProgramBulkUpdated';
  private readonly NotificationProgramEvaluationBulkUpdated =
    'NotificationProgramEvaluationBulkUpdated';
  private readonly NotificationProgramSessionBulkUpdated =
    'NotificationProgramSessionBulkUpdated';
  private readonly ProgramInspirationUrl = 'StaticContent/InspirationArea/admin-user-inspiration.html';
  // #region Program
  private mounted() {
    this.showLoading = true;
    this.GetRequestInfoFromStore();
    if (this.$route.params.applicationborderColor != null) {
      this.loaderBorderColor = this.$route.params.applicationborderColor;
    }

    this.$axios
      .post<ProgramViewModel>('/Program/GetProgramViewModel')
      .then((response) => {
        const copyViewModel = response.data;
        copyViewModel.ProgramList = this.sortAndGroupProgramItems(
          copyViewModel.ProgramList,
        );
        this.viewModel = copyViewModel;
        this.isHavingRTMPStreams = copyViewModel.HasRTMPStreamings;
        this.showLoading = false;
        this.$root.$emit('programsList', copyViewModel.ProgramList);
        // sometimes application data takes time to load and program mounted fired already
        setTimeout(() => {
          this.GetEnableProgramQuestion();
        }, 1500);
      })
      .catch(() => {
        this.showLoading = false;
      });

    this.$root.$on(
      'program-track-item-edit-operation',
      (pt: ProgramTrackItem) => {
        this.programTrackItemEditedOperation(pt);
      },
    );
    this.$root.$on('program-track-item-delete-operation', (trackId: number) => {
      this.programTrackItemDeletedOperation(trackId);
    });
    this.$root.$on('program-tag-item-edit-operation', (pt: ProgramTagItem) => {
      this.programTagItemEditedOperation(pt);
    });
    this.$root.$on('program-tag-item-delete-operation', (tagId: number) => {
      this.programTagItemDeletedOperation(tagId);
    });
    this.$root.$on(
      'program-sessionquestion-item-edit-operation',
      (pq: ProgramSessionQuestionsItem) => {
        this.selectedSessionQuestions = [pq];
      },
    );
    this.$root.$on('program-item-edit-operation', (pi: ProgramItem) => {
      this.programItemEditedOperation(pi);
    });

    this.$root.$on(
      'program-item-edit-operation-with-notification',
      (notification: SignalRModelRequest) => {
        this.programItemEditedOperationWithNotification(notification);
      },
    );

    this.$root.$on('program-item-delete-operation', (pi: ProgramItem) => {
      this.programItemDeletedOperation(pi);
    });
    this.$root.$on('program-uncheck-select-all', () => {
      this.selectAllProgramItems = false;
    });
    this.$root.$on(
      'program-sessionquestion-tab-select-operation',
      () => {
        this.SessionQuestionsTabClick(true);
      },
    );

    this.$root.$on(
      'program-evaluationquestion-tab-select-operation',
      () => {
        this.EvaluationQuestionsTabClick(true);
      },
    );
    this.$root.$on('reload-programs-data', () => {
      this.ReloadProgramsData();
    });
    this.$root.$on('empty-track-tag', () => {
      this.EmptyTagsAndTracks();
    });
    this.$root.$on(
      'selected-font-awesome-icon',
      (fontAwesomeIcon: FontAwesomeIcon) => {
        this.previouslySelectedFontAwesomeIcon = fontAwesomeIcon;
      },
    );

    this.$root.$on(
      'program-session-questions-updated',
      (sessionQuestionsItem: ProgramSessionQuestionsItem) => {
        if (this.selectedSessionQuestions.length === 1) {
          const objIndex = this.selectedSessionQuestions.findIndex(
            (obj: ProgramSessionQuestionsItem) => obj.id === sessionQuestionsItem.id,
          );
          if (objIndex > -1) {
            this.selectedSessionQuestions = [sessionQuestionsItem];
          }
        }
      });
    this.$root.$on('freeze-screen', (val: boolean, userName: string) => {
      const storeHelper: StoreHelper = new StoreHelper(this.$store);
      const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
      if (reqInfo.Username === userName) {
        const progressBar = document.getElementById('dvMainProgramItems') as HTMLElement;
        if (progressBar !== null) {
          if (val === true) {
            progressBar.classList.add('show-right-panel');
          } else {
            progressBar.classList.remove('show-right-panel');
          }
        }
      }
    });
    this.$root.$on('empty-active-sub-component',()=> this.activeSubComponent = '');
    if (window.innerWidth <= 700) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.ListenSignalRResponseEvents();
    this.$root.$on('show-loader', (val: any) => {
      this.showLoading = val;
    });
    this.$root.$on('program-import-inprogress', (val: any) => {
      this.programBulkImportInprogress= val;
    });
  }

  private GetIframeUrl(): string {
    return this.$EnvironmentBaseURL + this.ProgramInspirationUrl;
  }
  private updated() {
    this.GetEnableProgramQuestion();
  }
  private GetEnableProgramQuestion() {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const enableProgramQuestion: boolean = storeHelper.GetEnableProgramQuestionValueFromStore();
    this.enableProgramQuestion = enableProgramQuestion;
  }
  private ListenSignalRResponseEvents() {
    this.$root.$on(
      'batch-selected-program-items-completed',
      (notification: SignalRModelRequest) => {
        const signalR: ProgramSignalRModel = new ProgramSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.ProgramSignalRModelResponseReceived(notification);
        if (notification.IsSuccess) {
          if (this.selectedProgramItems.length === 1) {
            const objIndex = notification.SelectedIds.findIndex(
              (obj: number) => obj === this.selectedProgramItems[0].Id,
            );
            if (objIndex > -1) {
              this.modalPopupVisible = true;
              this.showCloseButton = false;
              this.showSection_ModalPopup = this.NotificationProgramBulkUpdated;
              this.modalPopupShowCancelButton = false;
              this.modalPopupConfirmButtonText = this.$t(
                'Button.Ok',
              ).toString();
              this.modalPopupCancelButtonText = this.$t(
                'CancelButton',
              ).toString();
              if (notification.SubModule === SubModuleEnum.Delete) {
                this.modalPopupContent = this.$t('Message.ItemDeletedBy', {
                  username: notification.UserName,
                }).toString();
              } else {
                this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
                  username: notification.UserName,
                }).toString();
              }
            }
          } else if (this.selectedProgramItems.length > 1) {
            this.modalPopupVisible = true;
            this.showCloseButton = false;
            this.showSection_ModalPopup = this.NotificationProgramBulkUpdated;
            this.modalPopupShowCancelButton = false;
            this.modalPopupConfirmButtonText = this.$t(
              'Button.Ok',
            ).toString();
            this.modalPopupCancelButtonText = this.$t(
              'CancelButton',
            ).toString();
            this.modalPopupContent = this.$t('Message.MultipleItemsUpdatedBy', {
              username: notification.UserName,
            }).toString();
          } else  {
            if (notification.SubModule === SubModuleEnum.AssignToTrack) {
              this.$root.$emit('update-tracks-in-listing');
            } else if (notification.SubModule === SubModuleEnum.EditTags) {
              this.$root.$emit('update-tags-in-listing');
            } else if (notification.SubModule === SubModuleEnum.LinkInformation) {
              this.$root.$emit('update-information-in-listing');
            } else if (notification.SubModule === SubModuleEnum.EditDateAndTime) {
              this.$root.$emit(
                'program-item-bulk-datetime-operation',
                notification,
              );
            } else if (notification.SubModule === SubModuleEnum.ImportPrograms) {
              this.$root.$emit('reload-programs-data');
            } else if (notification.SubModule === SubModuleEnum.UpdatePrograms) {
              this.$root.$emit('reload-programs-data');
            }
          }
        }
      },
    );
    this.$root.$on(
      'batch-selected-evaluation-questions-deleted',
      (notification: SignalRModelRequest) => {
        const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.ProgramSignalRModelResponseReceived(notification);
        if (notification.IsSuccess) {
          if (this.selectedEvaluationQuestions.length === 1) {
            const objIndex = notification.SelectedIds.findIndex(
              (obj: number) => obj === this.selectedEvaluationQuestions[0].Id,
            );
            if (objIndex > -1) {
              this.modalPopupVisible = true;
              this.showCloseButton = false;
              this.showSection_ModalPopup = this.NotificationProgramEvaluationBulkUpdated;
              this.modalPopupShowCancelButton = false;
              this.modalPopupConfirmButtonText = this.$t(
                'Button.Ok',
              ).toString();
              this.modalPopupCancelButtonText = this.$t(
                'CancelButton',
              ).toString();
              this.modalPopupContent = this.$t('Message.ItemDeletedBy', {
                username: notification.UserName,
              }).toString();
            }
          }
        }
      },
    );
    this.$root.$on(
      'batch-selected-evaluation-questions-toggleAnonymous',
      (notification: SignalRModelRequest) => {
        const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.ProgramSignalRModelResponseReceived(notification);
      },
    );
    this.$root.$on('program-item-video-upload-inprogress', (isVideoUploadinProgress: boolean) => {
      this.isVideoUploadInProgress = isVideoUploadinProgress;
    });
    this.$root.$on(
      'batch-selected-session-questions-deleted',
      (notification: SignalRModelRequest) => {
        const signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.ProgramSignalRModelResponseReceived(notification);
        if (notification.IsSuccess) {
          if (this.selectedSessionQuestions.length === 1) {
            const objIndex = notification.SelectedIds.findIndex(
              (obj: number) => obj === this.selectedSessionQuestions[0].id,
            );
            if (objIndex > -1) {
              this.modalPopupVisible = true;
              this.showSection_ModalPopup = this.NotificationProgramSessionBulkUpdated;
              this.modalPopupShowCancelButton = false;
              this.modalPopupConfirmButtonText = this.$t(
                'Button.Ok',
              ).toString();
              this.modalPopupCancelButtonText = this.$t(
                'CancelButton',
              ).toString();
              this.modalPopupContent = this.$t('Message.ItemDeletedBy', {
                username: notification.UserName,
              }).toString();
            }
          }
        }
      },
    );
    this.$root.$on(
      'selected-tracks-item-notification-completed',
      (notification: SignalRModelRequest) => {
        if (notification.IsSuccess) {
          this.PerformTrackOperationOnList(notification);
        }

        const signalR: TrackSignalRModel = new TrackSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.TrackSignalRModelResponseReceived(notification);
      },
    );
    this.$root.$on(
      'selected-tags-item-notification-completed',
      (notification: SignalRModelRequest) => {
        if (notification.IsSuccess) {
          this.PerformTagOperationOnList(notification);
        }

        const signalR: TagSignalRModel = new TagSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.TagSignalRModelResponseReceived(notification);
      },
    );
  }
  private PerformTrackOperationOnList(notification: SignalRModelRequest) {
    if (
      notification.Module === ModuleEnum.Track &&
      notification.SubModule === SubModuleEnum.SingleDelete
    ) {
      if (notification.SelectedIds.length > 0) {
        this.programTrackItemDeletedOperation(notification.SelectedIds[0]);
      }
    } else {
      const trackSignalRItem: ProgramTrackItemSignalR = JSON.parse(
        notification.JSONData,
      );
      let trackItem: ProgramTrackItem = ProgramTrackItem.createEmpty();
      trackItem = ProgramTrackItem.GetTrackItemFromTrackItemSignalR(
        trackItem,
        trackSignalRItem,
      );
      // remove properties to avoid reset for another user if he is working on any track.
      delete trackItem.OldIconUrl;
      delete trackItem.OldIconUrlOriginal;
      delete trackItem.OldName;
      delete trackItem.IsSelected;
      this.programTrackItemEditedOperation(trackItem);
    }
  }

  // Export Vonage Session Data for a particular Program
  private GetRTMPDetails(
  ) {
    this.showLoading = true;
    this.$axios({
      url: '/FileDownload/ExportRTMP',
      method: 'POST',
      headers: { Accept: 'application/pdf' },
      responseType: 'blob', // important
    })
      .then((response) => {
        this.fileDownload(response.data, 'RTMPDetails.pdf');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  private PerformTagOperationOnList(notification: SignalRModelRequest) {
    if (
      notification.Module === ModuleEnum.Tag &&
      notification.SubModule === SubModuleEnum.SingleDelete
    ) {
      if (notification.SelectedIds.length > 0) {
        this.programTagItemDeletedOperation(notification.SelectedIds[0]);
      }
    } else {
      const tagSignalRItem: ProgramTagItemSignalR = JSON.parse(
        notification.JSONData,
      );
      let tagItem: ProgramTagItem = ProgramTagItem.createEmpty();
      tagItem = ProgramTagItem.GetTagItemFromTagItemSignalR(
        tagItem,
        tagSignalRItem,
      );
      this.programTagItemEditedOperation(tagItem);
    }
  }

  private ReloadProgramsData() {
    this.$axios
      .post<ProgramViewModel>('/Program/GetProgramViewModel')
      .then((response) => {
        const copyViewModel = response.data;
        copyViewModel.ProgramList = this.sortAndGroupProgramItems(
          copyViewModel.ProgramList,
        );
        this.viewModel = copyViewModel;
        this.$root.$emit('programsList', copyViewModel.ProgramList);
        // this.SetProgramLandingPage();
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  private ReloadTagsData() {
    this.$axios
      .post('/Program/GetProgramTags')
      .then((response) => {
        const copyProgramTags: ProgramTagItem[] = response.data;
        this.viewModel.ProgramTags = copyProgramTags;
      })
      .catch(() => {
        // Do nothing
      });
  }
  private ChangeProramItem(object: any) {
    if (this.$refs.EditProgramItem) {
      // get current values of program edit pane
      this.programEditPaneValues = Object.assign(
        {},
        (this.$refs.EditProgramItem as Vue & {
          localProgramItem: () => ProgramItem;
        }).localProgramItem,
      );
      if(this.programEditPaneValues.Id === 0){
        this.programEditPaneValues.VirtualMeetingType = '';
      }
      // to prevent watch of localEditedProgramItem if create new program is click.
      if (!object.moveToAnotherTab) {
        this.localEditedProgramItem = this.programEditPaneValues;
      }
    } else {
      this.localEditedProgramItem = object.selectedItem;
    }
  }
  private ChangeSessionQuestionItem(object: any) {
    if (this.$refs.EditSessionQuestionItem) {
      // get current values of session question edit pane
      this.programSessionQuestionEditPaneValues = Object.assign(
        {},
        (this.$refs.EditSessionQuestionItem as Vue & {
          localSessionQuestion: () => ProgramSessionQuestionsItem;
        }).localSessionQuestion,
      );
      if (!object.moveToAnotherTab) {
        this.localEditedProgramSessionQuestionItem = this.programSessionQuestionEditPaneValues;
      }
    } else {
      this.localEditedProgramSessionQuestionItem = object.selectedItem;
    }
  }
  private ChangeEvaluationQuestionItem(object: any) {
    if (this.$refs.EditEvaluationQuestionItem) {
      // get current values of evaluation question edit pane
      this.programEvaluationQuestionEditPaneValues = Object.assign(
        {},
        (this.$refs.EditEvaluationQuestionItem as Vue & {
          localEvaluationQuestion: () => ProgramEvaluationQuestionsItem;
        }).localEvaluationQuestion,
      );
      if (!object.moveToAnotherTab) {
        this.localEditedProgramEvaluationQuestionItem = this.programEvaluationQuestionEditPaneValues;
      }
    } else {
      this.localEditedProgramEvaluationQuestionItem = object.selectedItem;
    }
  }
  private UpdateSelectAllCheckboxEvaluationQuestions(value: boolean) {
    this.selectAllEvaluationQuestions = value;
  }
  private UpdateSelectAllCheckboxSessionQuestions(value: boolean) {
    this.selectAllSessionQuestions = value;
  }
  private setActiveComponent(comp: string) {
    this.activeComponent = comp;
  }
  private setActiveSubComponent(comp: string) {
    this.activeSubComponent = comp;
  }
  private GetRequestInfoFromStore() {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    setTimeout(() => {
      const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
      this.applicationId = Number(reqInfo.ApplicationId);
      this.applicationInstanceId = Number(reqInfo.ApplicationInstanceId);
    }, 100);
  }
  // Method that fires on click of Programs Tab
  private ProgramsTabClick() {
    if (
      this.selectedProgramItems.length === 1 &&
      this.activeComponent === 'program'
    ) {
      // get edit item pane data
      this.ChangeProramItem({
        selectedItem: this.selectedProgramItems[0],
        moveToAnotherTab: true,
      });

      if (
        ProgramItem.prototype.CompareProgramObjects({
          val1: this.programEditPaneValues,
          val2: this.selectedProgramItems[0],
        })
      ) {
        this.SetProgramLandingPage();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.ProgramLandingPage;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      }
      return;
    } else if ( this.activeComponent === 'program' && this.programBulkImportInprogress) {
      this.modalPopupVisible = true;
      this.SetModalPopupDefaultButtonText();
      this.showSection_ModalPopup = this.ProgramLandingPage;
      this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      return;
    } else if (
      this.selectedEvaluationQuestions.length === 1 &&
      this.activeComponent === 'evaluationQuestions'
    ) {
      // get edit item pane data
      this.ChangeEvaluationQuestionItem({
        selectedItem: this.selectedEvaluationQuestions[0],
        moveToAnotherTab: true,
      });

      if (
        ProgramEvaluationQuestionsItem.prototype.CompareProgramObjects({
          val1: this.programEvaluationQuestionEditPaneValues,
          val2: this.selectedEvaluationQuestions[0],
        })
      ) {
        this.SetProgramLandingPage();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.ProgramLandingPage;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      }
      return;
    } else if (
      this.selectedSessionQuestions.length === 1 &&
      this.activeComponent === 'sessionQuestions'
    ) {
      // get edit item pane data
      this.ChangeSessionQuestionItem({
        selectedItem: this.selectedSessionQuestions[0],
        moveToAnotherTab: true,
      });

      if (
        ProgramSessionQuestionsItem.prototype.CompareProgramObjects({
          val1: this.programSessionQuestionEditPaneValues,
          val2: this.selectedSessionQuestions[0],
        })
      ) {
        this.SetProgramLandingPage();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.ProgramLandingPage;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      }
      return;
    }
    this.SetProgramLandingPage();
  }
  private SetProgramLandingPage() {
    if (this.selectedProgramItems.length > 0) {
      this.selectAllProgramItems = false;
      this.selectedProgramItems = [];
      this.$root.$emit('clearCheckboxes', []);
    }
    this.ResetSessionQuestionTab();
    this.setActiveComponent('program');
    this.setActiveSubComponent('');
  }
  // Method that fires on click of Session Questions Tab
  private SessionQuestionsTabClick(callByEmitProp: boolean) {
    if (!callByEmitProp) {
      if (
        this.selectedProgramItems.length === 1 &&
        this.activeComponent === 'program'
      ) {
        // get edit item pane data
        this.ChangeProramItem({
          selectedItem: this.selectedProgramItems[0],
          moveToAnotherTab: true,
        });

        if (
          ProgramItem.prototype.CompareProgramObjects({
            val1: this.programEditPaneValues,
            val2: this.selectedProgramItems[0],
          }) && !this.isVideoUploadInProgress
        ) {
          this.SetSessionLandingPage();
        } else {
          if (this.isVideoUploadInProgress) {
            this.modalPopupVisible = true;
            this.SetModalPopupDefaultButtonText();
            this.showSection_ModalPopup = this.VodVideoUploadInProgressSessionQuestionsClicked;
            this.modalPopupContent = this.$t('VirtualMeetings.AddVideo.ChangeScreenMessage').toString();
          } else {
            this.modalPopupVisible = true;
            this.SetModalPopupDefaultButtonText();
            this.showSection_ModalPopup = this.SessionQuestionsLandingPage;
            this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
          }
        }
        return;
      }else if ( this.activeComponent === 'program' && this.programBulkImportInprogress ) {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.SessionQuestionsLandingPage;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
        return;
      } else if (
        this.selectedEvaluationQuestions.length === 1 &&
        this.activeComponent === 'evaluationQuestions'
      ) {
        // get edit item pane data
        this.ChangeEvaluationQuestionItem({
          selectedItem: this.selectedEvaluationQuestions[0],
          moveToAnotherTab: true,
        });

        if (
          ProgramEvaluationQuestionsItem.prototype.CompareProgramObjects({
            val1: this.programEvaluationQuestionEditPaneValues,
            val2: this.selectedEvaluationQuestions[0],
          })
        ) {
          this.SetSessionLandingPage();
        } else {
          this.modalPopupVisible = true;
          this.SetModalPopupDefaultButtonText();
          this.showSection_ModalPopup = this.SessionQuestionsLandingPage;
          this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
        }
        return;
      } else if (
        this.selectedSessionQuestions.length === 1 &&
        this.activeComponent === 'sessionQuestions'
      ) {
        // get edit item pane data
        this.ChangeSessionQuestionItem({
          selectedItem: this.selectedSessionQuestions[0],
          moveToAnotherTab: true,
        });

        if (
          ProgramSessionQuestionsItem.prototype.CompareProgramObjects({
            val1: this.programSessionQuestionEditPaneValues,
            val2: this.selectedSessionQuestions[0],
          })
        ) {
          this.SetSessionLandingPage();
        } else {
          this.modalPopupVisible = true;
          this.SetModalPopupDefaultButtonText();
          this.showSection_ModalPopup = this.SessionQuestionsLandingPage;
          this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
        }
        return;
      }
    }
    this.SetSessionLandingPage();
  }
  private SetSessionLandingPage() {
    if (this.selectedSessionQuestions.length > 0) {
      this.ResetSessionQuestionTab();
    }
    this.setActiveComponent('sessionQuestions');
    this.setActiveSubComponent('sessionQuestionsLandingPage');
  }
  private ResetSessionQuestionTab() {
    this.selectAllSessionQuestions = false;
    this.selectedSessionQuestions = [];
    this.$root.$emit('clearSessionQuestionCheckboxes', []);
  }
  private EvaluationQuestionsTabClick(callByEmitProp: boolean) {
    if (!callByEmitProp) {
      if (
        this.selectedProgramItems.length === 1 &&
        this.activeComponent === 'program'
      ) {
        // get edit item pane data
        this.ChangeProramItem({
          selectedItem: this.selectedProgramItems[0],
          moveToAnotherTab: true,
        });

        if (
          ProgramItem.prototype.CompareProgramObjects({
            val1: this.programEditPaneValues,
            val2: this.selectedProgramItems[0],
          }) && !this.isVideoUploadInProgress
        ) {
          this.SetEvaluationLandingPage();
        } else {
          if (this.isVideoUploadInProgress) {
            this.modalPopupVisible = true;
            this.SetModalPopupDefaultButtonText();
            this.showSection_ModalPopup = this.VodVideoUploadInProgressEvaluationClicked;
            this.modalPopupContent = this.$t('VirtualMeetings.AddVideo.ChangeScreenMessage').toString();
          } else {
            this.modalPopupVisible = true;
            this.SetModalPopupDefaultButtonText();
            this.showSection_ModalPopup = this.EvaluationQuestionsLandingPage;
            this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
          }
        }
        return;
      } else if ( this.activeComponent === 'program' && this.programBulkImportInprogress ) {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.EvaluationQuestionsLandingPage;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
        return;
      }
      if (
        this.selectedSessionQuestions.length === 1 &&
        this.activeComponent === 'sessionQuestions'
      ) {
        // get edit item pane data
        this.ChangeSessionQuestionItem({
          selectedItem: this.selectedSessionQuestions[0],
          moveToAnotherTab: true,
        });

        if (
          ProgramSessionQuestionsItem.prototype.CompareProgramObjects({
            val1: this.programSessionQuestionEditPaneValues,
            val2: this.selectedSessionQuestions[0],
          })
        ) {
          this.SetEvaluationLandingPage();
        } else {
          this.modalPopupVisible = true;
          this.SetModalPopupDefaultButtonText();
          this.showSection_ModalPopup = this.EvaluationQuestionsLandingPage;
          this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
        }
        return;
      } else if (
        this.selectedEvaluationQuestions.length === 1 &&
        this.activeComponent === 'evaluationQuestions'
      ) {
        // get edit item pane data
        this.ChangeEvaluationQuestionItem({
          selectedItem: this.selectedEvaluationQuestions[0],
          moveToAnotherTab: true,
        });

        if (
          ProgramEvaluationQuestionsItem.prototype.CompareProgramObjects({
            val1: this.programEvaluationQuestionEditPaneValues,
            val2: this.selectedEvaluationQuestions[0],
          })
        ) {
          this.SetEvaluationLandingPage();
        } else {
          this.modalPopupVisible = true;
          this.SetModalPopupDefaultButtonText();
          this.showSection_ModalPopup = this.EvaluationQuestionsLandingPage;
          this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
        }
        return;
      }
    }
    this.SetEvaluationLandingPage();
  }
  private SetEvaluationLandingPage() {
    if (this.selectedEvaluationQuestions.length > 0) {
      this.selectAllEvaluationQuestions = false;
      this.selectedEvaluationQuestions = [];
      this.$root.$emit('clearEvaluationQuestionCheckboxes', []);
    }
    this.ResetSessionQuestionTab();
    this.setActiveComponent('evaluationQuestions');
    this.setActiveSubComponent('evaluationQuestionsLandingPage');
  }
  // Switch to classic mode
  private SwitchClassicMode() {
    this.classicMode = true;
    window.location.href =
      this.$EnvironmentBaseURL + '/Admin/Program/IndexProgram';
  }
  // #endregion

  // #region ProgramItem

  // Create new program item
  private CreateNewProgramItem() {
    if (this.programBulkImportInprogress) {
      this.modalPopupVisible = true;
      this.SetModalPopupDefaultButtonText();
      this.showSection_ModalPopup = this.Program;
      this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
    }else {
      this.setActiveComponent('program');
      this.setActiveSubComponent('');
      const newItem = ProgramItem.createEmpty();
      newItem.StartDate = this.GetNewProgramStartDate();
      this.selectedProgramItems = [newItem];
      this.$root.$emit('createEmptyProgramItem', newItem);
    }
  }
  // update program item component when program item changed
  private updateProgramItemComponent(selectedProgramItems: ProgramItem) {
    if (selectedProgramItems !== null && selectedProgramItems !== undefined &&
    selectedProgramItems.Description !== null && selectedProgramItems.Description !== undefined &&
    selectedProgramItems.ProgramTags !== null && selectedProgramItems.ProgramTags !== undefined) {
      this.$root.$emit('UpdateTags', selectedProgramItems.ProgramTags);
      this.$root.$emit(
        'UpdateEditorDescription',
        selectedProgramItems.Description,
      );
    }
    // make image process component false if other program item selected
    this.$root.$emit('show-image-process', false);
  }

  private programItemsSelected(pItems: ProgramItem[]) {
    this.setActiveComponent('program');
    const copyArr = Array.from(pItems); // copy array. //Object.assign({}, pItems);
    // Set selected item collection for batch operations
    this.selectedProgramItems = copyArr;
    if (this.selectedProgramItems.length === 1) {
      if ( this.activeComponent === 'program' && this.programBulkImportInprogress ) {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.SelectedProgram;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
        return;
      }
      this.updateProgramItemComponent(this.selectedProgramItems[0]);
      this.setActiveSubComponent(this.SelectedProgram);
    } else if (this.selectedProgramItems.length > 1) {
      this.setActiveSubComponent('selectedBatchprogram');
    } else if (this.selectedTrackItem != null) {
      this.setActiveSubComponent('tracks');
    } else if (this.selectedTagItem != null) {
      this.setActiveSubComponent('tags');
    } else if (this.activeSubComponent.toLowerCase() === this.Import.toLowerCase()) {
      this.setActiveSubComponent('import');
    // commented for bug DEV-7190
    // } else if (this.activeSubComponent.toLowerCase() === 'selectedprogram') {
    //   this.updateProgramItemComponent(this.selectedProgramItems[0]);
    //   this.setActiveSubComponent('selectedprogram');
    } else {
      this.setActiveSubComponent('');
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private programItemCancelOperation(pi: ProgramItem) {
    this.setActiveSubComponent('');
    this.selectedProgramItems = [];
  }
  private btnCreateNewProgramItemClick() {
    if (this.selectedProgramItems.length === 1) {
      // get edit item pane data
      this.selectedProgramItems[0].ParticipantGroupIds = [];
      this.ChangeProramItem({
        selectedItem: this.selectedProgramItems[0],
        moveToAnotherTab: true,
      });

      if (
        ProgramItem.prototype.CompareProgramObjects({
          val1: this.programEditPaneValues,
          val2: this.selectedProgramItems[0],
        })
      ) {
        this.createNewProgramItem();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.Program;
        this.modalPopupContent = this.$t('CreateNew.Warning').toString();
      }
    } else if (this.programBulkImportInprogress) {
      this.modalPopupVisible = true;
      this.SetModalPopupDefaultButtonText();
      this.showSection_ModalPopup = this.Program;
      this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
    }else {
      this.createNewProgramItem();
    }
  }
  private SetModalPopupDefaultButtonText() {
    this.modalPopupShowCancelButton = true;
    this.modalPopupConfirmButtonText = this.$t('YesButton').toString();
    this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
  }
  private createNewProgramItem() {
    if (this.selectedProgramItems.length > 0) {
      this.selectAllProgramItems = false;
      setTimeout(() => {
        this.CreateProgram();
      }, 100);
    } else {
      this.CreateProgram();
    }
  }
  private CreateProgram() {
    this.setActiveComponent('program');
    this.setActiveSubComponent(this.SelectedProgram);
    const newItem = ProgramItem.createEmpty();
    this.selectedProgramItems = [newItem];
    newItem.StartDate = this.GetNewProgramStartDate();
    this.selectedTagItem = null;
    this.selectedTrackItem = null;
    this.$root.$emit('createEmptyItem', newItem);
    this.$root.$emit('clearDescription', '');
    this.$root.$emit('clearTags');
    this.$root.$emit('clearSessionQuestions');
    this.$root.$emit('clearEvaluationQuestions');
    this.$root.$emit('clearMultimedia');
    this.$root.$emit('clearImage360');
    this.$root.$emit('clearInformations');
  }

  private programItemEditedOperation(pi: ProgramItem) {
    const objIndex = this.viewModel.ProgramList.findIndex(
      (obj: ProgramItem) => obj.Id === pi.Id,
    );
    if (objIndex === -1) {
      // Created
      this.viewModel.ProgramList.push(pi);
    } else {
      // Updated
      Vue.set(this.viewModel.ProgramList, objIndex, pi); // Update array w reactivity
      this.selectedProgramItems = [pi];
    }
    this.viewModel.ProgramList = this.sortAndGroupProgramItems(
      this.viewModel.ProgramList,
    );
    // get index of newly added program item
    const newProgramItemIndex = this.viewModel.ProgramList.findIndex(
      (obj: ProgramItem) => obj.           Id === pi.Id,
    );
    this.$root.$emit('scroll-to-newly-added-item', newProgramItemIndex);
  }
  private CheckIfSomeNewTagIsAdded(signalObject: SignalRModelRequest): boolean {
    let result = false;

    const pItem: ProgramItemSignalR = JSON.parse(signalObject.JSONData);
    if (pItem.ProgramTags != null) {
      const tags: ProgramTagItem[] = pItem.ProgramTags;
      if (tags.length > 0) {
        const objIndex = tags.findIndex(
          (obj: ProgramTagItem) => Number(obj.Id) === 0,
        );
        if (objIndex === -1) {
          result = true;
        }
      }
    }
    return result;
  }
  private programItemEditedOperationWithNotification(
    notification: SignalRModelRequest,
  ) {
    const pi: ProgramItemSignalR = JSON.parse(notification.JSONData);
    const objIndex = this.viewModel.ProgramList.findIndex(
      (obj: ProgramItem) => obj.Id === pi.Id,
    );
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    if (objIndex === -1) {
      // update all program ids in session questions listing
      this.$root.$emit(
        'update-all-programids-in-session-questions-with-new-Program',
        pi.Id, notification,
      );
      // Created
      const pItem = signalR.FillProgramObjectFromSignalRResponseNewItem(pi);
      this.viewModel.ProgramList.push(pItem);
      // update tags in tag list
      if (this.CheckIfSomeNewTagIsAdded(notification)) {
        this.ReloadTagsData();
      }
    } else {
      // Updated
      // Avoid override updated current program if edit pane is diffirent
      if (
        this.selectedProgramItems.length === 1 &&
        this.selectedProgramItems[0].Id === pi.Id
      ) {
        this.CheckIfUpdatedItemIsOpenInEditMode(
          notification,
          signalR,
          objIndex,
          pi,
        );
      } else {
        // update all program ids in session questions listing
        this.$root.$emit(
          'update-all-programids-in-session-questions',
          pi.Id,
        );
        this.SetProgramItemInList(objIndex, signalR, pi);
        // update tags in tag list
        if (this.CheckIfSomeNewTagIsAdded(notification)) {
          this.ReloadTagsData();
        }
      }
    }
    this.viewModel.ProgramList = this.sortAndGroupProgramItems(
      this.viewModel.ProgramList,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      // get index of newly added program item
      const newProgramItemIndex = this.viewModel.ProgramList.findIndex(
        (obj: ProgramItem) => obj.Id === pi.Id,
      );
      this.$root.$emit('scroll-to-newly-added-item', newProgramItemIndex);
    }
  }

  private CheckIfUpdatedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
    signalR: ProgramSignalRModel,
    objIndex: number,
    pi: ProgramItemSignalR,
  ) {
    if (signalR.IsLoggedInUserIsMe(notification)) {
      // update all program ids in session questions listing
      this.$root.$emit(
        'update-all-programids-in-session-questions',
        pi.Id,
      );
      this.PerformSelectedItemUpdateOperation(
        this.SetProgramItemInList(objIndex, signalR, pi),
      );
      // update tags in tag list
      if (this.CheckIfSomeNewTagIsAdded(notification)) {
        this.ReloadTagsData();
      }
    } else {
      this.modalPopupVisible = true;
      this.showSection_ModalPopup = this.NotificationUpdated;
      this.modalPopupShowCancelButton = true;
      this.modalPopupConfirmButtonText = this.$t('Button.Reload').toString();
      this.modalPopupCancelButtonText = this.$t('Button.Ignore').toString();
      this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedProgramItemSignalR = pi;
      this.notificationSignalRModelRequest = notification;
    }
  }
  private PerformSelectedItemUpdateOperation(pItem: ProgramItem) {
    this.updateProgramItemComponent(pItem);
    this.selectedProgramItems = [pItem];
  }
  private SetProgramItemInList(
    objIndex: number,
    signalR: ProgramSignalRModel,
    pi: ProgramItemSignalR,
  ): ProgramItem {
    const pItem = signalR.FillProgramObjectFromSignalRResponse(
      pi,
      objIndex,
      this.viewModel.ProgramList,
    );
    Vue.set(this.viewModel.ProgramList, objIndex, pItem); // Update array w reactivity
    return pItem;
  }

  private GetNewProgramStartDate(): Date {
    const length = this.viewModel.ProgramList.length;
    if (this.viewModel.ProgramList.length > 0) {
      const lastItemEndDate: Date = this.viewModel.ProgramList[length - 1]
        .EndDate;
      if (lastItemEndDate == null) {
        const lastItemStartDate: Date = this.viewModel.ProgramList[length - 1]
          .StartDate;
        if (lastItemStartDate != null) {
          const dateToPass: Date = new Date(lastItemStartDate);
          dateToPass.setHours(lastItemStartDate.getHours() + 1);
          return dateToPass;
        }
      }
      return lastItemEndDate;
    } else {
      const storeHelper: StoreHelper = new StoreHelper(this.$store);
      const startDate: Date = storeHelper.GetActiveEventStartDateFromStore();
      if (startDate != null) {

        const date: Date = new Date(
          Date.UTC(
            startDate.getUTCFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
          ),
        );
        date.setHours(date.getHours() + 7);
        return date;
      }
    }
    const todaysdate: Date = new Date();
    const currentMinutes: number = todaysdate.getMinutes();
    if (currentMinutes > 0 && currentMinutes < 30) {
      todaysdate.setMinutes(30);
    } else if (currentMinutes > 30) {
      todaysdate.setHours(todaysdate.getHours() + 1);
      todaysdate.setMinutes(0);
    }
    todaysdate.setSeconds(0);
    return todaysdate;
  }
  // Emitted Deleted program item from programEditItemPane
  // To removed this program item from program list.
  private programItemDeletedOperation(pi: ProgramItem) {
    const objIndex = this.viewModel.ProgramList.findIndex(
      (obj: ProgramItem) => obj.Id === pi.Id,
    );
    Vue.delete(this.viewModel.ProgramList, objIndex); // Update array w reactivity
    this.selectedProgramItems = [];
    // to show Inspiration area
    this.activeSubComponent = '';
  }
  private UpdateProgramSorting() {
    this.viewModel.ProgramList = this.sortAndGroupProgramItems(
      this.viewModel.ProgramList,
    );
  }

  private GetUtcDate(value: Date ) {
    return value.getUTCFullYear() + '-' +
          ('0' + (value.getUTCMonth() + 1)).slice(-2) + '-' +
           ('0' + value.getUTCDate()).slice(-2);
  }

  private sortAndGroupProgramItems(programs: ProgramItem[]): ProgramItem[] {
    programs.sort((a: ProgramItem, b: ProgramItem): number => {
      if (a.StartDate.toISOString() > b.StartDate.toISOString()) {
        return 1;
      }
      if (b.StartDate.toISOString() > a.StartDate.toISOString()) {
        return -1;
      }
      if (a.StartDate.toISOString() === b.StartDate.toISOString()) {
        return a.Title > b.Title ? 1 : a.Title === b.Title ? 0 : -1;
      }
      return 0;
    });
    const sortedPrograms: ProgramItem[] = [];
    const finalizedTracks: string[] = [];
    for (const p of programs) {
      if (p.Track) {
        // If p is in track, then we need to figure out what other track items to add after p.
        const trackname = p.Track.Name;
        const currentDate = this.GetUtcDate(p.StartDate);
        if (!finalizedTracks.find((track) => track === trackname + currentDate)) {
          const itemsintrackOndate = programs.filter((p2) => {
            if (p2.Track) {
              if (
                p2.Track.Name === trackname &&
                this.GetUtcDate(p2.StartDate) === currentDate
              ) {
                return p2;
              }
            }
          });
          for (const item of itemsintrackOndate) {
            sortedPrograms.push(item);
          }
          finalizedTracks.push(trackname + currentDate);
        }
      } else {
        // if p i not in track, just add to sorted
        sortedPrograms.push(p);
      }
    }
    return sortedPrograms;

    // return programs;
  }

  private get hasSelectedProgramItem(): boolean {
    return this.selectedProgramItems.length > 0;
  }
  // #endregion

  // #region Tracks
  private programTrackItemCancelOperation() {
    this.setActiveSubComponent('');
    this.selectedTrackItem = null;
    this.selectedProgramItems = [];
  }
  private ImportProgram() {
    this.EmptyTagsAndTracks();
    this.setActiveComponent('program');
    this.setActiveSubComponent('import');
    this.selectedProgramItems = [];
    this.selectAllProgramItems = false;
    this.$root.$emit('clearCheckboxes', []);
  }
  private btnCreateNewProgramTrackItemClick() {
    if (this.selectedProgramItems.length === 1) {
      // get edit item pane data
      this.ChangeProramItem({
        selectedItem: this.selectedProgramItems[0],
        moveToAnotherTab: true,
      });


      if (
        ProgramItem.prototype.CompareProgramObjects({
          val1: this.programEditPaneValues,
          val2: this.selectedProgramItems[0],
        })
      ) {
        this.CreateNewProgramTrackItem();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.Track;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      }
    } else if ( this.programBulkImportInprogress ) {
      this.modalPopupVisible = true;
      this.SetModalPopupDefaultButtonText();
      this.showSection_ModalPopup = this.Track;
      this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
    } else {
      this.CreateNewProgramTrackItem();
    }
  }

  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if (this.showSection_ModalPopup === this.Track) {
        this.CreateNewProgramTrackItem();
      } else if (this.showSection_ModalPopup === this.Tag) {
        this.CreateNewProgramTagItem();
      } else if (this.showSection_ModalPopup === this.Import) {
        this.ImportProgram();
      } else if (this.showSection_ModalPopup === this.Program) {
        const newItem = ProgramItem.createEmpty();
        this.selectedProgramItems[0] = newItem;
        this.createNewProgramItem();
      } else if (this.showSection_ModalPopup === this.SessionQuestions) {
        this.CreateNewProgramQuestionItem();
      } else if (this.showSection_ModalPopup === this.EvaluationQuestions) {
        this.CreateNewEvaluationQuestionItem();
        return;
      } else if (
        this.showSection_ModalPopup === this.EvaluationQuestionsLandingPage
      ) {
        this.SetEvaluationLandingPage();
        return;
      } else if (
        this.showSection_ModalPopup === this.SessionQuestionsLandingPage
      ) {
        this.SetSessionLandingPage();
        return;
      } else if (this.showSection_ModalPopup === this.ProgramLandingPage) {
        this.SetProgramLandingPage();
        return;
      } else if (this.showSection_ModalPopup === this.NotificationUpdated) {
        this.ReloadConfirmedProgramItem();
        return;
      } else if (
        this.showSection_ModalPopup === this.NotificationProgramBulkUpdated
      ) {
        this.$root.$emit('reload-programs-data');
        if (this.selectedProgramItems.length === 1 ) {
          this.programItemCancelOperation(this.selectedProgramItems[0]);
        } else if (this.selectedProgramItems.length > 1 ) {
          this.SetProgramLandingPage();
        }
      } else if (
        this.showSection_ModalPopup ===
        this.NotificationProgramEvaluationBulkUpdated
      ) {
        this.$root.$emit('reload-evaluation-questions-data');
        return;
      } else if (
        this.showSection_ModalPopup ===
        this.NotificationProgramSessionBulkUpdated
      ) {
        this.$root.$emit('reload-session-questions-data');
        return;
      } else if (this.showSection_ModalPopup === this.VodVideoUploadInProgressEvaluationClicked) {
        // this.$root.$emit('program-cancel-video-upload');
        this.SetEvaluationLandingPage();
      } else if (this.showSection_ModalPopup === this.VodVideoUploadInProgressSessionQuestionsClicked) {
        // this.$root.$emit('program-cancel-video-upload');
        this.SetSessionLandingPage();
      } else if (this.showSection_ModalPopup.toLowerCase() === this.SelectedProgram) {
        this.setActiveSubComponent(this.SelectedProgram);
        return;
      }
      this.$root.$emit('clearCheckboxes', []);
    } else {
      if(this.programBulkImportInprogress) {
        this.selectedProgramItems = [];
        this.$root.$emit('clearCheckboxes', []);
      }
      return;
    }
  }
  private ReloadConfirmedProgramItem() {
    const pq: ProgramItemSignalR = this.selectedProgramItemSignalR;
    const objIndex = this.viewModel.ProgramList.findIndex(
      (obj: ProgramItem) => obj.Id === pq.Id,
    );
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );

    this.PerformSelectedItemUpdateOperation(
      this.SetProgramItemInList(objIndex, signalR, pq),
    );
    // update all program ids in session questions listing
    this.$root.$emit(
      'update-all-programids-in-session-questions',
      pq.Id,
    );
    // update tags in tag list
    if (this.CheckIfSomeNewTagIsAdded(this.notificationSignalRModelRequest)) {
      this.ReloadTagsData();
    }
    this.$root.$emit('reload-program-detail');
  }
  private CreateNewProgramTrackItem() {
    this.setActiveComponent('program');
    this.setActiveSubComponent('tracks');
    const newItem = ProgramTrackItem.createEmpty();
    this.selectedTrackItem = newItem;
    this.selectedProgramItems = [];
    this.selectAllProgramItems = false;
    this.selectedTagItem = null;
    this.$root.$emit('clearCheckboxes', []);
  }

  private programTrackItemEditedOperation(pt: ProgramTrackItem) {
    const objIndex = this.viewModel.ProgramTracks.findIndex(
      (obj: ProgramTrackItem) => obj.Id === pt.Id,
    );
    if (objIndex === -1) {
      // Created
      this.viewModel.ProgramTracks.push(pt);
    } else {
      // Updated
      Vue.set(this.viewModel.ProgramTracks, objIndex, pt); // Update array w reactivity
      // update track in program
      this.viewModel.ProgramList.forEach((element) => {
        if (element.Track) {
          if (element.Track.Id === pt.Id) {
            element.Track.Name = pt.Name;
          }
        }
      });
    }
  }

  private programTrackItemDeletedOperation(trackId: number) {
    const objIndex = this.viewModel.ProgramTracks.findIndex(
      (obj: ProgramTrackItem) => obj.Id === trackId,
    );
    if (objIndex > -1) {
      this.viewModel.ProgramTracks.splice(objIndex, 1);
      this.selectedTrackItem = null;
      // remove track from Program List
      this.viewModel.ProgramList.forEach((element) => {
        if (element.Track) {
          if (element.Track.Id === trackId) {
            element.Track = null;
          }
        }
      });
    }
  }
  private get hasSelectedTrackItem(): boolean {
    if (this.selectedTrackItem) {
      return true;
    } else {
      return false;
    }
  }
  // #endregion

  // #region Tags
  private programTagItemCancelOperation() {
    this.setActiveSubComponent('');
    this.selectedTagItem = null;
  }
  private ProgramImportCancelOperation() {
    this.setActiveSubComponent('');
  }
  private btnImportProgramsClicked() {
    if (this.selectedProgramItems.length === 1) {
      // get edit item pane data
      this.ChangeProramItem({
        selectedItem: this.selectedProgramItems[0],
        moveToAnotherTab: true,
      });

      if (
        ProgramItem.prototype.CompareProgramObjects({
          val1: this.programEditPaneValues,
          val2: this.selectedProgramItems[0],
        })
      ) {
        this.ImportProgram();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.Import;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      }
    } else {
      this.ImportProgram();
    }
  }
  private btnCreateNewProgramTagItemClick() {
    if (this.selectedProgramItems.length === 1) {
      // get edit item pane data
      this.ChangeProramItem({
        selectedItem: this.selectedProgramItems[0],
        moveToAnotherTab: true,
      });

      if (
        ProgramItem.prototype.CompareProgramObjects({
          val1: this.programEditPaneValues,
          val2: this.selectedProgramItems[0],
        })
      ) {
        this.CreateNewProgramTagItem();
      }else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.Tag;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      }
    } else if ( this.programBulkImportInprogress ) {
      this.modalPopupVisible = true;
      this.SetModalPopupDefaultButtonText();
      this.showSection_ModalPopup = this.Tag;
      this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
    }  else {
      this.CreateNewProgramTagItem();
    }
  }

  private CreateNewProgramTagItem() {
    this.setActiveComponent('program');
    this.setActiveSubComponent('tags');
    const newItem = ProgramTagItem.createEmpty();
    this.selectedTagItem = newItem;
    this.selectedProgramItems = [];
    this.selectAllProgramItems = false;
    this.selectedTrackItem = null;
    this.$root.$emit('clearCheckboxes', []);
  }

  private programTagItemEditedOperation(pt: ProgramTagItem) {
    const objIndex = this.viewModel.ProgramTags.findIndex(
      (obj: ProgramTagItem) => obj.Id === pt.Id,
    );
    if (objIndex === -1) {
      // Created
      this.viewModel.ProgramTags.push(pt);
    } else {
      // Updated
      Vue.set(this.viewModel.ProgramTags, objIndex, pt); // Update array w reactivity
      // update tag in related program
      this.viewModel.ProgramList.forEach((element) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element.ProgramTags!.forEach((item) => {
          if (item.Id === pt.Id) {
            item.TagName = pt.TagName;
          }
        });
      });
    }
  }

  private programTagItemDeletedOperation(tagId: number) {
    const objIndex = this.viewModel.ProgramTags.findIndex(
      (obj: ProgramTagItem) => obj.Id === tagId,
    );
    if (objIndex > -1) {
      this.viewModel.ProgramTags.splice(objIndex, 1);
      this.selectedTagItem = null;
      // remove tag from program in program list
      this.viewModel.ProgramList.forEach(function(item) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        item.ProgramTags = item.ProgramTags!.filter((s) => s.Id !== tagId);
      });
    }
  }

  private get hasSelectedTagItem(): boolean {
    if (this.selectedTagItem) {
      return true;
    } else {
      return false;
    }
  }
  // #endregion

  // #region Session Questions

  // Click event of Create new Question Button
  private btnCreateNewProgramQuestionItemClick() {
    if (this.selectedSessionQuestions.length === 1) {
      // get edit item pane data
      this.ChangeSessionQuestionItem({
        selectedItem: this.selectedSessionQuestions[0],
        moveToAnotherTab: true,
      });


      if (
        ProgramSessionQuestionsItem.prototype.CompareProgramObjects({
          val1: this.programSessionQuestionEditPaneValues,
          val2: this.selectedSessionQuestions[0],
        })
      ) {
        this.CreateNewProgramQuestionItem();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.SessionQuestions;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      }
    } else {
      this.CreateNewProgramQuestionItem();
    }
  }
  // Create new Session Question
  private CreateNewProgramQuestionItem() {
    if (this.selectedSessionQuestions.length > 0) {
      this.selectAllSessionQuestions = false;
      setTimeout(() => {
        this.CreateSessionQuestion();
      }, 100);
    } else {
      this.CreateSessionQuestion();
    }
  }
  private CreateSessionQuestion() {
    this.setActiveComponent('sessionQuestions');
    this.setActiveSubComponent('sessionQuestionsCreateNewQuestion');
    const newItem = ProgramSessionQuestionsItem.createEmpty();
    this.selectedSessionQuestions = [newItem];
    this.$root.$emit('createEmptySessionQuestionItem', newItem);
  }
  private CreateSessionQuestionOfSelectedType(type: number) {
    this.setActiveComponent('sessionQuestions');
    this.setActiveSubComponent('sessionQuestionsCreateNewQuestion');
    const newItem = ProgramSessionQuestionsItem.createEmpty();
    newItem.ProgramQuestionType = type;
    this.selectedSessionQuestions = [newItem];
    this.$root.$emit('createEmptySessionQuestionItem', newItem);
  }
  // Fill Program Question Types from DB
  private FillProgramQuestiontypes(
    questionTypes: ProgramSessionQuestionType[],
  ) {
    this.programQuestionTypes = questionTypes;
  }

  // Method that fires when Session Questions are selected from Child Component
  private sessionQuestionsSelected(sItems: ProgramSessionQuestionsItem[]) {
    if (this.activeComponent === 'sessionQuestions') {
      this.setActiveComponent('sessionQuestions');
      const copyArr = Array.from(sItems); // copy array. //Object.assign({}, pItems);
      // Set selected item collection for batch operations
      this.selectedSessionQuestions = copyArr;
      if (this.selectedSessionQuestions.length === 1) {
        this.setActiveSubComponent('sessionQuestionsCreateNewQuestion');
      } else if (this.selectedSessionQuestions.length > 1) {
        this.setActiveSubComponent('sessionQuestionsBatchEdit');
      } else {
        this.setActiveSubComponent('sessionQuestionsLandingPage');
      }
    }
  }

  // Method that fires when Cancel button of Create/Edit Session Question is clicked
  private ProgramSessionQuestionItemCancelOperation(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pq: ProgramSessionQuestionsItem,
  ) {
    this.setActiveSubComponent('sessionQuestionsLandingPage');
    this.selectedSessionQuestions = Object.assign({}, []);
  }
  // Emit Deleted Session Questions
  private EmitSelectedSessionQuestionsAfterBulkDelete() {
    this.$root.$emit(
      'program-sessionquestions-bulk-item-deleted',
      this.selectedSessionQuestions,
    );
  }
  private UpdateExistingSessionQuestionsCount(count: number) {
    this.existingSessionQuestionsCount = count;
  }
  // Export Session Questions Result to excel
  private ExportSessionQuestionResultsToExcel() {
    this.showLoading = true;
    this.$axios({
      url: '/FileDownload/ExportProgramQuestion',
      method: 'POST',
      headers: { Accept: 'application/vnd.ms-excel' },
      responseType: 'blob', // important
    })
      .then((response) => {
        this.fileDownload(response.data, 'ProgramQuestions.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private GetProgramQuestionsClassName(): string {
    let className = 'programQuestions';
    if (this.isMobileView) {
      className = 'programQuestions-mobile';
    } else {
      if (this.existingSessionQuestionsCount === 0) {
        className = 'programQuestions-full';
      } else {
        className = 'programQuestions';
      }
    }
    return className;
  }

  // #endregion

  // #region Evaluation Questions

  // Click event of Create new Evaluation Question Button
  private btnCreateNewEvaluationQuestionItemClick() {
    if (this.selectedEvaluationQuestions.length === 1) {
      // get edit item pane data
      this.ChangeEvaluationQuestionItem({
        selectedItem: this.selectedEvaluationQuestions[0],
        moveToAnotherTab: true,
      });


      if (
        ProgramEvaluationQuestionsItem.prototype.CompareProgramObjects({
          val1: this.programEvaluationQuestionEditPaneValues,
          val2: this.selectedEvaluationQuestions[0],
        })
      ) {
        this.CreateNewEvaluationQuestionItem();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.EvaluationQuestions;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      }
    } else {
      this.CreateNewEvaluationQuestionItem();
    }
  }

  // Create new Evaluation Question
  private CreateNewEvaluationQuestionItem() {
    this.setActiveComponent('evaluationQuestions');
    this.setActiveSubComponent('evaluationQuestionsCreateNewQuestion');
    const newItem = ProgramEvaluationQuestionsItem.createEmpty();
    this.selectedEvaluationQuestions = [newItem];
    this.$root.$emit('createEmptyEvaluationQuestionItem', newItem);
  }

  private CreateEvaluationQuestionOfSelectedType(type: number) {
    this.setActiveComponent('evaluationQuestions');
    this.setActiveSubComponent('evaluationQuestionsCreateNewQuestion');
    const newItem = ProgramEvaluationQuestionsItem.createEmpty();
    if (type === 1) {
      newItem.Type = 'Grade';
    } else {
      newItem.Type = 'Text';
    }
    this.selectedEvaluationQuestions = [newItem];
    this.$root.$emit('createEmptyEvaluationQuestionItem', newItem);
  }
  // Fill Program Question Types from DB
  private FillProgramEvaluationQuestiontypes(
    questionTypes: ProgramEvaluationQuestionType[],
  ) {
    this.programEvaluationQuestionTypes = questionTypes;
  }
  private FillIsGlobalProgramEvaluation(val: boolean) {
    this.programEvaluationsLinkedToAllPrograms = val;
    this.baseprogramEvaluationsLinkedToAllPrograms = val;
  }
  private ShowEditLink() {
    this.programEvaluationsLinkedToAllPrograms = false;
  }
  private HideEditLink() {
    this.programEvaluationsLinkedToAllPrograms = true;
  }

  private saveProgramEvaluationsAll() {
    this.showLoading = true;
    this.$axios
      .post('/Program/SaveProgramEvaluations', {
        isGlobalProgramEvaluation: true,
      })
      .then((response) => {
        if (response.data.success === false) {
          alert(this.$t('Message.UnableToSave'));
        } else {
          // Successful update / save
          alert(this.$t('Message.SuccessfullySavedOrUpdated'));
          this.selectedEvaluationProgramIds = [];
          this.$root.$emit('update-evaluation-selected-programs', {
            isallSelected: true,
            selectedProgramIds: this.selectedEvaluationProgramIds,
          });
        }
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private ConfirmEvaluationExportToEmailPopupClick(val: any) {
    if (val.clickedYes) {
      // Do nothing
    } else {
      this.evaluationQuestionExportToEmailPopupVisible = false;
    }
  }

  // Method that fires when Evaluation Questions are selected from Child Component
  private evaluationQuestionsSelected(
    sItems: ProgramEvaluationQuestionsItem[],
  ) {
    if (this.activeComponent === 'evaluationQuestions') {
      this.setActiveComponent('evaluationQuestions');
      const copyArr = Array.from(sItems); // copy array. //Object.assign({}, pItems);
      // Set selected item collection for batch operations
      this.selectedEvaluationQuestions = copyArr;
      if (this.selectedEvaluationQuestions.length === 1) {
        this.setActiveSubComponent('evaluationQuestionsCreateNewQuestion');
      } else if (this.selectedEvaluationQuestions.length > 1) {
        this.setActiveSubComponent('evaluationsBatchEdit');
      } else {
        this.setActiveSubComponent('evaluationQuestionsLandingPage');
      }
    }
  }

  // Method that fires when Cancel button of Create/Edit Evaluation Question is clicked
  private ProgramEvaluationQuestionItemCancelOperation(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pq: ProgramEvaluationQuestionsItem,
  ) {
    this.setActiveSubComponent('evaluationQuestionsLandingPage');
    this.selectedEvaluationQuestions = Object.assign({}, []);
  }

  // Export Session Questions Result to excel
  private ExportEvaluationQuestionResultsToExcel() {
    this.showLoading = true;
    this.$axios({
      url: '/FileDownload/ExportProgramEvaluation',
      method: 'POST',
      headers: { Accept: 'application/vnd.ms-excel' },
      responseType: 'blob', // important
    })
      .then((response) => {
        this.fileDownload(response.data, 'EvaluationQuestions.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private OpenEvaluationQuestionResultsToEmailPopup() {
    this.evaluationQuestionExportToEmailPopupVisible = true;
  }

  private UpdateExistingEvaluationQuestionsCount(count: number) {
    this.existingEvaluationQuestionsCount = count;
  }
  private EmptyTagsAndTracks() {
    this.selectedTagItem = null;
    this.selectedTrackItem = null;
  }

  // #endregion

  private beforeDestroy() {
    this.$root.$off('batch-selected-program-items-completed');
    this.$root.$off('reload-programs-data');
    this.$root.$off('batch-selected-session-questions-deleted');
    this.$root.$off('batch-selected-evaluation-questions-deleted');
    this.$root.$off('batch-selected-evaluation-questions-toggleAnonymous');
    this.$root.$off('selected-tracks-item-notification-completed');
    this.$root.$off('selected-tags-item-notification-completed');
  }
}
